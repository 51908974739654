import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

// import { Button } from 'antd';
import {
    Bar, Pie,
} from 'ant-design-pro/lib/Charts';
import {
    Row, Col, Icon, Card, message, Table, Radio,
} from 'antd';

import DataSet from "@antv/data-set";
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend,

} from "bizcharts";

import actions from '../../store/actions/user';
import style from '../../common/scss/product-list.module.scss';
import { getApi } from '../../api/common';

import { countAllUserListUrl } from '../../api/index';

@connect(state => ({
    openKey: state.user.openKey,
}), actions)

class allUser extends Component {
    constructor() {
        super();
        const { quering, state, chart } = this._init();

        this.quering = quering
        this.state = state
    }

    componentWillMount() {
        const { changeMenu, changeOpenKey, openKey } = this.props;
        changeMenu('allUser');
        const index = openKey.findIndex(item => item === 'sub7');
        const newOpenKey = [].concat(openKey);
        newOpenKey.splice(index, 1);
        changeOpenKey(index === -1 ? ['sub7'] : ['sub7', ...newOpenKey]);
        this.initData();
    }

    componentDidMount() {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        window.dispatchEvent(event);
    }

    _init() {

        const quering = {
            page_no: 1, page_size: 100, // 1. 按日查询 2. 按天查询page_no: 1,
        }

        const state = {

            columns: [
                // "merchant_name": "余粮宝",
                // "register_num": 0,
                //  "parent_num": 0
                {
                    title: '店铺',
                    dataIndex: 'merchant_name',
                    key: 'merchant_name',
                },
                {
                    title: '注册量',
                    dataIndex: 'register_num',
                    key: 'register_num',
                },
                {
                    title: '伙伴用户',
                    dataIndex: 'parent_num',
                    key: 'parent_num',
                },
                {
                    title: '累计交易额',
                    dataIndex: 'trade_sum',
                    key: 'trade_sum',
                    render: (text) => (
                        `¥ ${numeral(text / 100).format('0,0.00')}`
                    ),
                },
                {
                    title: '平均订单价',
                    dataIndex: 'trade_avg',
                    key: 'trade_avg',
                    render: (text) => (
                        `¥ ${numeral(text / 100).format('0,0.00')}`
                    ),
                },
                {
                    title: '订单量',
                    dataIndex: 'trade_count',
                    key: 'trade_count',
                }
            ],
            barCols: [],

            tableList: [],
            barList: [],
        }


        return { quering, state }
    }

    async initData() {
        await this.getUserList();
    }

    async getUserList() {
        try {
            this.setState({ loading: true });
            // this.quering
            const res = await getApi(countAllUserListUrl, this.quering);

            if (res.code === 0) {

                // "user_count": [
                //     {
                //         "belong_appid": "wxb1cd068e836575cd",
                //         "count": 17,
                //         "name": "弦子平台"
                //     },
                // ],
                // "mall_count": [
                //     {
                //         "appid": "wxea67acc43792159b",
                //         "count": 18,
                //         "name": "湃小铺"
                //     },
                //     {
                //         "count": 0,
                //         "name": "其他累计"
                //     }
                // ],
                // "user_total": 32,
                // "mall_total": 36,
                // "merchants": [
                //     {
                //         "appid": "wx556173cf712223b2",
                //         "merchant_name": "余粮宝",
                //         "register_num": 0,
                //         "parent_num": 0
                //     },
                // ]
                let { mall_count = [], merchants = [], user_total, mall_total } = res.data
                const { total = 0 } = res.pagination


                // 原始数据
                // {name: "伙伴店铺", 湃小铺: 8, 湃小摊: 9, 其它店铺: 20}
                // {name: "注册量", 湃小铺: 8, 湃小摊: 9, 其它店铺: 20}

                const ticks = []
                const barList = []
                const barRegisterList = []

                let panter
                let register


                //构建成：
                // {name: "伙伴店铺", time："湃小铺" , count: 8}
                // {name: "伙伴店铺", time："湃小摊" , count: 9}
                // {name: "伙伴店铺", time："其它店铺" , count: 20}
                // mall_count = [{
                //     "name": "伙伴店铺",
                //     "弦小店": 1365,
                //     "爱罗依糖果": 187,
                //     "余粮宝": 167,
                //     "橙火铺子": 144,
                //     "盛世伊尹": 145,
                //     "汖明西秀": 104,
                //     "伴月乡旅": 62,
                //     "光希": 6,
                //     "DR MARS火星博士数字商城": 32,
                //     "HAINLE海恩勒酒庄": 7,
                //     "悠乐果": 9,
                //     "长荡湖万叶大闸蟹": 6,
                //     "怡品茗": 7,
                //     "四特酒": 2,
                //     "宝仕图酒业": 2,
                //     "天福号数字商城": 8,
                //     "Feis 珠宝之旅": 2,
                //     "旗岭食品": 4,
                //     "黑枸杞": 3,
                //     "常斟鹿": 3,
                //     "其它店铺": 3714
                // }, {
                //     "name": "注册量",
                //     "弦小店": 1633,
                //     "爱罗依糖果": 318,
                //     "余粮宝": 304,
                //     "橙火铺子": 225,
                //     "盛世伊尹": 203,
                //     "汖明西秀": 161,
                //     "伴月乡旅": 99,
                //     "光希": 59,
                //     "DR MARS火星博士数字商城": 53,
                //     "HAINLE海恩勒酒庄": 50,
                //     "悠乐果": 32,
                //     "长荡湖万叶大闸蟹": 31,
                //     "怡品茗": 28,
                //     "四特酒": 25,
                //     "宝仕图酒业": 22,
                //     "天福号数字商城": 22,
                //     "Feis 珠宝之旅": 22,
                //     "旗岭食品": 18,
                //     "黑枸杞": 17,
                //     "常斟鹿": 12,
                //     "其它店铺": 51
                // }]
                for (const item of mall_count) {
                    const { name, ...key } = item
                    const keys = Object.keys(key)

                    keys.forEach(p => {
                        if (name === '伙伴店铺') {
                            panter = item
                            barList.push({ name: "伙伴用户", time: p, count: item[p], tip: p })
                        } else if (name === '注册量') {
                            register = item
                            // barList.push({ name: name, time: p, all: item[p] })
                            barRegisterList.push({ name, time: p, count: item[p], tip: `${p}   ${item[p]}` })
                        }

                        if (!ticks.includes(p)) {
                            ticks.push(p)
                            // 前台添加一个 {name: "其他店铺", 湃小铺: （注册量-伙伴店铺）, 湃小摊:（注册量-伙伴店铺）, 其它店铺: （注册量-伙伴店铺）}
                            barList.push({ name: "其他用户", time: p, count: 0, tip: p })//count: 0, tip: p 占位用，数组不对
                        }
                    })
                }


                barList.forEach(p => {
                    if (p.name === '其他用户') {
                        const count = register[p.time] - panter[p.time]
                        p.count = count > 0 ? count : 0
                    }
                    const all = barRegisterList.find(x => x.time == p.time)
                    if (all) {
                        p.tip = all.tip
                    }
                })

                merchants.forEach(p => {
                    p.merchant_name = p.merchant_name === '弦子平台' ? '物影钱包' : p.merchant_name
                });

                this.setState({
                    allUserCount: mall_total,
                    allUserSetCount: user_total,
                    barList,
                    barScale: ticks,
                    tableList: merchants,
                    total,

                    loading: false,
                });


            } else {
                this.setState({ loading: false });
                message.error(`${res.msg} `);
            }
        } catch (error) {
            message.error(`${error.message}`);
            console.log('get new user list error', error);
            this.setState({ loading: false });
        }
    }

    onStatusChange = (e) => {
        // if (e && e.target && e.target.value) {

        //     const { barRegisterList, barShopList } = this.state;
        //     // 2. 注册量 1. 伙伴店铺
        //     if (e.target.value === 1) {
        //         this.setState({ barList: barRegisterList })
        //     } else {
        //         this.setState({ barList: barShopList })
        //     }
        // }
    }

    // 改变页码
    changePage = (current, pageSize) => {
        this.quering.page_no = current;
        this.quering.page_size = pageSize;
        this.getUserList();
    }

    rotate = () => {
        const {
            barList,
        } = this.state
        const len = barList.length
        if (len < 9) {
            return 0;
        }
        return 30;
    }

    render() {
        const { loading,
            columns, tableList, total = 0,
            barList, allUserCount = 0, allUserSetCount = 0,
            currentTime = moment().format('YYYY-MM-DD HH:mm:ss'),
            barScale,
        } = this.state;

        const that = this

        return (
            <div className={style.content}>
                <div className={style.title}>
                    <div className={style.title_head}>数据统计</div>
                    <div className={style.title_info}>数据统计</div>
                </div>
                <Card bordered={false}>
                    <Row gutter={24} type="flex" justify="space-between"  >

                        <Col span={8}>
                            <div >
                                <Icon type="line" rotate='90' style={{ fontSize: '30px', color: '#08c' }} />
                                累计用户分析
                            </div>
                        </Col>

                        <Col span={16}>
                            <div style={{ display: 'flex', }}>
                                <Icon type="line" rotate='90' style={{ fontSize: '30px', color: '#08c' }} />
                                店铺累计用户排行
                                <p style={{ display: 'flex', marginLeft: '5px', marginTop: '5px' }}>
                                    <span style={{ color: '#CCCCCC', fontSize: '10px', 'verticalAlign': 'bottom' }}>
                                        统计截止时间：{currentTime}
                                    </span>
                                </p>
                            </div>
                        </Col>

                        <Col span={8}>
                            {/* <div >
                                <Radio.Group onChange={e => this.onStatusChange(e)} defaultValue={1}>
                                    <Radio.Button value={1}>注册量</Radio.Button>
                                    <Radio.Button value={2}>伙伴店铺</Radio.Button>
                                </Radio.Group>
                            </div> */}
                        </Col>

                    </Row>

                    <div style={{ marginTop: '20px' }}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <div style={{ border: '1px solid', borderRadius: '10px', borderStyle: 'dashed', }}>
                                    <div style={{ 'margin': '20px' }}>
                                        <Row >
                                            <div> 全平台注册量</div>
                                            <p><span style={{ color: '#CCCCCC', fontSize: '10px' }}>统计截止时间：{currentTime}</span></p>
                                            <span style={{ color: '#333333', fontSize: '32px', fontFamily: 'Arial Negreta' }}>{allUserCount}</span>
                                        </Row>
                                        <hr style={{ border: '0.5px solid', borderRadius: '10px', borderStyle: 'dashed' }} />
                                        <Row >
                                            <div> 去重累计用户量</div>
                                            <p><span style={{ color: '#CCCCCC', fontSize: '10px' }}>统计截止时间：{currentTime}</span></p>
                                            <span style={{ color: '#333333', fontSize: '32px', fontFamily: 'Arial Negreta' }}>{allUserSetCount}</span>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={14}>
                                <Row>
                                    <Chart height={window.innerHeight * 1 / 2} data={barList} scale={barScale} forceFit padding='auto'
                                        animate={true}
                                        onTooltipChange={(ev) => {
                                            var items = ev.items; // tooltip显示的项
                                            const datas = []
                                            for (const item of items) {
                                                var range = item.point._origin;
                                                datas.push({
                                                    name: range.name,
                                                    title: range.tip,
                                                    marker: true,
                                                    color: item.color,
                                                    value: range.count
                                                });
                                            }

                                            items.splice(0); // 清空
                                            items.push(...datas)
                                        }}
                                    >
                                        <Axis name="time"
                                            label={{
                                                autoRotate: true, // 是否需要自动旋转，默认为 true
                                                offset: 20,
                                                textStyle: {
                                                    rotate: this.rotate()
                                                }
                                            }}
                                        />
                                        < Axis name="count" />
                                        <Legend />
                                        <Tooltip
                                            // title="tip"  // TODO:不好使???
                                            crosshairs={{
                                                type: "line"
                                            }}
                                        />
                                        <Geom type="intervalStack" position="time*count" color="name"
                                            style={{
                                                stroke: "#fff", //#fff
                                                lineWidth: 1
                                            }} />
                                    </Chart>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Row gutter={16}>
                            <div className={style.table}>
                                <Table
                                    rowClassName={style.row}
                                    columns={columns}
                                    dataSource={tableList}
                                    rowKey={record => record.appid}
                                    loading={loading}
                                    pagination={false}
                                // pagination={{
                                // defaultCurrent: 1,
                                // current: this.quering.page_no,
                                // total,
                                // showQuickJumper: true,
                                // showSizeChanger: true,
                                // pageSize: this.quering.page_size,
                                // onChange: this.changePage,
                                // onShowSizeChange(current, pageSize) {
                                //     that.quering.page_no = 1;
                                //     that.quering.page_size = pageSize;
                                //     that.getUserList();
                                // },
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // showTotal: () => `共 ${total} 条`,
                                // }}
                                ></Table>
                            </div>
                        </Row>
                    </div>
                </Card>
            </div >
        );
    }
}
export default allUser;
