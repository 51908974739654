import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import style from '../../common/scss/product-info.module.scss';

@withTranslation('productDetail')
class ProductInfo extends Component {
  constructor() {
    super();
    this.state = {
      spu_info: {},
    };
  }

  componentWillMount() {
    const { spu_info } = this.props;
    this.setState({
      spu_info,
    });
  }

  componentWillReceiveProps(props) {
    const { spu_info } = props;
    this.setState({
      spu_info,
    });
  }

  getValueItems= (values) => (
    values.map(value => {
      if (value.image) {
        return (
          <div className={`${style.left} ${style.picture} ${style.formItem}`} key={value.name}>
            <img src={value.image.res_key} alt="" />
            <div>{value.name}</div>
          </div>
        );
      }
      return (
        <div className={`${style.left} ${style.formItem}`} key={value.key}>
          <div className={style.formatValue}>{value.name}</div>
        </div>
      );
    })
  )

  render() {
    const { t } = this.props;
    const { spu_info } = this.state;
    let formatItems;
    let DateItems;
    if (!spu_info.specs) {
      formatItems = '';
    } else {
      formatItems = spu_info.specs.map(item => (
        <div className={style.attribute} key={item.name}>
          <div className={style.name}>{item.name}：</div>
          <div className={`${style.clearfix} ${style.formatItemWrap}`}>
            {this.getValueItems(item.values)}
          </div>
        </div>
      ));
    }
    if (!spu_info.delivery_time) {
      DateItems = '';
    } else {
      DateItems = spu_info.delivery_time.map(item => (
        <div className={style.time} key={item.start}>
          {moment(item.start).format('YYYY-MM-DD HH:mm')}
          {t(' 至 ')}
          {moment(item.end).format('YYYY-MM-DD HH:mm')}
        </div>
      ));
    }
    return (
      <div className={style.wrap}>
        <div className={style.title}>{spu_info.caption}</div>
        {spu_info.keywords ? <div className={style.description}>{spu_info.keywords.join('、')}</div> : ''}
        {formatItems}
        <div className={style.attribute}>
          <div className={style.name}>{t('提货时限')}</div>
          <div className={style.clearfix}>
            <div className={style.left}>
              <div className={style.time}>
                {t('申请后')} {spu_info.delivery_start} {t('天发货')}
              </div>
              {DateItems}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProductInfo;
