import { combineReducers } from 'redux';
// import home from './home';
import signin from './signin';
import productFormat from './productFormat';
import user from './user';

const reducer = combineReducers({
  // home,
  signin,
  productFormat,
  user,
});
export default reducer;
