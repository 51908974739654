import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Icon,
  Avatar,
  // Badge,
  Dropdown, Menu,
} from 'antd';
import { withRouter } from 'react-router-dom';
import style from './nav.module.scss';
import actions from '../../store/actions/logout';

@connect(state => ({
  logout: state.logout,
}), actions)

@withRouter
class Nav extends Component {
  logout= () => {
    const { history, logout } = this.props;
    console.log(this.props);
    logout(history);
  };

  render() {
    const { cellPhone } = this.props;
    const setUpMenu = (
      <Menu>
        {/* <Menu.Item>
          <Link to="/user/merchantCertification">
            商家认证
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/user/securitySetting">
            安全设置
          </Link>
        </Menu.Item> */}
        <Menu.Item onClick={this.logout}>
          <span>
            退出登录
          </span>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className={style.nav}>
        {/* <Badge dot>
          <Icon className={style.email} type="mail" />
        </Badge> */}
        <Dropdown overlay={setUpMenu}>
          <div className={style.userStyle}>
            <Avatar className={style.avatar} size={32} icon="user" />
            <div className={style.user}>
              <span className={style.role}>管理员</span>
              <br />
              <span className={style.nickname}>{cellPhone}</span>
            </div>
            <Icon type="down" />
          </div>
        </Dropdown>
      </div>
    );
  }
}
export default Nav;
