import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Link, Route, Switch, /* NavLink, */
} from 'react-router-dom';
import {
  Layout, Menu, Icon, message,
} from 'antd';
import actions from '../../store/actions/user';
import Home from '../home/home';

// import ProductList from '../product/list';
// import ProductItem from '../product/item';
import OrderDelivery from '../order/delivery';
import OrderPickups from '../order/pickups';
import OrderReturns from '../order/returns';
// import OrderRefunds from '../order/refunds';
import CustomerAdmin from '../customer/admin';
import CustomerUser from '../customer/user';
import LiquidaTion from '../order/liquidation';
import LiquidationDetail from '../order/detail';
import AssetsTokens from '../assets/tokens';
import UserAdmin from './admin';
import SplitSetting from '../splitSetting/splitSetting';
import TokenDetail from '../assets/tokenDetail';

// 统计-------------
import NewUser from '../count/newUser';
import AllUser from '../count/allUser';
import TradeDSC from '../count/tradeDSC';
import TradeTrend from '../count/tradeTrend';

import NotFound from '../exception/404';
import Authority from '../exception/403';
import ServerError from '../exception/500';
import ThemeManage from '../themeManage/themeManage';
import Nav from '../../components/nav/nav';
// import logo from '../../common/images/logo.svg';
// import Foot from '../../components/foot/foot';
import style from '../../common/scss/user.module.scss';
import { checkLogin } from '../../api/common';
import { myMenu } from '../../api/manager';
import logoImg from '../../common/images/logo.png';

const {
  Header, Content, Footer, Sider,
} = Layout;
const { SubMenu } = Menu;
@connect(state => ({
  selectedMenuKey: state.user.selectedMenuKey,
  openKey: state.user.openKey,
}), actions)
class App extends Component {
  constructor() {
    super();
    this.state = {
      collapsed: false,
      logoStyle: {
        width: '100px',
      },
      contentStyle: {
        marginLeft: '199px',
      },
      cellphone: '',
      allowMenus: [],
    };
  }

  componentWillMount() {

  }

  async componentDidMount() {
    const res = await checkLogin();
    if (res && res.code === 0) {
      // await this.fetchMenu();
    } else {
      const { history } = this.props;
      history.push('/profile/signin');
    }
  }

  fetchMenu = async () => {
    const res = await myMenu();
    if (res.code === 0) {
      this.setState(() => ({ allowMenus: res.data }));
    } else {
      message.error(res.message);
    }
  }

  menuShowOrHide = (paths) => {
    // const { allowMenus } = this.state;
    // const item = allowMenus.find(p => (paths.find(m => (p === m))));
    // if (item) {
    //   return {};
    // }
    // return {
    //   display: 'none',
    // };
    if (paths) return {};
    return {};
  }

  onCollapse = (collapsed) => {
    const { changeOpenKey } = this.props;
    const { openKey } = this.state;
    this.setState({ collapsed });
    if (collapsed) {
      this.setState({
        logoStyle: {
          width: '60px',
        },
        contentStyle: {
          marginLeft: '80px',
        },
      });
      changeOpenKey([]);
    } else {
      this.setState({
        logoStyle: {
          width: '100px',
        },
        contentStyle: {
          marginLeft: '199px',
        },
      });
      changeOpenKey(openKey);
    }
  }

  openChange = (openKey) => {
    const { changeOpenKey } = this.props;
    changeOpenKey(openKey);
    this.setState({ openKey });
  }

  render() {
    const { selectedMenuKey, openKey } = this.props;
    const {
      collapsed, logoStyle, cellphone, contentStyle,
    } = { ...this.state };

    return (
      <>
        <Layout className={style.layout}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            theme="light"
            className={style.siderStyle}
          >
            <div className={style.logoWrap}>
              <div className={`${style.logo} ${style.clearfix}`} style={logoStyle} alt="">
                <div className={style.imgWarp}>
                  <img className={style.logoImage} src={logoImg} alt="wing" />
                </div>
                <div className={collapsed ? style.hide : style.logoTitle}>
                  物影钱包
                </div>
              </div>
            </div>
            <Menu
              defaultSelectedKeys={['sub1']}
              selectedKeys={[selectedMenuKey]}
              openKeys={openKey}
              onOpenChange={this.openChange}
              mode="inline"
              inlineCollapsed={collapsed}
            >
              <SubMenu
                key="sub2"
                title={(
                  <span>
                    <Icon type="user" />
                    <span>用户</span>
                  </span>
                )}
              >
                <Menu.Item key="customerAdmin"><Link to="/user/customer/admin">用户管理</Link></Menu.Item>
              </SubMenu>
              <SubMenu
                key="sub3"
                title={(
                  <span>
                    <Icon type="cluster" />
                    <span>联盟</span>
                  </span>
                )}
              >
                <Menu.Item key="SplitSetting"><Link to="/user/splitSetting/splitSetting">联盟分润设置</Link></Menu.Item>
              </SubMenu>
              <SubMenu
                key="sub4"
                title={(
                  <span>
                    <Icon type="account-book" />
                    <span>资金</span>
                  </span>
                )}
              >
                <Menu.Item key="LiquidaTion">
                  <Link to="/user/order/liquidation">分润清算订单</Link>
                </Menu.Item>
                {/* <Menu.Item key="assetsTransfers">
                  <Link to="/user/assets/transfers">转账任务</Link>
                </Menu.Item> */}
              </SubMenu>
              <SubMenu
                key="sub5"
                title={(
                  <span>
                    <Icon type="bank" />
                    <span>资产</span>
                  </span>
                )}
              >
                <Menu.Item key="assetsTokens">
                  <Link to="/user/assets/tokens">资产管理</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="sub8"
                title={(
                  <span>
                    <Icon type="bank" />
                    <span>运营</span>
                  </span>
                )}
              >
                <Menu.Item key="assetsTokens">
                  <Link to="/user/themeManage">贺卡主题管理</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="sub6"
                title={(
                  <span>
                    <Icon type="setting" />
                    <span>设置</span>
                  </span>
                )}
              >
                <Menu.Item key="userAdmin">
                  <Link to="/user/admin">管理员设置</Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="sub7"
                title={(
                  <span>
                    <Icon type="pie-chart" />
                    <span>统计</span>
                  </span>
                )}
              >
                <Menu.Item key="newUser">
                  <Link to="/user/count/newUser">新增用户</Link>
                </Menu.Item>
                <Menu.Item key="allUser">
                  <Link to="/user/count/allUser">累计用户</Link>
                </Menu.Item>
                <Menu.Item key="tradeTrend">
                  <Link to="/user/count/tradeTrend">交易额趋势统计</Link>
                </Menu.Item>
                <Menu.Item key="tradeDSC">
                  <Link to="/user/count/tradeDSC"> 交易额分布统计</Link>
                </Menu.Item>

              </SubMenu>
            </Menu>
          </Sider>
          <Layout className={style.sectionRight} style={contentStyle}>
            <Header className={style.header}>
              <Nav cellPhone={cellphone} />
            </Header>
            <Content className={style.contentWrap}>
              <Switch>
                <Route path="/user/order/delivery" component={OrderDelivery} />
                <Route path="/user/order/pickups" component={OrderPickups} />
                <Route path="/user/order/returns" component={OrderReturns} />
                <Route path="/user/customer/admin" component={CustomerAdmin} />
                <Route path="/user/customer/user/:id" component={CustomerUser} />
                <Route path="/user/order/liquidation" component={LiquidaTion} />
                <Route path="/user/order/detail/:id" component={LiquidationDetail} />
                <Route path="/user/assets/token/:id" component={TokenDetail} />
                <Route path="/user/assets/tokens" component={AssetsTokens} />
                <Route path="/user/splitSetting/splitSetting" component={SplitSetting} />
                <Route path="/user/count/newUser" component={NewUser} />
                <Route path="/user/count/allUser" component={AllUser} />
                <Route path="/user/count/tradeTrend" component={TradeTrend} />
                <Route path="/user/count/tradeDSC" component={TradeDSC} />
                <Route path="/user/admin" component={UserAdmin} />
                <Route path="/user/home" component={Home} />
                <Route path="/user/403" component={Authority} />
                <Route path="/user/500" component={ServerError} />
                <Route path="/user/themeManage" component={ThemeManage} />
                <Route component={NotFound} />
              </Switch>
            </Content>
            <Footer className={style.footer}>
              Copyright © 2019 Stringon. All Rights Reserved.
            </Footer>
          </Layout>
        </Layout>
      </>
    );
  }
}
export default App;
