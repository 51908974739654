import * as Types from '../action-types';

const initState = {
  formatItemNumber: [0],
};
const productFormat = (state = initState, action) => {
  switch (action.type) {
  case Types.ADD_FORMAT_ITEM:
    return {
      formatItemNumber: state.formatItemNumber.concat(state.formatItemNumber.length),
    };

  case Types.REMOVE_FORMAT_ITEM:
    return {
      formatItemNumber: state.formatItemNumber.filter(key => key !== action.index),
    };
  default:
    return state;
  }
};
export default productFormat;
