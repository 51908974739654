import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import {
  // Breadcrumb,
  Button, Input, Table, Card, TreeSelect, message,
} from 'antd';
import moment from 'moment';
import actions from '../../store/actions/user';
import style from '../../common/scss/product-list.module.scss';
import { orderPickupList, orderPickupToChain } from '../../api/index';
import { getApi, postApi } from '../../api/common';

const { Search } = Input;
@connect(state => ({
  openKey: state.user.openKey,
}), actions)
class OrderPickups extends Component {
  constructor() {
    super();
    this.quering = {
      status: -1,
      catagory: 'pickups',
    };
    this.state = {
      order_status: -1,
      pageSize: 0,
      columns: [{
        title: '订单编号',
        dataIndex: 'pickup_id',
        key: 'pickup_id',
      }, {
        title: '提交时间',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text) => (
          moment(text).format('YYYY-MM-DD HH:mm:ss')
        ),
      }, {
        title: 'SPU-ID',
        dataIndex: 'spu_id',
        key: 'spu_id',
      }, {
        title: '商品名称',
        dataIndex: 'spu',
        key: 'spu',
        className: `${style.productName}`,
        render: () => '',
      }, {
        title: '数量',
        dataIndex: 'amount',
        key: 'amount',
      }, {
        title: '买家名称',
        dataIndex: 'user_info.nickname',
        key: 'nickname',
      }, {
        title: '买家电话',
        dataIndex: 'cellphone',
        key: 'cellphone',
      }, {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: (val) => {
          switch (val) {
          // case 1: return '未开始';
          // case 2: return '事务发送成功';
          // case 3: return '已上链';
          // case 4: return '已发货';
          case 5: return '确认中';
          case 6: return '已确认';
          case 7: return '已确认';
          case 8: return '上链失败';
          case 9: return '失败';
          default: return '未知';
          }
        },
      }, {
        title: '操作',
        key: 'action',
        render: (text, record) => {
          const vmls = [];
          if (record.status === 8) {
            vmls.push(<Button type="link" onClick={() => this.doPickupToChain(record)}>重新上链</Button>);
          } else if (record.txid) {
            vmls.push(<a key="link-more" href={`${process.env.REACT_APP_DISCOVERVER_PATH}/tx/${record.txid}`} rel="noopener noreferrer" target="_blank">详情</a>);
          }
          return vmls;
        },
      }],
      data: [],
      loading: false,
      total: 0,
      // page: 1,
    };
  }

  componentWillMount() {
    const { changeMenu, changeOpenKey, openKey } = this.props;
    changeMenu('orderPickups');
    const index = openKey.findIndex(item => item === 'sub2');
    const newOpenKey = [].concat(openKey);
    newOpenKey.splice(index, 1);
    changeOpenKey(index === -1 ? ['sub2'] : ['sub2', ...newOpenKey]);
  }

  async componentDidMount() {
    this.getOrderList();
  }

  async getOrderList() {
    try {
      this.setState({
        loading: true,
      });
      const res = await getApi(orderPickupList, this.quering);
      this.setState({
        data: res.data || [],
        total: res.pagination.total,
        pageSize: res.pagination.pageSize,
        loading: false,
      });
    } catch (err) {
      message.error(`获取列表错误：${err.message}`);
      this.setState({
        loading: false,
      });
    }
  }

  // 根据商品类目筛选商品
  statusChange = order_status => {
    this.quering.page_no = 1;
    this.quering.status = order_status;
    this.setState({
      order_status,
    });
    this.getOrderList();
  }

  // 按关键字搜索商品
  search= (keywords) => {
    this.quering.page_no = 1;
    this.quering.keywords = keywords;
    this.getOrderList();
  }

  // 改变页码
  changePage= (page) => {
    this.quering.page_no = page;
    this.getOrderList();
  }

  recurCategory(array) {
    return array.map(p => ({
      title: p.cat_name,
      value: p.id,
      key: p.id,
      children: this.recurCategory(p.children),
    }));
  }

  async asyncPost(name, params) {
    try {
      this.setState({ loading: true });
      const res = await postApi(name, params);
      if (res.code !== 0) {
        throw new Error(`服务器返回错误：${res.code}, ${res.msg}`);
      }
      message.info('操作成功');
      this.getOrderList();
    } catch (err) {
      this.setState({ loading: false });
      message.error(`请求失败：${err.message}`);
    }
  }

  doPickupToChain(record) {
    this.asyncPost(orderPickupToChain, { pickup_id: record.pickup_id });
  }

  render() {
    const {
      columns,
      data,
      order_status,
      loading,
      pageSize,
      total,
    } = this.state;

    const statusData = [
      { value: -1, title: '全部' },
      // { value: 1, title: '未开始' },
      // { value: 2, title: '事务发送成功' },
      // { value: 3, title: '已上链' },
      // { value: 4, title: '已发货' },
      { value: 5, title: '确认中' },
      { value: 6, title: '已确认' },
      { value: 8, title: '上链失败' },
      { value: 9, title: '失败' },
    ];

    return (
      <Card bordered={false} className={style.content}>
        <div className={style.title}>提货订单</div>
        {/* <Breadcrumb className={style.breadCrumb}>
          <Breadcrumb.Item>
            <Link to="/user/productManage">订单</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>提货订单</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className={style.searchCondition}>
          <div className={style.name}>订单列表</div>
          <div className={style.flex1}>
            状态：
            <TreeSelect
              className={style.select}
              value={order_status}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={statusData}
              placeholder="请选择订单状态"
              treeDefaultExpandAll
              onChange={this.statusChange}
              allowClear
            />
          </div>
          <div className={style.flex1}>
            <Search
              className={style.search}
              placeholder="请输入订单编号"
              // suffix={<span />}
              onSearch={this.search}
            />
          </div>
        </div>
        <div className={style.table}>
          <Table
            rowClassName={style.row}
            columns={columns}
            dataSource={data}
            rowKey="pickup_id"
            loading={loading}
            pagination={{
              current: this.quering.page_no,
              total,
              pageSize,
              onChange: this.changePage,
            }}
            // onRow={(record) => ({
            //   onClick: (e) => {
            //     if (e.target.id === 'delete' || e.target.id === 'edit') {
            //       return;
            //     }
            //     const { history } = this.props;
            //     history.push(`/user/productDetail/${record.spu_id}`);
            //   },
            // })}
          />
        </div>
      </Card>
    );
  }
}
export default OrderPickups;
