import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  // Breadcrumb,
  Input, Table, Card, Select, DatePicker, // Button, Option,
} from 'antd';
import moment from 'moment';
// import { Button } from 'antd/lib/radio';
import actions from '../../store/actions/user';
import style from '../../common/scss/product-list.module.scss';
import {
  userList,
} from '../../api/index';
import { getApi } from '../../api/common';

const { Search } = Input;
@connect(state => ({
  openKey: state.user.openKey,
}), actions)
class CustomerAdmin extends Component {
  constructor() {
    super();
    this.quering = {
      // page_size: 10,
    };
    this.state = {
      // pageSize: 10,
      columns: [{
        title: 'ID',
        dataIndex: 'uid',
      }, {
        title: '头像/用户名',
        dataIndex: 'nickname',
      }, {
        title: '手机号',
        dataIndex: 'cellphone',
        render: (text, record) => (
          record.cellphone ? record.cellphone : '-'
        ),
      }, {
        title: '微信号',
        dataIndex: 'province',
        render: (text, record) => (
          record.wechat_id ? record.wechat_id : '-'
        ),
      }, {
        title: '区块链主地址',
        dataIndex: 'wallet_address',
      }, {
        title: '注册时间',
        dataIndex: 'created_at',
        render: (text) => (
          moment(text).format('YYYY-MM-DD HH:mm:ss')
        ),
      }, {
        title: '操作',
        key: 'action',
        render: (text, record) => (
          <Link id="edit" to={`/user/customer/user/${record.uid}`}>
            详情
          </Link>
        ),
      }],
      data: [],
      loading: false,
      total: 0,
    };
  }

  componentWillMount() {
    const { changeMenu, changeOpenKey, openKey } = this.props;
    changeMenu('customerAdmin');
    const index = openKey.findIndex(item => item === 'sub2');
    const newOpenKey = [].concat(openKey);
    newOpenKey.splice(index, 1);
    changeOpenKey(index === -1 ? ['sub2'] : ['sub2', ...newOpenKey]);
  }

  async componentDidMount() {
    this.getUserList();
  }

  async getUserList() {
    try {
      this.setState({
        loading: true,
      });
      const res = await getApi(userList, this.quering);
      if (res.code === 0) {
        this.setState({
          data: res.data,
          total: res.pagination.total,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
        throw new Error(`${res.msg || res.message}`);
      }
    } catch (err) {
      console.log(`${err.message}`);
    }
  }

  getstartTime = (data, dateString) => {
    const startTime = moment(dateString).format('YYYY-MM-DD').valueOf();
    this.setState({
      startTime,
      timeType: 0,
    }, () => this.searchByDate(startTime));
  }

  getendTime = (data, dateString) => {
    const endTime = moment(dateString).format('YYYY-MM-DD').valueOf();
    this.setState({
      endTime,
      timeType: 1,
    }, () => this.searchByDate(endTime));
  }

  searchByDate = () => {
    const {
      timeType, startTime, endTime,
    } = this.state;
    this.quering.page_no = 1;
    if (timeType === 0) {
      this.quering.start_time = startTime;
    }
    if (timeType === 1) {
      this.quering.end_time = endTime;
    }
    this.getUserList();
  }

  // 按关键字搜索商品
  searchName= (name) => {
    this.quering.page_no = 1;
    this.quering.page_size = 10;
    this.quering.keywords = undefined;
    this.quering.nickname = name;
    this.getUserList();
  }

  // 按关键字搜索商品
  search= (keywords) => {
    this.quering.page_no = 1;
    this.quering.page_size = 10;
    this.quering.nickname = undefined;
    this.quering.keywords = keywords;
    this.getUserList();
  }

  // 改变页码
  changePage= (current, pageSize) => {
    const { category_id, group } = this.state;
    this.quering.producer_id = localStorage.getItem('uid');
    this.quering.page_no = current;
    this.quering.page_size = pageSize;
    if (category_id) {
      this.quering.category_id = category_id;
    }
    if (group) {
      this.quering.group_id = group;
    }
    this.getUserList();
  }

  recurCategory(array) {
    return array.map(p => ({
      title: p.cat_name,
      value: p.id,
      key: p.id,
      children: this.recurCategory(p.children),
    }));
  }

  render() {
    const {
      columns,
      data,
      loading,
      total,
    } = this.state;
    const that = this;
    return (
      <div className={style.content}>
        <div className={style.title}>
          <div className={style.title_head}>用户管理</div>
          <div className={style.title_info}>用户管理</div>
        </div>

        <Card bordered={false}>
          <div className={style.searchCondition}>
            <div className={style.name}>关键字:</div>
            <div className={style.flex1}>
              <Search
                className={style.search}
                placeholder="ID/手机号/微信号/用户名"
                // suffix={<span />}
                onSearch={this.search}
              />
            </div>
            <div className={style.name}>所属店铺:</div>
            <div className={style.flex1}>
              <Select className={style.search}>
                {/* {columns.map(item => (
                  <Option
                    id={item.id}
                    title={item.caption}
                    key={item.id}
                    value={item.id}
                  >{item.caption}
                  </Option>
                ))} */}
              </Select>
            </div>
            <div className={style.name}>注册时间:</div>
            <div>
              <DatePicker
                className={style.rangePicker1}
                onChange={this.getstartTime}
                allowClear
                disabledDate={this.disabledStartDate}
              />
              <span className={style.time_info}>—</span>
              <DatePicker
                className={style.rangePicker2}
                onChange={this.getendTime}
                allowClear
                disabledDate={this.disabledEndDate}
              />
            </div>
            {/* <div>
              <Button type="primary">查询</Button>
            </div> */}
          </div>
          <div className={style.table}>
            <Table
              rowClassName={style.row}
              columns={columns}
              dataSource={data}
              rowKey={record => record.uid}
              loading={loading}
              pagination={{
                defaultCurrent: 1,
                current: this.quering.page_no,
                total,
                showQuickJumper: true,
                showSizeChanger: true,
                pageSize: this.quering.page_size,
                onChange: this.changePage,
                onShowSizeChange(current, pageSize) {
                  that.quering.page_no = 1;
                  that.quering.page_size = pageSize;
                  that.getUserList();
                },
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: () => `共 ${total} 条`,
                // current: this.quering.page_no,
                // total,
                // pageSize,
                // onChange: this.changePage,
                // defaultCurrent: 1,
                // showSizeChanger: true,
                // pageSizeOptions: ["10","20","50","100"],
                // onShowSizeChange: (current, pageSize) => {
                //     this.changePageSize(current, pageSize)
                // },
              }}
              // pagination={pagination}
            // onRow={(record) => ({
            //   onClick: (e) => {
            //     if (e.target.id === 'delete' || e.target.id === 'edit') {
            //       return;
            //     }
            //     const { history } = this.props;
            //     history.push(`/user/productDetail/${record.spu_id}`);
            //   },
            // })}
            />
          </div>
        </Card>
      </div>
    );
  }
}
export default CustomerAdmin;
