import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Avatar, Card, Divider, Icon, message, Modal, Form,
} from 'antd';
import { withTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import QrCode from 'qrcode.react';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import actions from '../../store/actions/user';
import style from '../../common/scss/user-admin.module.scss';
import SetPassWord from './password';
import {
  adminDetail, updatePassword,
} from '../../api/index';
import {
  getApi,
  postApi,
} from '../../api/common';

@Form.create({ name: 'userAdmin' })
@connect(state => ({
  openKey: state.user.openKey,
}), actions)
@withTranslation(['common', 'userAdmin'])
class UserAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // hasPubKey: false,
      // pub_key: '',
      // wallet_addr: '',
      // isProducer: false,
      visiblepassWard: false,
      // visiblePubKey: false,
      expired: null,
      cellphone: '...',
      real_name: '',
      uid: null,
      lastlogintime: Date.now(),
      lastloginip: '-',
      // code: props.t('二维码已过期'),
      // showTimeoutTip: 'none',
      showSetCallback: false, // 是否显示修改通知调用的弹窗
      // pickupNotifyUrl: '', // 通知调用地址
      // availableStatus: 0, // 通知调用地址 URl 可用状态
    };
  }

  componentWillMount() {
    const { changeMenu, changeOpenKey, openKey } = this.props;
    changeMenu('userAdmin');
    const index = openKey.findIndex(item => item === 'sub6');
    const newOpenKey = [].concat(openKey);
    newOpenKey.splice(index, 1);
    changeOpenKey(index === -1 ? ['sub6'] : ['sub6', ...newOpenKey]);
  }

  componentDidMount() {
    const { t } = this.props;
    getApi(adminDetail).then((res) => {
      if (res.code === 0) {
        this.setState({
          // hasPubKey: res.data.hasPubKey,
          // pub_key: res.data.pub_key,
          // wallet_addr: res.data.wallet_addr,
          cellphone: res.data.phone,
          real_name: res.data.real_name,
          uid: res.data.id,
          lastlogintime: res.data.last_login_time,
          lastloginip: res.data.last_login_ip,
        });
      } else {
        message.error(res.msg);
      }
    }, () => {
      message.error(t('服务器连接异常'));
    });
  }

  componentWillUnmount() {
    if (this.task) {
      clearInterval(this.task);
    }
    if (this.authTask) {
      clearInterval(this.authTask);
    }
  }

  /**
   * 复制
   */
  copy = (copyCotent) => {
    const { t } = this.props;
    copy(copyCotent);
    message.success(t('已复制'));
  };

  /**
   * 重新生成 API_Secret
   */
  generateApiSecretClick = () => {
    // this.setState({
    //   visibleApiSecret: true,
    // });
  };

  /**
   * 设置密码
   */
  setpassWard = () => {
    this.setState({
      visiblepassWard: true,
    });
  };

  onRef = (ref) => {
    this.child = ref;
  }

  /* 重置secret子组件 */
  onResetRef = (ref) => {
    this.resetChild = ref;
  }

  /**
   * 修改密码
   */
  setPassWordOk = async () => {
    const { t } = this.props;
    const data = await this.child.submitPassWord();
    if (!data) {
      return;
    }
    postApi(updatePassword, data.data).then((res) => {
      if (res.code === 0) {
        message.success(t('密码修改成功'));
        this.setState({
          visiblepassWard: false,
        });
      } else {
        if (data.captchaObj) {
          data.captchaObj.reset();
        }
        message.error(res.msg);
      }
    }, () => {
      message.error(t('服务器连接异常'));
    }).catch(err => console.log(`${err.message}`));
  }

  /**
   * 获取公钥
   */
  getPubKeyApply = () => {
    // const { t } = this.props;
    // pubKeyApplyApi().then((res) => {
    //   if (res.code === 0) {
    //     this.setState({
    //       code: res.data.callback_url.toString(),
    //       expired: res.data.expired,
    //       ticket: res.data.ticket,
    //       showTimeoutTip: 'none',
    //     }, () => {
    //       this.refresh();
    //       this.authRefresh();
    //     });
    //   } else {
    //     message.error(res.msg);
    //   }
    // }, () => {
    //   message.error(t('服务器连接异常'));
    // }).catch(error => console.log(error));
  };

  /**
   * 刷新私钥二维码
   * @param e 参数
   */
  refreshCode = () => {
    this.getPubKeyApply();
  };

  /**
   * 取消展示Model
   */
  handleCancel = () => {
    this.setState({
      // visibleApiSecret: false,
      // visiblePubKey: false,
      visiblepassWard: false,
      // okDisabled: false,
      // visibleSendCode: false,
      // confirmLoading: false,
    });
    this.cancelPubTask();
  };

  // 切换是否显示修改通知调用的弹窗
  toggleSetCallback = () => {
    const { showSetCallback } = this.state;
    this.setState({
      showSetCallback: !showSetCallback,
    });
  }

  // 修改通知调用地址
  handleOk = () => {
    // const { t } = this.props;
    // const { form } = this.props;
    // form.validateFields().then(() => {
    //   this.setState({
    //     showSetCallback: false,
    //   });
    // });
  }

  // 重新连接
  reconnect = () => {
    // const { t } = this.props;
    // reconnectNotifyUrlApi().then(res => {
    //   if (res.code === 0) {
    //     this.setState({
    //       availableStatus: res.data.available_status,
    //     });
    //   } else {
    //     message.error(res.msg);
    //   }
    // }, () => {
    //   message.error(t('服务器连接异常'));
    // }).catch(error => console.log(error));
  }

  refresh() {
    this.cancelPubTask();

    // this.task = setInterval(async () => {
    //   const { ticket } = this.state;
    //   if (!ticket) {
    //     return;
    //   }
    //   await this.refreshData(ticket);
    // }, 4000);
  }

  // 二维码自动刷新
  authRefresh() {
    this.authTask = setInterval(async () => {
      const { expired } = this.state;
      if (expired > 3) {
        this.setState(() => ({
          expired: expired - 1,
        }));
      } else if (expired === 3) {
        this.getPubKeyApply();
      }
    }, 1000);
  }

  cancelPubTask() {
    if (this.task) {
      clearInterval(this.task);
    }
    if (this.authTask) {
      clearInterval(this.authTask);
    }
  }

  // async refreshData(ticket) {
  //   const result = await checkUploadApi(ticket);
  //   if (result) {
  //     if (result.code === 0) {
  //       this.setState({
  //         hasPubKey: result.data.hasPubKey,
  //         pub_key: result.data.pub_key,
  //         wallet_addr: result.data.wallet_addr,
  //       });
  //       this.handleCancel();
  //     } else if (result.code === 1051) {
  //       // result.message = 公钥上传超时，请重新刷新上传
  //       this.setState({ showTimeoutTip: 'block' });
  //     } else if (result.code === 1052) {
  //       // result.message = 等待扫码上传
  //       this.setState({ showTimeoutTip: 'none' });
  //     }
  //   }
  // }

  render() {
    const { t } = this.props;
    // const { form } = this.props;
    const {
      // wallet_addr,
      // isProducer,
      cellphone,
      real_name,
      uid,
      lastlogintime,
      lastloginip,
      // visiblePubKey,
      visiblepassWard,
      // code,
      // showTimeoutTip,
      // pickupNotifyUrl,
      // availableStatus,
      // showSetCallback,
    } = this.state;
    // let status = null;
    // switch (availableStatus) {
    // case 0:
    //   status = null;
    //   break;
    // case 1:
    //   status = <Badge status="success" text={t('通畅')} />;
    //   break;
    // case 2:
    //   status = <Badge status="error" text={t('中断')} />;
    //   break;
    // case 3:
    //   status = <Badge status="warning" text={t('已停用')} />;
    //   break;
    // default:
    //   status = null;
    //   break;
    // }

    return (
      <div className={style.content}>
        <div className={style.leftStyles}>
          <div className={style.title}>
            {t('账号管理')}
          </div>
          <div className={style.wholeStyle}>
            <div className={style.leftStyle}>
              <Avatar size={64} icon="user" />
              <div className={style.infoStyle}>
                <div className={style.nameStyle}>
                  { real_name }
                </div>
                <div className={style.nameStyle}>
                  {cellphone}
                </div>
                <div className={style.nameStyle}>
                  { uid ? `${t('用户ID')}: ${uid}` : '' }
                </div>
              </div>
            </div>
            <div className={style.rightStyle}>
              <div className={style.lastTime}>
                {t('最后登录时间')}：
                <div className="right">
                  {moment(lastlogintime).format('YYYY-MM-DD HH:mm:ss')}
                </div>
              </div>
              <div className={style.lastIP}>
                IP：
                <div className="right">
                  {lastloginip}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card bordered={false}>
          <div className={style.pawStyle}>
            <div className={style.passWord}>
              {t('登录密码')}
              <div className={style.introduce}>
                {t('为保障您的安全')}
              </div>
            </div>
            <div className={style.modify} onClick={this.setpassWard}>
              {t('修改')}
            </div>
          </div>
          <Divider />
          <Modal
            title={(
              <div>
                <Icon type="form" style={{ marginRight: 10 }} />
                {t('修改密码')}
              </div>
            )}
            visible={visiblepassWard}
            onOk={this.setPassWordOk}
            onCancel={this.handleCancel}
            centered
            destroyOnClose
            cancelText={t('取消')}
            okText={t('提交')}
            bodyStyle={{ textAlign: 'center' }}
          >
            <SetPassWord onRef={this.onRef} />
          </Modal>
        </Card>
      </div>

    );
  }
}
export default UserAdmin;
