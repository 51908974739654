import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import {
    Row, Col, Card, DatePicker, message, Radio, Table, Icon
} from 'antd';
import {
    Chart,
    Legend,
    Geom,
    Axis,
    Tooltip,
		View,
} from "bizcharts";
import DataSet from '@antv/data-set';
import actions from '../../store/actions/user';
import style from '../../common/scss/product-list.module.scss';
// import { homeStats } from '../../api/index';
import { getApi } from '../../api/common';

import { countTradeTrendListUrl } from '../../api/index';

const { RangePicker, MonthPicker } = DatePicker;
const ds = new DataSet();
ds.setState('type', '');
@connect(state => ({
    openKey: state.user.openKey,
}), actions)

class tradeTrend extends Component {
    constructor() {
        super();
        const { quering, state, chart } = this._init();

        this.quering = quering
        this.state = state
        this.chart = chart
    };

    componentWillMount() {
        const { changeMenu, changeOpenKey, openKey } = this.props;
        changeMenu('tradeTrend');
        const index = openKey.findIndex(item => item === 'sub7');
        const newOpenKey = [].concat(openKey);
        newOpenKey.splice(index, 1);
        changeOpenKey(index === -1 ? ['sub7'] : ['sub7', ...newOpenKey]);
        this.initData();
    }

    componentDidMount() {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        window.dispatchEvent(event);
    }


    _init() {
        const timeTormat = 'YYYY-MM-DD'
        const start_time = moment().subtract(30, 'days')
        const end_time = moment()

        const quering = {
            start_time: start_time.format(timeTormat), end_time: end_time.format(timeTormat), type: 1, page_no: 1, page_size: 30, // 1. 按日查询 2. 按天查询page_no: 1,
        }
        const start = Object.assign({}, start_time)
        const { ticks, lineRawList } = this.creatLineData(start, end_time, timeTormat)

				const X = 'X'
				const lineY = 'count';
				const intervalY = 'sum';
        const allCount = 'allCount';  // 折线
        const h5Count = 'h5Count';  // 折线
        const wechatCount = 'wechatCount';  // 折线
        // const allSum = 'allSum'; // 柱状
        const h5Sum = 'h5Sum'; // 柱状
        const wechatSum = 'wechatSum'; // 柱状

        const chart = {
            chartIns: null,
            X,
            lineY, // 折线
            intervalY, // 柱状
            title: {
							allCount: '总下单量',  // 折线
							h5Count: 'H5下单量',  // 折线
							wechatCount: '小程序下单量',  // 折线
							// allSum: '总交易额', // 柱状
							h5Sum: 'H5交易额', // 柱状
							wechatSum: '小程序交易额', // 柱状
            },

            legendItem: [
							/* {
								value: allSum,
								marker: {
										symbol: "circle",
										fill: "#3182bd",
										radius: 5
								}
							}, */
							{
									value: allCount,
									marker: {
											symbol: "circle",
											fill: "#ffae6b",
											radius: 5,
									}
							},
							{
									value: h5Count,
									marker: {
											symbol: "circle",
											fill: "#41a2fc",
											radius: 5,
									}
							},
							{
									value: h5Sum,
									marker: {
											symbol: "circle",
											fill: "#fad248",
											radius: 5,
									}
							},
							{
									value: wechatCount,
									marker: {
											symbol: "circle",
											fill: "#54ca76",
											radius: 5,
									}
							},
							{
									value: wechatSum,
									marker: {
											symbol: "circle",
											fill: "#db4c3c",
											radius: 5,
									}
							}
            ],

            position: {
                line: `${X}*${lineY}`, // 折线
                interval: `${X}*${intervalY}`,
            },

            mixScales: {
                X: {
                    ticks,
                },
            },
            lineRawList,
        }

        const state = {
            ...chart,
            columns: [
                // "created_at": "2019-09-27",
                //  "count": 12,
                //  "sum": 823,
                //  "today_all": 0,
                //  "all": 823
                {
                    title: '日期',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: '下单量（H5/小程序）',
                    dataIndex: 'all_count',
										render: (text, record) => (
											`${text}（${record.h5_count}/${record.applet_count}）`
									),
                },
                {
                    title: '交易额（元）（H5/小程序）',
                    dataIndex: 'all_sum',
                    render: (text, record) => (
												`¥ ${numeral(text / 100).format('0,0.00')}（${numeral(record.h5_sum / 100).format('0,0.00')}/${numeral(record.applet_sum / 100).format('0,0.00')}）`
                    ),
                },
                {
                    title: '之前之和（元）（H5/小程序）',
                    dataIndex: 'before_all_sum',
                    render: (text, record) => {
											return `¥ ${numeral(text / 100).format('0,0.00')}（${numeral(record.before_h5_sum / 100).format('0,0.00')}/${numeral(record.before_applet_sum / 100).format('0,0.00')}）`
										},
                },
                {
                    title: '累计（元）（H5/小程序）',
                    dataIndex: 'today_all_sum',
                    render: (text, record) => (
											`¥ ${numeral(text / 100).format('0,0.00')}（${numeral(record.today_h5_sum / 100).format('0,0.00')}/${numeral(record.today_applet_sum / 100).format('0,0.00')}）`
                    ),
                },
            ],
            tableList: [],

            salesPieData: [],

            timeTormat,

            defaultValue: [start_time, end_time],

            timeVisiable: {},
            monthVisiable: { display: 'none' },


        }

        return { quering, state, chart }
    }

    async initData() {
        await this.getTradeList();
		}


    onStatusChange = (e) => {
        if (e && e.target && e.target.value) {

            let start_time, end_time = moment(), timeTormat = 'YYYY-MM-DD'

            const type = e.target.value
            // 1. 按日查 2. 按月查 
            if (type === 1) {
                // this.quering.status = null;
                start_time = moment().subtract(30, 'days')

                const start_format = `${start_time.format(timeTormat)}`
                const end_format = `${end_time.format(timeTormat)}`

                const { mixScales, lineRawList } = this.creatLineData(start_time, end_time, timeTormat)

                this.setState({

                    defaultValue: [start_time, end_time],
                    timeVisiable: {},
                    monthVisiable: { display: 'none' },
                    mixScales,
                    disabledEndMonthPicker: true,
                    lineRawList,
                })
                this.state.lineRawList = lineRawList
                start_time = start_format
                end_time = end_format
            } else {
                start_time = moment().subtract(1, "years")
                end_time = moment().endOf("months")
                timeTormat = 'YYYY-MM'
                const start_format = `${start_time.format(timeTormat)}`
                const end_format = `${end_time.format(timeTormat)}`

                const { mixScales, lineRawList } = this.creatLineData(start_time, end_time, timeTormat, 2, 2)

                this.setState({
                    timeVisiable: { display: 'none' },
                    monthVisiable: { display: 'flex' },
                    mixScales,
                    disabledEndMonthPicker: true,
                    lineRawList,
                })

                this.state.lineRawList = lineRawList
                start_time = start_format
                end_time = end_format
            }
            this.quering.start_time = start_time
            this.quering.end_time = end_time
            this.quering.type = type
            this.quering.page_no = 1;
            this.quering.page_size = 30;
            this.getTradeList();
        }
    }


    // 改变页码
    changePage = (current, pageSize) => {
        this.quering.page_no = current;
        this.quering.page_size = pageSize;
        this.getTradeList();
    }


    disabledDate = (current) => {
        const start = moment().subtract(30, 'days')
        const end = moment()
        return current && (current > end || current < start); // 30 天以前
    }

    disabledStartMonth = (current) => {
        const start_month = moment().subtract(12, "months")
        const end = moment()

        return current && (current > end || current < start_month);
    }

    disabledEndMonth = (current) => {

        const { startMonthRaw = moment().subtract(12, "months") } = this.state
        // const start_month = moment().subtract(12, "months")
        const end = moment()

        return current && (current > end || current < startMonthRaw);
    }

    timeSelected = async (data, dateString) => {

        const start = dateString[0];
        const end = dateString[1];

        if (moment(start).isBefore(moment(end).subtract(31, 'days'))) {
            message.error(`按日查询，时间跨度不能超过31天`);
            return
        }

        this.quering.start_time = start
        this.quering.end_time = end

        // 直接data[0] 会改变选择框的值 指针传递???
        const startRaw = Object.assign({}, data[0])

        const { mixScales, lineRawList } = this.creatLineData(startRaw, data[1])

        this.state.lineRawList = lineRawList

        this.setState({ mixScales })

        await this.getTradeList()
    }


    monthStartSelect = (data, dateString) => {

        this.quering.start_time = dateString
        this.state.startMonthRaw = Object.assign({}, data)

        this.setState({ disabledEndMonthPicker: false })
    }

    monthEndSelect = async (data, dateString) => {

        this.quering.end_time = dateString


        const { startMonthRaw } = this.state

        if (moment(startMonthRaw).isBefore(moment(data).subtract(12, 'months'))) {
            message.error(`按月查询，时间跨度不能超过12月`);
            return
        }

        const { mixScales, lineRawList } = this.creatLineData(startMonthRaw, data, 'YYYY-MM', 2, 2)

        // const { start_time, end_time } = this.quering

        this.setState({ mixScales })
        this.state.lineRawList = lineRawList

        await this.getTradeList()
    }


    creatLineData = (start, end, format = 'YYYY-MM-DD', interval = 2, type = 1) => {
        // let xTime = Object.assign({}, moment(start))
        let xTime = moment(start)
        let eTime = moment(end)
        const ticks = []
        const lineRawList = []
        let count = 0
        while (xTime.isSameOrBefore(eTime)) {
            const time = xTime.format(format)
            count++
            if (count === interval) {
                ticks.push(time)
                count = 0
            }
            lineRawList.push({ time, raw: xTime, format })
            xTime.add(1, `${type === 1 ? 'days' : 'months'}`)
        }

        const mixScales = {
            X: {
                ticks,
            },
        }

        return { ticks, mixScales, lineRawList }
    }

    enabled(start_date, time, format) {
        if (!start_date) {
            return true
        }
        return moment(start_date, format).isBefore(moment(time, format))
    }

    async getTradeList() {
        try {

            const { lineRawList } = this.state
            this.setState({ loading: true });

            const res = await getApi(countTradeTrendListUrl, this.quering);
            if (res.code === 0) {
                const { trade = [], trade_list = [], start_date } = res.data
                const { total } = res.pagination || res.data.pagination
                const mixChartList = []
                const tableList = []
                lineRawList.map(p => {
                    const t = trade.find(u => u.created_at === p.time)
                    if (t) {
                        const { created_at, applet_count, h5_count, applet_sum, h5_sum } = t
                        mixChartList.push({
													X: created_at,
													allCount: h5_count + applet_count,
													wechatCount: applet_count,
													h5Count: h5_count,
													// allSum: h5_sum + applet_sum,
													wechatSum: applet_sum,
													h5Sum: h5_sum,
												})
                    } else {
                        if (this.enabled(start_date, p.time, p.format)) {
                            mixChartList.push({
															X: p.time,
															allCount: 0,
															wechatCount: 0,
															h5Count: 0,
															// allSum: 0,
															wechatSum: 0,
															h5Sum: 0,
														})
                        }
										}

                    const tl = trade_list.find(u => u.created_at === p.time)
                    if (tl) {
                        tableList.push({ ...tl })
                    } else {
                        if (this.enabled(start_date, p.time, p.format)) {
                            tableList.push({
															all_count: 0,
															all_sum: 0,
															applet_count: 0,
															applet_sum: 0,
															before_all_sum: 0,
															before_applet_sum: 0,
															before_h5_sum: 0,
															created_at: p.time,
															h5_count: 0,
															h5_sum: 0,
															today_all_sum: 0,
															today_applet_sum: 0,
															today_h5_sum: 0,
														})
                        }
                    }
                })
								
                tableList.forEach((val, index) => {
                    if (index > 0 && val.before_all_sum === 0 && val.before_applet_sum === 0 && val.before_h5_sum === 0 && val.today_all_sum === 0 && val.today_applet_sum === 0 && val.today_h5_sum === 0) { // 今日新增为0
                        val.before_all_sum = tableList[index - 1].today_all_sum
                        val.before_applet_sum = tableList[index - 1].today_applet_sum
                        val.before_h5_sum = tableList[index - 1].today_h5_sum
                        val.today_all_sum = tableList[index - 1].today_all_sum
                        val.today_applet_sum = tableList[index - 1].today_applet_sum
                        val.today_h5_sum = tableList[index - 1].today_h5_sum
                    }
                })

								const dv = ds.createView().source(mixChartList);
								const dv1 = ds.createView().source(mixChartList);
								dv.transform({
									type: 'fold',
									fields: ['allCount', 'wechatCount', 'h5Count'], // 展开字段集
									key: 'lineY', // key字段
									value: 'count', // value字段
								})
								dv1.transform({
									type: 'fold',
									fields: ['wechatSum', 'h5Sum'], // 展开字段集
									key: 'intervalY', // key字段
									value: 'sum', // value字段
								})
                this.setState({
                    tableList: tableList.reverse(),
                    // mixChartList,
										total,
										dv,
										dv1,
                    loading: false,
                });
            } else {
                this.setState({ loading: false });
                message.error(`${res.msg}`);
            }
        } catch (error) {
            message.error(`${error.message}`);
            console.log('getTradeList error', error);
            this.setState({ loading: false });
        }
    }


    render() {
        const {
            columns, tableList, loading, total = 0,
            timeTormat = 'YYYY-MM-DD',
            defaultValue,
            dv,
            dv1,
            timeVisiable, monthVisiable,
            mixScales,
        } = this.state;

        const { title, legendItem, lineY, intervalY, position } = this.chart

        const legendItemConvert = val => title[val]

        const onGetG2Instance = chart => {
            this.chart.chartIns = chart;
        }

        return (
            <div className={style.content}>

                <div className={style.title}>
                    <div className={style.title_head}>数据统计</div>
                    <div className={style.title_info}>数据统计</div>
                </div>
                <Card bordered={false}>
                    <div style={{ marginTop: '20px' }}>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Icon type="line" rotate='90' style={{ fontSize: '30px', color: '#08c' }} />
                                平台流水：
                                <Radio.Group onChange={e => this.onStatusChange(e)} defaultValue={1}>
                                    <Radio.Button value={1}>日</Radio.Button>
                                    <Radio.Button value={2}>月</Radio.Button>
                                </Radio.Group>
                            </Col>


                            <Col span={8}>
                                <RangePicker showTime={false} format={timeTormat}
                                    // disabledDate={this.disabledDate}
                                    defaultValue={defaultValue}
                                    onChange={this.timeSelected}
                                    style={timeVisiable} />

                                <div style={monthVisiable} >
                                    <MonthPicker
                                        // disabledDate={this.disabledStartMonth}
                                        onChange={this.monthStartSelect}
                                    // onOpenChange={this.monthStartPanlShow}
                                    />
                                    <span style={{ color: '#CCCCCC', fontSize: '10px', marginRight: '5px', marginLeft: '5px', marginTop: '2px' }}>——</span>

                                    <MonthPicker disabledDate={this.disabledEndMonth} onChange={this.monthEndSelect} />
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Row gutter={0}>
                            <Col >
                                <Chart height={400} scale={mixScales} forceFit={true} padding='auto' data={dv1} onGetG2Instance={onGetG2Instance} >
                                    <Legend allowAllCanceled={true} items={legendItem}
																			itemFormatter={legendItemConvert} animate={true} placeholder />
                                    <Axis name={intervalY}
                                        label={{
                                            offset: 25,
                                            textStyle: {
                                                fill: "#3182bd",
                                            },
                                            formatter(text, item, index) {
                                                return `${numeral(text / 100).format('0,0.00')}`
                                            },
                                        }}
                                    />
                                    
                                    <Tooltip />
                                    <Geom type="interval" position={position.interval} color="#3182bd" size={20}
																				tooltip={['intervalY*sum', (intervalY, sum) => {
																					if (intervalY.toLowerCase().includes('sum')) {
																						return {
																							name: title[intervalY],
																							value: `¥ ${numeral(sum / 100).format('0,0.00')}`
																						}
																					}
																					return {
																						name: title[intervalY],
																						value: sum
																					}
																				}]}
																				adjust= {'stack'}
																				color={['intervalY', (value) => {
																					/* if (value === 'allSum') {
																						return '#2b6cbb';
																					} */
																					if (value === 'wechatSum') {
																						return '#41a2fc';
																					}
																					if (value === 'h5Sum') {
																						return '#54ca76';
																					}
																				}]}
                                    />
																		<View data={dv} scale={mixScales}>
																			<Axis name={lineY}
                                        label={{
                                            offset: 25,
                                            textStyle: {
                                                fill: "#fdae6b",
                                            }
																				}}
																				position="right"
                                    	/>
																			<Geom type="line" position={position.line} color="#fdae6b" size={3} shape="smooth"
																					tooltip={['lineY*count', (lineY, count) => {
																						return {
																							name: title[lineY],
																							value: count
																						}
																					}]}
																				// adjust= {'stack'}
																				color={['lineY', (value) => {
																					if (value === 'allCount') {
																						return '#2b6cbb';
																					}
																					if (value === 'wechatCount') {
																						return '#41a2fc';
																					}
																					if (value === 'h5Count') {
																						return '#54ca76';
																					}
																				}]}
																			/>
																		</View>
                                </Chart>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Row>
                            <div className={style.table}>
                                <Table
                                    rowClassName={style.row}
                                    columns={columns}
                                    dataSource={tableList}
                                    rowKey={record => record.created_at}
                                    loading={loading}
                                    pagination={false}
                                ></Table>
                            </div>
                        </Row>
                    </div>


                </Card>
            </div >
        );
    }
}
export default tradeTrend;
