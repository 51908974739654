import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Table, message, Button, Divider,
} from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import actions from '../../store/actions/user';
import style from '../../common/scss/order-detail.module.scss';
import {
  profitsharingDetail, profitsharingExecute, profitsharingRefund,
} from '../../api/index';
import { getApi, postApi } from '../../api/common';
import orderImg from '../../common/images/order-detail.svg';

@connect(state => ({
  openKey: state.user.openKey,
}), actions)
class ProfitsharingDetail extends Component {
  constructor() {
    super();
    this.quering = {};
    this.state = {
      columns: [{
        title: '类型',
        dataIndex: 'type',
        key: 'profit_type',
        // render: (text, record) => {
        //   if (record.profit_type === 9) {
        //     return '平台手续费';
        //   }
        //   if (record.profit_type === 6) {
        //     return '联盟上级伙伴';
        //   }
        //   if (record.profit_type === 4) {
        //     return '店内'.concat(record.profit_level).concat('级伙伴');
        //   }
        //   if (record.profit_type === 5) {
        //     return '联盟伙伴商家';
        //   }
        //   return '-';
        // },
      }, {
        title: '用户名',
        dataIndex: 'name',
        key: 'name',
      }, {
        title: '微信号/商户号',
        dataIndex: 'account',
        key: 'account',
      }, {
        title: '金额',
        dataIndex: 'profit_amount',
        key: 'profit_amount',
        render: (text) => (
          `¥ ${numeral(text / 100).format('0,0.00')}`
        ),
      }, {
        title: '比例',
        dataIndex: 'profit_ratio',
        key: 'profit_ratio',
        render: (text) => (
          `${numeral(text ).format('0.00%')}`
        ),
      }, {
        title: '结算支付订单ID',
        dataIndex: 'wx_order_id',
        key: 'wx_order_id',
      },{
        title: '分润状态',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          if(record.profit_status === 0 || record.profit_status === 1){
            return '待结算';
          }
          if(record.profit_status === 2){
           return '已结算';
         }
         if(record.profit_status === 3){
           return '用户退款';
         }
         if(record.profit_status === 6){
           return '分润为0';
         }
         if(record.profit_status === 7){
           return '找不到联盟用户微信号及实名或无效';
         }
         if(record.profit_status === 8){
           return '分润已错过';
         }
         if(record.profit_status === 9){
           return '错误';
         }
          return '-';
        },
      },
      ],
      info: {},
      divides_data: [],
      loading: false,
    };
  }

  static getDerivedStateFromProps(nextProps, preProps) {
    const { match } = nextProps;
    const order_id = match.params.id;
    if (order_id === preProps.order_id) {
      return null;
    }
    this.external_changing = true;
    return {
      order_id,
    };
  }

  async componentDidMount() {
    const { order_id } = this.state;
    this.getProfitsharingDetail({ order_id });
  }

  async getProfitsharingDetail(params) {
    this.setState({ loading: true });
    await getApi(profitsharingDetail, params).then(res => {
      if (res.code === 0) {
        // 分润状态, 0: 未处理(刚从链上同步到), 1: 已计划 2: 已分润 3: 已退回, 8: 找不到商家 9: 错误
        if (res.info.status === 0 || res.info.status === 1) {
          res.info.status = '待结算';
        }
        if (res.info.status === 2) {
          res.info.status = '已到帐';
        }
        if (res.info.status === 3) {
          res.info.status = '用户退款';
        }
        if (res.info.status === 8) {
          res.info.status = '找不到商家';
        }
        if (res.info.status === 9) {
          res.info.status = '错误';
        }
        if (res.info.created_at) {
          res.info.created_at = moment(res.info.created_at).format('YYYY-MM-DD HH:mm:ss');
        }
        res.info.amount = this.fomatAmount(res.info.amount);
        if (!res.info.cost) {
          res.info.cost = 0;
        }
        res.info.cost = this.fomatAmount(res.info.cost);
        this.setState({
          info: res.info,
          divides_data: res.divides_data,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }

  fomatAmount = (amount) => {
    let formated = 0;
    if (amount < 0) {
      formated = `-¥ ${numeral(Math.abs(amount) / 100).format('0,0.00')}`;
    } else {
      formated = `¥ ${numeral(amount / 100).format('0,0.00')}`;
    }
    return formated;
  }

  print = () => {

  }

  refund = () => {
    const { order_id } = this.state;
    this.profitsharingRefund({ order_id });
  }

  execute = () => {
    const { order_id } = this.state;
    this.profitsharingExecute({ order_id });
  }

  async profitsharingExecute({ order_id }) {
    this.setState({ loading: true });
    await postApi(profitsharingExecute, { order_id }).then(res => {
      if (res.code === 0) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }

  async profitsharingRefund({ order_id }) {
    this.setState({ loading: true });
    await postApi(profitsharingRefund, { order_id }).then(res => {
      if (res.code === 0) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }

  render() {
    const {
      columns,
      info,
      divides_data,
      loading,
    } = this.state;

    return (
      <div className={style.content}>
        <div className={`${style.orderDetail} ${style.clearfix}`}>
          <div className={style.detailInfo}>
            <div className={style.toOrder}>
              <Link to="/user/order/liquidation" className={style.linkInfo1}>清算订单</Link>
            </div>
            <div className={style.userInfo2}>/</div>
            <div className={style.userInfo2}>清算订单详情</div>
            <div className={style.title}>
              <img src={orderImg} alt="图片" />
              <div className={style.orderId}>单号: {info.order_id}</div>
            </div>
            <div className={style.orderInfo}>
              <div className={style.orderItem}>
                <div className={style.orderTitle}>来源店铺: {info.merchant_name}</div>
                <div className={style.orderTitle}>购买订单: {info.order_id}</div>
              </div>
              <div className={style.orderItem}>
                <div className={style.orderTitle}>订单时间: {info.created_at}</div>
                <div className={style.orderTitle}>支付订单: {info.wx_order_id}</div>
              </div>
              <div className={style.orderItem}>
                <div className={style.orderTitle}>TxID:
                 <a
                  href={`${process.env.REACT_APP_DISCOVERVER_PATH}/tx/${info.txid}`}
                  className={style.linkInfo1}
                  rel="noopener noreferrer"
                  target="_blank"
                 >
                 {info.txid}
                 </a>
                </div>
                <div className={style.orderTitle}>订单金额: {info.amount}</div>
              </div>
            </div>

          </div>
          <div className={style.rightInfo}>
            <Button key="print" className={style.buttonStyle} onClick={() => this.print()}>
              打印
            </Button>
            <Button key="refund" className={style.buttonStyle} onClick={() => this.refund()}>
              用户退款
            </Button>
            <Button key="execute" className={style.buttonStyle} type="primary" onClick={() => this.execute()}>
              立即结算
            </Button>
            <div className={style.itemStyle}>
              <div className={style.item1}>
                <div>分润状态</div>
                <div className={info.status ==="待结算" ? style.status1 : style.status2}>{info.status}</div>
              </div>
              <div className={style.item2}>
                <div>分润总额</div>
                <div className={style.money}>{info.cost}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.table}>
          <div className={style.tableTitle}>
            分润详情
          </div>
          <Divider />
          <Table
            rowClassName={style.row}
            columns={columns}
            dataSource={divides_data}
            rowKey={(record, index)=> index}
            loading={loading}
            className={style.tableStyle}
          />
        </div>
      </div>
    );
  }
}
export default ProfitsharingDetail;
