import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Signin from '../signin/signin';
import Signup from '../signup/signup';
import Reset from '../reset/reset';
import style from '../../common/scss/profile.module.scss';
import logo from '../../common/images/wing_logo.png';

class UserProfile extends Component {
  render() {
    return (
      <div className={style.container}>
        <div className={style.logoWrap}>
          <div className={style.logo}>
            <img className={style.logoImg} src={logo} alt="" />
          </div>
          {/* <span className={style.title}>生产者平台</span> */}
          <div className={style.description}>物影钱包</div>
        </div>
        <div className={style.wrap}>
          <Route path="/profile/signin" component={Signin} />
          <Route path="/profile/signup" component={Signup} />
          <Route path="/profile/reset" component={Reset} />
        </div>
        <div className={style.footer}>
          Copyright © 2019 Stringon.All Rights Reserved.
        </div>
      </div>
    );
  }
}
export default UserProfile;
