// 设置默认路径和拦截器
import axios from 'axios';
import history from '../index';
// axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
axios.defaults.baseURL = '/admin/api';

const geetest = '/v1/geetest/register'; // 极验验证
const checklogin = '/v1/checklogin'; // 登录验证
const login = '/v1/manager/login'; // 登录
const logout = '/v1/manager/logout'; // 退出登录
const menuList = '/v1/manager/myMenu'; // 获取菜单列表
const adminDetail = '/v1/manager/adminDetail'; // 管理员详情
const updatePassword = '/v1/manager/updatePassword'; // 重置密码

/* 发货相关 */
const deliveryUrl = 'v1/pickup/list'; // 发货单查询
const refundUrl = 'v1/refund/list'; // 退货单查询

/* 用户相关 */
const userList = '/v1/user/list'; // 查看用户列表
const userBalance = '/v1/user/balance'; // 查看用户数字凭证列表
const userHistory = '/v1/user/history'; // 查看用户明细记录
const producer = '/v1/producer/list'; // 查看认证商审核
const producerList = '/v1/producer/producerList'; // 查看认证商认证
const applyDetail = '/v1/producer/applyDetail'; // 查看认证商认证详情
const producerConfirm = '/v1/producer/confirmApply'; // 审核生产商申请
const applyList = '/v1/prod/spu/apply/list';// 商品-申请-列表
const applyShow = '/v1/prod/spu/apply/show';// 商品-申请-详情
const applyReject = '/v1/prod/spu/apply/reject';// 商品-申请-拒绝通过
const applyCommit = '/v1/prod/spu/apply/commit';// 商品-申请-申请通过
const profitDetail = '/v1/user/profit_detail';// 分润详情
const partnerDetail = '/v1/user/partner_detail';// 联盟伙伴
const businessList = '/v1/merchant/list';// 商家列表

/* 管理员用户相关 */
const managerList = '/v1/manager/list'; // 获取管理员列表
const managerCreate = '/v1/manager/createAdmin'; // 添加管理员
const managerUpdate = '/v1/manager/update'; // 编辑管理员信息
const managerRoleList = '/v1/manager/role/list'; // 获取role列表
const producerDetail = '/v1/producer/producerDetail'; // 生产商详情
const resetNotifyUrl = '/v1/producer/resetNotifyUrl'; // 生产商详情

/* 联盟分润 */
const profitsharingList = '/v1/profitsharing/list'; // 清算列表
const profitsharingDetail = '/v1/profitsharing/detail'; // 清算详情
const profitsharingExecute = '/v1/profitsharing/execute'; // 清算执行
const profitsharingRefund = '/v1/profitsharing/refund'; // 清算退款
const profitsharingUpdateSetting = '/v1/profitsharing/setting'; // 联盟分润设置
const profitsharingSetting = '/v1/profitsharing/setting'; // 联盟分润设置

const roleList = '/v1/role/list'; // 角色查询
const roleCreate = '/v1/role/create'; // 角色创建
const roleUpdate = '/v1/role/update'; // 角色更新
const funcList = '/v1/role/func/list'; // 功能查询

const tokenList = '/v1/token/list'; // token发行列表
const tokenDetail = '/v1/token/detail'; // token发行详情
const tokenTransferList = '/v1/token/transfer/list'; // token transfer list
const tokenConfirm = '/v1/token/confirm'; // 通过
const tokenTransferToChain = '/v1/token/transfer/tochain'; // 重新发起转账上链
const assetsList = '/v1/assets/list';// 资产列表
const assetDetail = '/v1/assets/detail';// 资产详情

const prodCategory = '/v1/product/category/list'; // 获取商品类型树信息
const prodGroupList = '/v1/product/group/list'; // 商品分组-分组列表
const skuList = '/v1/product/sku/list'; // 获取sku列表
const spuList = '/v1/product/list'; // 商品-获取列表信息
const spuPutdown = '/v1/product/putdown'; // 商品上架
const spuSoldout = '/v1/product/soldout'; // 商品上架
const skuSetRank = '/v1/product/setrank'; // 商品排序
const skuSetPrice = '/v1/product/setprice'; // 商品价格
const skuSort = '/v1/product/sort'; // 商品排序

// const orderList = '/v1/order/list'; // 订单列表
const orderPickupList = '/v1/order/pickup/list'; // 提货订单列表
const orderPickupToChain = '/v1/order/pickup/tochain'; // 重新发起提货上链

// 用户统计---------------------------------------
const countNewUserListUrl = '/v1/stat/userStat'; //  新增用户统计
const countAllUserListUrl = '/v1/stat/userAccumulative'; //  累计用户统计
const countTradeTrendListUrl = 'v1/stat/tradeTrend'; //  交易额统计
const countTradeDistributionListUrl = 'v1/stat/tradeDistribution'; //  交易分布统计
const greetingList = '/v1/manager/greeting/list'; // 贺卡主题列表
const getUploadToken = '/v1/manager/getUploadToken'; // 获取上传凭证
const greetingCreate = '/v1/manager/greeting/create'; // 创建贺卡主题
const greetingUpdate = '/v1/manager/greeting/update'; // 创建贺卡主题
const greetingDetail = '/v1/manager/greeting/detail'; // 贺卡详情
const greetingTop = '/v1/manager/greeting/top'; // 贺卡置顶

const homeStats = '/v1/home/stats'; // 首页统计

axios.interceptors.request.use(config => {
  const newConfig = {
    ...config,
  };
  const token = localStorage.getItem('token');
  newConfig.headers = {
    ...config.headers,
    ...token ? {
      token,
    } : null,
  };
  return newConfig;
});

axios.interceptors.response.use(res => {
  if (res.data.code === 1000) {
    history.push('/profile/signin');
  } else if (res.data.code === 500) {
    setTimeout(() => {
      history.push('/user/500');
    }, 300);
  } else if (res.data.code === 404) {
    setTimeout(() => {
      history.push('/user/404');
    }, 300);
  } else if (res.data.code === 403) {
    setTimeout(() => {
      history.push('/user/403');
    }, 300);
  }
  return res.data;
});

export {
  axios,
  updatePassword,
  geetest,
  checklogin,
  login,
  menuList,
  deliveryUrl,
  refundUrl,
  logout,
  adminDetail,

  profitsharingList,
  profitsharingDetail,
  profitsharingExecute,
  profitsharingRefund,
  profitsharingUpdateSetting,
  profitsharingSetting,

  userList,
  userBalance,
  userHistory,
  producer,
  producerList,
  producerConfirm,
  applyDetail,
  applyList,
  applyShow,
  applyCommit,
  applyReject,
  profitDetail,
  partnerDetail,
  businessList,
  assetsList,
  assetDetail,

  managerRoleList,
  managerList,
  managerCreate,
  managerUpdate,
  roleList,
  roleCreate,
  roleUpdate,
  funcList,

  tokenList,
  tokenDetail,
  tokenConfirm,
  tokenTransferToChain,

  prodCategory,
  prodGroupList,
  skuList,
  spuList,
  spuPutdown,
  spuSoldout,
  skuSetRank,
  skuSetPrice,
  skuSort,
  producerDetail,
  resetNotifyUrl,

  // orderList,
  orderPickupList,
  orderPickupToChain,
  tokenTransferList,
  getUploadToken,
  homeStats,
  greetingList,
  countNewUserListUrl,
  countAllUserListUrl,
  countTradeTrendListUrl,
  countTradeDistributionListUrl,
  greetingCreate,
  greetingUpdate,
  greetingDetail,
  greetingTop,
};
