
import {
  axios,
  menuList,
  managerList,
  managerCreate,
  managerRoleList,
  updatePassword,
  managerUpdate,
  greetingList,
  getUploadToken,
  greetingCreate,
  greetingUpdate,
  greetingDetail,
  greetingTop,
} from './index';

const api = {
  managerList,
  managerCreate,
  managerRoleList,
  updatePassword,
  managerUpdate,
  greetingCreate,
  greetingUpdate,
  getUploadToken: { method: 'get', url: getUploadToken },
  greetingList: { method: 'get', url: greetingList },
  greetingDetail: { method: 'get', url: greetingDetail },
  greetingTop,
};

Object.entries(api).forEach(([name, value]) => {
  api[name] = (body) => axios[value.method || 'post']((value.url || value), value.method === 'get' ? { params: body } : body);
});

export default api;

export const myMenu = () => axios.get(menuList);
