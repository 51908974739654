import {
  axios, geetest, checklogin,
} from './index';

// 获取极验
export const getGeetestApi = () => axios.get(geetest);

/**
 * 测试是否登录
 * @returns {AxiosPromise<any>}
 */
export const checkLogin = () => axios.get(checklogin);

export const getApi = (name, params) => axios.get(name, { params });
export const postApi = (name, params) => axios.post(name, { ...params });
