import * as Types from '../action-types';

const initState = {
  uid: '',
  status: '',
  isProducer: '',
  hasPubKey: '',
};
const signin = (state = initState, action) => {
  switch (action.type) {
  case Types.SET_USER:
    return {
      uid: action.uid,
      status: action.status,
      isProducer: action.isProducer,
      hasPubKey: action.hasPubKey,
    };
  default:
    return state;
  }
};
export default signin;
