import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import style from '../../common/scss/slick.module.scss';
import './slick.css';

export default class ProductInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      slideshow: [],
    };
  }

  componentWillMount() {
    const { slideshow } = this.props;
    this.setState({
      slideshow: slideshow || [],
    });
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      slideshow: props.slideshow || [],
    });
  }

  render() {
    const { nav1, nav2, slideshow } = this.state;
    let bigSlideItems = '';
    let smallSlideItems = '';
    if (slideshow.length) {
      bigSlideItems = slideshow.map(item => (
        <div className={style.bigSlick} key={`${item.res_key}big`}>
          <img src={item.res_key} alt="" />
        </div>
      ));
      smallSlideItems = slideshow.map(item => (
        <div className={style.smallSlick} key={`${item.res_key}small`}>
          <img src={item.res_key} alt="" />
        </div>
      ));
    }
    const slidesToShow = slideshow.length >= 3 ? 3 : slideshow.length;
    return (
      <div className={style.wrap}>
        <Slider
          asNavFor={nav2}
          autoplay
          arrows={false}
          ref={slider => { this.slider1 = slider; }}
        >
          {bigSlideItems}
          {/* <div className={style.bigSlick}>
            <h3>1</h3>
          </div>
          <div className={style.bigSlick}>
            <h3>2</h3>
          </div>
          <div className={style.bigSlick}>
            <h3>3</h3>
          </div>
          <div className={style.bigSlick}>
            <h3>4</h3>
          </div>
          <div className={style.bigSlick}>
            <h3>5</h3>
          </div>
          <div className={style.bigSlick}>
            <h3>6</h3>
          </div> */}
        </Slider>
        <Slider
          asNavFor={nav1}
          ref={slider => { this.slider2 = slider; }}
          slidesToShow={slidesToShow}
          // arrows={false}
          swipeToSlide
          focusOnSelect
          className={style.smallSlide}
        >
          {smallSlideItems}
          {/* <div className={style.smallSlick}>
            <h3>1</h3>
          </div>
          <div className={style.smallSlick}>
            <h3>2</h3>
          </div>
          <div className={style.smallSlick}>
            <h3>3</h3>
          </div>
          <div className={style.smallSlick}>
            <h3>4</h3>
          </div>
          <div className={style.smallSlick}>
            <h3>5</h3>
          </div>
          <div className={style.smallSlick}>
            <h3>6</h3>
          </div> */}
        </Slider>
      </div>
    );
  }
}
