import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Tabs,
  Table,
  Icon,
  Card,
  message,
  Button,
  TreeSelect,
  Radio,
  Input,
  Select,
  DatePicker,
} from 'antd';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import numeral from 'numeral';
import actions from '../../store/actions/user';
import {
  userList,
  spuPutdown,
  spuSoldout,
  skuSetPrice,
  skuSort,
  userBalance,
  userHistory,
  profitDetail,
  partnerDetail,
  businessList,
} from '../../api/index';
import { getApi, postApi } from '../../api/common';
import style from '../../common/scss/customer.module.scss';

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

// const { confirm } = Modal;
@connect(state => ({
  openKey: state.user.openKey,
}), actions)
class CustomerUser extends Component {
  constructor() {
    super();
    this.quering = {};
    this.historyValue = {};
    this.parentValue = {};
    this.state = {
      user_info: {
        nickname: '加载中 ...',
      },
      columns: [
        { title: '商品名称', dataIndex: 'sku.caption', key: 'caption' },
        { title: '数字凭证', dataIndex: 'token_origin', key: 'token_origin' },
        {
          title: '商品规格；单位',
          key: 'unit_name',
          render: (text, record) => {
            const attr = [];
            if (record.sku.specs && Object.keys(record.sku.specs)) {
              Object.keys(record.sku.specs).forEach(item => {
                if (item !== 'image') {
                  attr.push(record.sku.specs[item]);
                }
              });
              attr.push(record.sku.unit_name);
            }
            return attr.join(';');
          },
        },
        {
          title: '商品图片',
          dataIndex: 'picture',
          key: 'picture',
          render: (text, record) => {
            if (record.sku.specs && record.sku.specs.image.res_key) {
              return (
                <img
                  className={style.productImage}
                  src={record.sku.specs.image.res_key}
                  alt="商品图片"
                />
              );
            }
            return '暂无';
          },
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => {
            if (record.status === 1) {
              return '未提货';
            }
            if (record.status === 2) {
              return '已提货';
            }
            if (record.status === 2) {
              return '已发货';
            }
            return '-';
          },
        },
        {
          title: '操作',
          key: 'action',
          render: (text, record) => (
            <div>
              <a key="link-more" href={`${process.env.REACT_APP_DISCOVERVER_PATH}/token/${record.token_origin}`} rel="noopener noreferrer" target="_blank">详情</a>
            </div>
          ),
        },
      ],
      transfercolumns: [
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (text, record) => (
            <div className={style.detailName}>
              <span>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
          ),
        },
        { title: '名称', dataIndex: 'caption', key: 'caption' },
        {
          title: '规格',
          dataIndex: 'unit_name',
          key: 'unit_name',
          render: (text, record) => {
            const attr = [];
            if (Object.keys(record.specs)) {
              Object.keys(record.specs).forEach(item => {
                if (item !== 'image') {
                  attr.push(record.specs[item]);
                }
              });
            }
            attr.push(record.unit_name);
            return attr.join(';');
          },
        },
        { title: '类型', dataIndex: 'type_caption', key: 'type_caption' },
        { title: '数量', dataIndex: 'amount', key: 'amount' },
        { title: '数字凭证', dataIndex: 'token_key', key: 'token_key' },

        // {
        //   title: '商品图片',
        //   dataIndex: 'picture',
        //   key: 'picture',
        //   render: (text, record) => {
        //     if (record.specs.image.res_key) {
        //       return (
        //         <img
        //           className={style.productImage}
        //           src={record.specs.image.res_key}
        //           alt="商品图片"
        //         />
        //       );
        //     }
        //     return '暂无';
        //   },
        // },

        {
          title: 'TXID',
          dataIndex: 'txid',
          key: 'txid',
          render: (text, record) => (
            <div className={style.txidStyle}>
              <a
                key="link-more"
                href={`${process.env.REACT_APP_DISCOVERVER_PATH}/tx/${record.txid}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {record.txid}
              </a>
            </div>
          ),
        },
        { title: '状态',
         dataIndex: 'status_caption', 
         key: 'status_caption',
         render: (text, record) => {
          if (record.status_caption === '已确认') {
            return '已完成';
          }
          return record.status_caption;
        },
         },
      ],
      profitcolumns: [
        { title: '清算订单ID', dataIndex: 'order_id', key: 'order_id' },
        {
          title: '时间',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (text, record) => (
            <div className={style.detailName}>
              <span>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
          ),
        },
        { title: '消费店铺', dataIndex: 'merchant_name', key: 'merchant_name' },
        // { title: '购买支付ID', transaction_id: 'amount', key: 'transaction_id' },
        { 
          title: '商品及交易TXID',
          dataIndex: 'txid',
          key: 'txid',
          render: (text, record) => (
            <div className={style.txidStyle}>
              <a
                key="link-more"
                href={`${process.env.REACT_APP_DISCOVERVER_PATH}/tx/${record.txid}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {record.txid}
              </a>
            </div>
          ), },
        { 
          title: '支付金额', 
          dataIndex: 'total', 
          key: 'total',
          render: (text) => (
            `¥ ${numeral(text / 100).format('0,0.00')}`
          ),
       },
        { title: '店内分润奖励(层级)', dataIndex: 'mall_amount', key: 'mall_amount' , render: (text) => (
          `¥ ${numeral(text / 100).format('0,0.00')}`
        ),},
        { title: '联盟分润奖励(层级)', dataIndex: 'union_amount', key: 'union_amount', render: (text) => (
          `¥ ${numeral(text / 100).format('0,0.00')}`
        ), },

        { title: '结算状态', dataIndex: 'status', key: 'status',
          render: (text, record) => {
           if(record.status === 1){
             return '待结算';
           }
           if(record.status === 2){
            return '已结算';
          }
          if(record.status === 3){
            return '用户退款';
          }
          if(record.status === 6){
            return '分润为0';
          }
          if(record.status === 7){
            return '找不到联盟用户微信号及实名或无效';
          }
          if(record.status === 8){
            return '分润已错过';
          }
          if(record.status === 9){
            return '错误';
          }
          },
      },
        { title: '结算支付ID',
         dataIndex: 'wx_order_id',
          key: 'wx_order_id',
          render: (text, record) => {
            if(record.wx_order_id === ''){
              return '-';
            } 
            else {
              return text;
            }
          }
         },
        {
          title: '结算支付时间',
          dataIndex: 'executed_at',
          key: 'executed_at',
          render: (text, record) => (
            <div className={style.detailName}>
              <span>{record.executed_at ? moment(record.executed_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</span>
            </div>
          ),
        },
      ],
      partnercolumns: [
        { title: '用户ID', dataIndex: 'customer_id', key: 'customer_id' },
        { title: '用户名', dataIndex: 'nickname', key: 'nickname' },
        { 
          title: '手机号', 
          dataIndex: 'cellphone', 
          key: 'cellphone',
          render: (text, record) => {
            if(record.cellphone === ''||record.cellphone === null){
              return '-';
            } 
            else {
              return text;
            }
          }
        },
        {
          title: '微信号', 
          dataIndex: 'wechat_id',
          key: 'wechat_id',
          render: (text, record) => {
            if(record.wechat_id === ''||record.wechat_id === null){
              return '-';
            }
            else {
              return text;
            }
          }
       },

        // { title: '伙伴层级', dataIndex: '', key: '' },
        {
          title: '加入时间',
          dataIndex: 'created_at', 
          key: 'created_at',
          render: (text, record) => (
            <span>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
          ), 
        },
      ],
      data: [],
      historyData: [],
      profitData: [],
      total:0,
      un_total:0,
      partnerData: [],
      // shop_id: '',
      startTime: '',
      endTime: '',
      allianceInfo1: {},
      allianceInfo2: {},
      business: [],
      pageSize: 10,
    };
    this.external_changing = false;
    this.temp_edit_price = 0;
  }

  static getDerivedStateFromProps(nextProps, preProps) {
    const { match } = nextProps;
    const uid = match.params.id;
    if (uid === preProps.uid) {
      return null;
    }

    this.external_changing = true;
    return {
      uid,
    };
  }

  componentDidMount() {
    const { changeMenu, changeOpenKey, openKey } = this.props;
    changeMenu('customerAdmin');
    const index = openKey.findIndex(item => item === 'sub2');
    const newOpenKey = [].concat(openKey);
    newOpenKey.splice(index, 1);
    changeOpenKey(index === -1 ? ['sub2'] : ['sub2', ...newOpenKey]);

    const { uid } = this.state;
    if (uid) {
      this.quering.uid = uid;
      this.quering.status = 0;
      this.getUserList({ uid });
    }
  }

  componentDidUpdate() {
    const { uid } = this.state;
    if (this.external_changing && uid) {
      this.external_changing = false;
      this.getUserList({ uid });
    }
  }

  getUserList= (params) => {
    const { t } = this.props;
    getApi(userList, params).then((res) => {
      if (res.code === 0) {
        if (res.data) {
          this.setState({
            user_info: res.data[0],
          }, () => {
            const { uid, user_info } = this.state;
            this.getUserBalance({ uid, status: 0, address: user_info.wallet_address });
          });
        }
      } else {
        message.error(res.msg);
      }
    }, () => {
      message.error(t('服务器连接异常'));
    }).catch(err => console.log(`${err.message}`));
  }

  // 获取用户数字资产余额
  getUserBalance= (params) => {
    this.setState({ loading: true });
    getApi(userBalance, params).then(res => {
      if (res.code === 0) {
        if (res.data) {
          this.setState({
            loading: false,
            data: res.data,
          });
        }
      } else {
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }

  // 获取交易明细记录
  getUserHistory= (params) => {
    this.setState({ loading: true });
    getApi(userHistory, params).then(res => {
      if (res.code === 0) {
        if (res.data) {
          this.setState({
            loading: false,
            historyData: res.data,
          });
        }
      } else {
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }


  // 分润明细
  getProfitDetail= (params) => {
    this.setState({ loading: true });
    getApi(profitDetail, params).then(res => {
      if (res.code === 0) {
        if (res.data) {
          this.setState({
            loading: false,
            profitData: res.data.profit,
            total:res.data.total/100,
            un_total:res.data.un_total/100,
            profitTotal: res.pagination.total,
          });
        }
      } else {
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }

   // 联盟伙伴
   getPartnerDetail= (params) => {
     this.setState({ loading: true });
     getApi(partnerDetail, params).then(res => {
       if (res.code === 0) {
         if (res.data) {
           if(params.type === 1){
            this.setState({
              loading: false,
              partnerData: res.data.partner,
              allianceInfo1: res.data,
              partnerTotal: res.pagination.total,
            });
           } else {
            this.setState({
              loading: false,
              partnerData: res.data.partner,
              allianceInfo2: res.data,
              partnerTotal: res.pagination.total,
            });
           }
         }
       } else {
         message.error(`${res.msg || res.message}`);
       }
     }).catch(err => console.log(`${err.message}`));
   }

   // 获取用户数字资产余额
  getUserBalance= (params) => {
    this.setState({ loading: true });
    getApi(userBalance, params).then(res => {
      if (res.code === 0) {
        if (res.data) {
          this.setState({
            loading: false,
            data: res.data,
          });
        }
      } else {
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }

   // 获取商家列表
   getBusinessList= () => {
    getApi(businessList).then(res => {
      if (res.code === 0) {
        if (res.data) {
          this.setState({
            business:res.data,
          });
        }
      } else {
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }

  /**
   * 复制
   */
  copy = (copyCotent) => {
    copy(copyCotent);
    message.success('已复制');
  };

  expandedRowRender = item => {
    const columns = [
      {
        dataIndex: 'origin',
        key: 'origin',
        render: (text, record) => (
          <div className={style.detailName}>
            数字凭证:
            <span>{record.origin}</span>
            <Icon className={style.icon} type="copy" onClick={() => this.copy(record.origin)} />
          </div>
        ),
      },
      {
        dataIndex: 'total_supply',
        key: 'total_supply',
        render: (text, record) => (
          <div className={style.detailName}>
            已发行:
            <span>{record.total_supply}</span>
          </div>
        ),
      },
      {
        dataIndex: 'Finished',
        key: 'Finished',
        render: () => (
          <div className={style.detailName}>
            已提货:
            <span>-</span>
          </div>
        ),
      },
      {
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text, record) => (
          <div className={style.detailName}>
            最新发行时间:
            <span>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
          </div>
        ),
      },
    ];
    const dataSource = item.rows;
    return (
      <Table
        showHeader={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey="origin"
      />
    );
  }

  // 翻页显示数据
  changePage=(page) => {
    const { uid } = this.state;
    this.getSkuList({
      uid,
    });
  }

  changeProfitPage = (page) => {
    this.quering.page_no = page;
    this.getProfitDetail(this.quering);
  }

  changePartnerPage = (page) => {
    const { uid, pageSize } = this.state;
    this.parentValue.page_size = pageSize;
    this.parentValue.page_no = page;
    this.getPartnerDetail(this.parentValue);
  }

  onStatusChange = (e) => {
    const { uid, user_info } = this.state;
    let status;
    if (e && e.target && e.target.value) {
      if (e.target.value === '0') {
        status = 0;
      } else {
        status = e.target.value;
      }
      this.getUserBalance({ uid, status, address: user_info.wallet_address });
    }
  }

  historyStatusChange = (e) => {
    let status;
    if (e && e.target && e.target.value) {
      if (e.target.value === '0') {
        status = 0;
      } else {
        status = e.target.value;
      }
      this.historyValue.status = status;
      this.getUserHistory(this.historyValue);
    }
  }

  historyTypeChange= (e) => {
    let type;
    if (e && e.target && e.target.value) {
      if (e.target.value === '0') {
        type = 0;
      } else {
        type = e.target.value;
      }
      this.historyValue.type = type;
      this.getUserHistory(this.historyValue);
    }
  }

   // 按关键字搜索
   search = (keyword) => {
     const { pageSize } = this.state;
     this.setState({
       loading: true,
     });
     this.quering.page_no = 1;
     this.quering.keywords = keyword;
     this.getProfitDetail(this.quering);
   }

   handleChange = (value) => {
     const { pageSize } = this.state;
     this.setState({
       loading: true,
     });
     this.quering.status = value;
     this.quering.page_no = 1;
     this.getProfitDetail(this.quering);
   }

   searchBusiness = (appid) => {
    const { uid } = this.state;
    this.parentValue.uid = uid;
    this.parentValue.type = 2;
    this.parentValue.appid = appid;
    this.getPartnerDetail(this.parentValue);
   }

   getstartTime = (data, dateString) => {
     const startTime = moment(dateString).format('YYYY-MM-DD').valueOf();
     this.setState({
       startTime,
       timeType: 0,
     }, () => this.searchByDate(startTime));
   }

  getendTime = (data, dateString) => {
    const endTime = moment(dateString).format('YYYY-MM-DD').valueOf();
    this.setState({
      endTime,
      timeType: 1,
    }, () => this.searchByDate(endTime));
  }

  searchByDate = () => {
    const {
      startTime, endTime, timeType, pageSize
    } = this.state;
    // const time = [startTime, endTime];
    this.quering.page_no = 1;

    if (timeType === 0) {
      this.quering.start_time = startTime;
    }
    if (timeType === 1) {
      this.quering.end_time = endTime;
    }
    this.quering.page_size = pageSize;
    this.quering.page_no = 1;
    this.getProfitDetail(this.quering);
  }

  async asyncPost(name, params) {
    try {
      this.setState({ loading: true });
      const res = await postApi(name, params);
      if (res.code !== 0) {
        this.setState({ loading: false });
        throw new Error(`${res.msg || res.message}`);
      }
      message.info('操作成功');
      this.refresh();
    } catch (err) {
      this.setState({ loading: false });
      console.log(`${err.msg || err.message}`);
    }
  }
  
  onTabChange = key => {
    const { uid, user_info, pageSize } = this.state;
    if(key === 'tab1'){
      this.getUserBalance({ uid, status: 0, address: user_info.wallet_address });
    } else if(key === 'tab2'){
      this.historyValue.uid = uid;
      this.historyValue.type = 0;
      this.historyValue.address = user_info.wallet_address;
      this.getUserHistory(this.historyValue);
    } else if(key === 'tab3'){
      this.quering.uid = uid;
      this.quering.status = 0;
      this.quering.page_size = pageSize;
      this.quering.page_no = 1;
      this.getProfitDetail(this.quering);
    } else if(key === 'tab4'){
      this.getBusinessList();
      this.parentValue.page_size = pageSize;
      this.parentValue.uid = uid;
      this.parentValue.type = 1;
      this.getPartnerDetail(this.parentValue);
    }
  }


  doPutdown(record) {
    this.asyncPost(spuPutdown, { sku_id: record.sku_id });
  }

  doSoldout(record) {
    this.asyncPost(spuSoldout, { sku_id: record.sku_id });
  }

  doPriceChange(val) {
    this.temp_edit_price = Number.parseFloat(val) || 0;
  }

  doSetPrice(record) {
    if (!this.temp_edit_price) {
      this.setState({
        // editingSkuId: 0,
      });
      return;
    }
    this.asyncPost(skuSetPrice, { sku_id: record.sku_id, price: this.temp_edit_price });
  }


  doSortChange(val) {
    this.temp_edit_sort = Number.parseFloat(val) || 0;
  }

  doSetSort(record) {
    if (!(this.temp_edit_sort >= 0)) {
      this.setState({
        // sortingSkuId: 0,
      });
      return;
    }
    this.asyncPost(skuSort, { sku_id: record.sku_id, sort: this.temp_edit_sort });
  }

  refresh() {
    const { uid } = this.state;
    if (uid) {
      this.setState({
        // editingSkuId: 0,
        // sortingSkuId: 0,
      });
      // this.getUserList({ uid });
      this.getSkuList({ uid });
    }
  }


  render() {
    const {
      loading, columns, data, historyData, transfercolumns, partnerData,allianceInfo1,allianceInfo2,
      user_info, profitcolumns, partnercolumns, profitData,total,un_total,business, pageSize, profitTotal, partnerTotal,// shop_id,
    } = this.state;


    return (
      <Card bordered={false} className={style.content}>
        <div className={style.userInfo1}>
          <Link to="/user/customer/admin" className={style.linkInfo1}>用户管理</Link>
        </div>
        <div className={style.userInfo2}>/</div>
        <div className={style.userInfo2}>用户详情</div>
        <div className={style.userDetail}>
          <div className={style.imgInfo}>
            {
              user_info.avatar ? <img
              className={style.userImg}
              src={user_info.avatar}
              alt="头像"
            /> : <Icon type="user" className={style.iconImg} />
            }
            
          </div>
          <div className={style.table}>
            <div className={style.userId}>
              ID：{user_info.uid}
            </div>
            <Icon className={style.iconStyle} type="copy" onClick={() => this.copy(user_info.uid)} />
            <table cellPadding={5} cellSpacing={5}>
              <tbody>
                <tr>
                  <td>
                    <span className={style.tableTitle}>用户名：</span>
                    {user_info.nickname}
                  </td>
                  <td>
                    <span className={style.tableTitle}>注册时间：</span>
                    {moment(user_info.created_at).format('YYYY-MM-DD HH:mm:ss')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={style.tableTitle}>手机号：</span>
                    {user_info.cellphone}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={style.tableTitle}>微信号：</span>
                    {user_info.wechat_id === ''||user_info.wechat_id === null ? '-' : user_info.wechat_id}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={style.tableTitle}>性别：</span>
                    {user_info.gender === 1 ? '男' : '女'}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={style.tableTitle}>地区：</span>
                    {user_info.province}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span className={style.tableTitle}>区块链主地址：</span>
                    {user_info.wallet_address}
                    <Icon className={style.iconStyle} type="copy" onClick={() => this.copy(user_info.wallet_address)} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={`${style.userOperation} ${style.clearfix} ${style.hide}`}>
            <div>
              <Button className={style.buttonStyle}>次操作</Button>
              <Button className={style.buttonStyle}>次操作</Button>
              <Button className={style.buttonStyle} type="primary">主操作</Button>
            </div>
            <div className={style.userStatus}>
              <div>订单金额</div>
              <div>￥ </div>
            </div>
            <div className={style.userStatus}>
              <div>状态</div>
              <div>正常</div>
            </div>
          </div>
        </div>

        <Tabs defaultActiveKey="tab1" onChange={this.onTabChange}>
          <TabPane tab="用户资产" key="tab1">
            <div className={`${style.screenInfo} ${style.clearfix}`}>
              <div className={style.searchInfo}>
              钱包地址：
                <TreeSelect
                  className={style.search1}
                  // value={category_id}
                  dropdownStyle={{ maxHeight: 400 }}
                  // treeData={categoryData}
                  placeholder="默认全部地址"
                  treeDefaultExpandAll
                  // onChange={this.categoryChange}
                  allowClear
                />
                <span className={style.lineStyle}>—</span>
                <TreeSelect
                  className={style.search2}
                  // value={category_id}
                  dropdownStyle={{ maxHeight: 400 }}
                  // treeData={categoryData}
                  placeholder="所属小程序"
                  treeDefaultExpandAll
                  // onChange={this.categoryChange}
                  allowClear
                />
              </div>
              <div className={style.radoiInfo1}>
              资产状态：
                {/* onChange={onChange} */}
                <Radio.Group onChange={e => this.onStatusChange(e)} defaultValue="0">
                  <Radio.Button value="0">全部</Radio.Button>
                  <Radio.Button value="1">未提货</Radio.Button>
                  <Radio.Button value="2">待发货</Radio.Button>
                  <Radio.Button value="3">已发货</Radio.Button>
                </Radio.Group>
              </div>
            </div>
            <Table
              className={style.table}
              columns={columns}
              dataSource={data}
              rowKey={record => record.balance_id}
              loading={loading}
            />
          </TabPane>
          <TabPane tab="交易记录" key="tab2">
            <div className={`${style.screenInfo} ${style.clearfix}`}>
              <div className={style.searchInfo}>
                钱包地址：
                <TreeSelect
                  className={style.search1}
                  // value={category_id}
                  dropdownStyle={{ maxHeight: 400 }}
                  // treeData={categoryData}
                  placeholder="默认全部地址"
                  treeDefaultExpandAll
                  // onChange={this.categoryChange}
                  allowClear
                />
                <span className={style.lineStyle}>—</span>
                <TreeSelect
                  className={style.search2}
                  // value={category_id}
                  dropdownStyle={{ maxHeight: 400 }}
                  // treeData={categoryData}
                  placeholder="所属小程序"
                  treeDefaultExpandAll
                  // onChange={this.categoryChange}
                  allowClear
                />
              </div>
              <div className={style.radoiInfo2}>
                <div className={style.radoiDetail}>
                交易类型：
                  {/* onChange={onChange} */}
                  <Radio.Group onChange={e => this.historyTypeChange(e)} defaultValue="0">
                    <Radio.Button value="0">全部</Radio.Button>
                    <Radio.Button value="1">收入</Radio.Button>
                    <Radio.Button value="2">转出</Radio.Button>
                    <Radio.Button value="3">提货</Radio.Button>
                    <Radio.Button value="4">退货</Radio.Button>
                  </Radio.Group>
                </div>
                <div className={style.radoiDetail}>
                链上状态：
                  {/* onChange={onChange} */}
                  <Radio.Group onChange={e => this.historyStatusChange(e)} defaultValue="0">
                    <Radio.Button value="0">全部</Radio.Button>
                    <Radio.Button value="2">确认中</Radio.Button>
                    <Radio.Button value="1">已完成</Radio.Button>
                    <Radio.Button value="3">失败</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <Table
              className={style.table}
              columns={transfercolumns}
              dataSource={historyData}
              rowKey={record => record.txid}
              loading={loading}
              pagination={{
                pageSize,
              }}
            />
          </TabPane>
          <TabPane tab="分润明细" key="tab3">
            <div className={style.screenInfo}>
              <div className={style.radoiInfo2}>
                <div className={style.amountItem}>
                  <span className={style.amountInfo}>待结算金额：</span>
                  <span className={style.amountInfo}>￥{un_total}</span>
                </div>
                <div className={style.amountItem}>
                  <span className={style.amountInfo}>累计结算金额：</span>
                  <span className={style.amountInfo}>￥{total}</span>
                </div>
                <div className={style.searchDetail}>
                搜索：
                  {/* <Input
                    placeholder="ID/店铺名称"
                    className={style.inputId}
                    value={shop_id}
                    onChange={e => this.searchId(e)}
                  /> */}
                  <Search
                    className={style.inputId}
                    placeholder="ID/TXID"
                    // suffix={<span />}
                    onSearch={this.search}
                  />
                结算状态：
                  <Select
                    placeholder="请选择"
                    className={style.selectStatus}
                    onChange={this.handleChange}
                  >
                    <Option value="0">全部</Option>
                    <Option value="2">已结算</Option>
                    <Option value="1">待结算</Option>
                    <Option value="3">用户退款</Option>
                  </Select>
                分润类型：
                  <Select
                    placeholder="请选择"
                    className={style.selectStatus}
                  />
                  <div className={style.timeStyle}>
                  时间：
                    <DatePicker
                      className={style.rangePicker1}
                      onChange={this.getstartTime}
                      allowClear
                      disabledDate={this.disabledStartDate}
                    />
                    <span className={style.time_info}>—</span>
                    <DatePicker
                      className={style.rangePicker2}
                      onChange={this.getendTime}
                      allowClear
                      disabledDate={this.disabledEndDate}
                    />
                  </div>
                  {/* <Button type="primary" onclick={this.handSubmit}>查询</Button> */}
                </div>
              </div>

            </div>
            <Table
              className={style.table}
              columns={profitcolumns}
              dataSource={profitData}
              rowKey={record => record.txid}
              loading={loading}
              pagination={{
                total: profitTotal,
                pageSize,
                onChange: this.changeProfitPage
              }}
            />
          </TabPane>
          <TabPane tab="联盟伙伴" key="tab4">
            <div className={style.partner1}>
              <div className={style.partnerInfo}>
                联盟伙伴商铺：{allianceInfo1.merchant_name}
              </div>
              <div className={style.partnerInfo}>
                联盟上级伙伴ID：
                { allianceInfo1.level_id ? <Link to={`/user/customer/user/${allianceInfo1.level_id}`}  className={style.linkInfo1}> {allianceInfo1.level_id}</Link> : '-'}<span>&nbsp;&nbsp;</span>
                <span>{allianceInfo1.level_name && allianceInfo1.level_name !== '' ? '(' : ''}</span>
                <span>&nbsp;</span>{allianceInfo1.level_name !== '' ? allianceInfo1.level_name : ''}<span>&nbsp;</span>
                <span>{allianceInfo1.level_name && allianceInfo1.level_name !== '' ? ')' : ''}</span>
              </div>
              <div className={style.partnerInfo}>
                联盟下级伙伴人数：{allianceInfo1.level_num}
              </div>
            </div>
            <div className={style.partner2}>
              <div className={style.partnerInfo2}>
                查询店铺：
                <Select
                  placeholder="默认查询联盟关系"
                  className={style.partnerSelect}
                  onChange={this.searchBusiness}
                >
                  {business.map(d => (
                    <Option key={d.appid}>{d.merchant_name}</Option>
                  ))}
                </Select>
        
              </div>
              <div className={style.partnerInfo2}>
                对应上级伙伴ID：
                <span>
                { allianceInfo2.level_id ? <Link to={`/user/customer/user/${allianceInfo2.level_id}`}  className={style.linkInfo1}> {allianceInfo2.level_id}</Link> : '-'}<span>&nbsp;&nbsp;</span>
                <span>{allianceInfo2.level_name && allianceInfo2.level_name !== '' ? '(' : ''}</span>
                <span>&nbsp;</span>{allianceInfo2.level_name !== '' ? allianceInfo2.level_name : ''}<span>&nbsp;</span>
                <span>{allianceInfo2.level_name && allianceInfo2.level_name !== '' ? ')' : ''}</span>
                </span>
              </div>
              <div className={style.partnerInfo2}>
                累计下级伙伴人数：{allianceInfo2.level_num}
              </div>
            </div>
            <div className={style.subordinate}>
              下级伙伴列表：
            </div>

            <Table
              className={style.table}
              columns={partnercolumns}
              dataSource={partnerData}
              rowKey={record => record.wechat_id + record.created_at}
              loading={loading}
              pagination={{
                total: partnerTotal,
                pageSize,
                onChange: this.changePartnerPage
              }}
            />
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}
export default (props)=><CustomerUser {...props} key={props.location.pathname} />;
