import React from 'react';
import ReactDOM from 'react-dom';
import {
  Route, Router, Switch,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
// import createBrowserHistory from 'history/createBrowserHistory';
import createHashHistory from 'history/createHashHistory';
import { LocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import store from './store';
import * as serviceWorker from './serviceWorker';
import User from './pages/user/user';
import Profile from './pages/user/profile';
import NotFound from './pages/exception/404';
import Authority from './pages/exception/403';
import ServerError from './pages/exception/500';
import 'antd/dist/antd.css';
import 'ant-design-pro/dist/ant-design-pro.css';

if (module.hot) {
  // 实现热更新
  module.hot.accept();
}
// const history = createBrowserHistory();
const history = createHashHistory();
ReactDOM.render(
  <Provider store={store}>
    <LocaleProvider locale={zhCN}>
      <Router history={history}>
        <Switch>
          <Redirect exact from="/" to="/user/customer/admin" />
          <Route path="/user" component={User} />
          <Route path="/profile" component={Profile} />
          <Route path="/404" component={NotFound} />
          <Route path="/403" component={Authority} />
          <Route path="/500" component={ServerError} />
          {/* <Redirect to="/" /> */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </LocaleProvider>
  </Provider>, document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
export default history;
