import { message } from 'antd';
import * as Types from '../action-types';
import submitApi from '../../api/logout';
// import setAuthToken from '../../api/authtoken';

export default {
  logout(history) {
    return dispatch => {
      submitApi().then((res) => {
        if (res.code === 0) {
          dispatch({
            type: Types.SET_USER,
            ...res.data,
          });
          // setAuthToken(null);
          localStorage.setItem('token', null);
          setTimeout(() => {
            message.success('您已退出登录！');
            history.push('/profile/signin');
          }, 500);
        } else {
          message.error(res.msg || 'Unknown logout error');
        }
      });
    };
  },
};
