import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Button } from 'antd';
import {
  ChartCard, Field, MiniArea, MiniBar, MiniProgress, Bar,
} from 'ant-design-pro/lib/Charts';
import { Trend } from 'ant-design-pro';
import {
  Row, Col, Icon, Tooltip, Card, Tabs, DatePicker, message,
} from 'antd';
import numeral from 'numeral';
// import NumberInfo from 'ant-design-pro/lib/NumberInfo';
// import moment from 'moment';
import actions from '../../store/actions/user';
import { getTimeDistance } from '../../common/utils';
import style from '../../common/scss/home.module.scss';
// import { homeStats } from '../../api/index';
// import { getApi } from '../../api/common';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
@connect(state => ({
  ...state,
}), actions)
class Home extends Component {
  constructor() {
    super();
    this.state = {
      statsData: {},
      rangePickerValue: '',
    };
  }

  componentWillMount() {
    const { changeMenu } = this.props;
    changeMenu('home');

    this.loadData();
  }

  componentDidMount() {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }

  selectDate = type => {
    // const { dispatch } = this.props;
    this.setState({
      rangePickerValue: getTimeDistance(type),
      loading: false,
    });

    /* dispatch({
      type: 'chart/fetchSalesData',
    }); */
  };

  isActive = type => {
    const { rangePickerValue } = this.state;
    const value = getTimeDistance(type);
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0], 'day')
      && rangePickerValue[1].isSame(value[1], 'day')
    ) {
      return style.currentDate;
    }
    return '';
  };

  handleRangePickerChange = rangePickerValue => {
    const { dispatch } = this.props;
    this.setState({
      rangePickerValue,
    });

    dispatch({
      type: 'chart/fetchSalesData',
    });
  };

  async loadData() {
    try {
      this.setState({
        loading: true,
      });
      // const res = await getApi(homeStats, { days: 15 });
      // if (res.status !== 0) {
      //   throw new Error(`${res.status}, ${res.msg || res.message}`);
      // }
      this.setState({
        loading: false,
        // statsData: res.data || {},
      });
    } catch (err) {
      message.error(`${err.message}`);
    }
  }

  render() {
    const { statsData, rangePickerValue, loading } = this.state;
    const amounts = statsData.amounts || {};
    const counts = statsData.counts || {};
    const users = statsData.users || {};
    const visits = statsData.visits || null;
    const converts = statsData.converts || null;

    const previousRatio = 0;
    const weeklyRatio = 0;
    if (!loading) {
    //   const pa = amounts.lastDays[amounts.lastDays.length - 1] || 0;
    //   const wa = amounts.lastDays[amounts.lastDays.length - 7] || 0;
    //   previousRatio = pa && pa.value ? (amounts.today - pa.value) / pa.value * 100 : 100;
    //   weeklyRatio = wa && wa.value ? (amounts.today - wa.value) / wa.value * 100 : 100;
    }

    return (
      <div className={style.content}>
        <Row gutter={16}>
          <Col span={8}>
            <ChartCard
              title="总销售额"
              action={(
                <Tooltip title="指标说明">
                  <Icon type="info-circle-o" />
                </Tooltip>
              )}
              total={() => (
                <span>{loading ? '加载中 ...' : `¥ ${numeral(amounts.total).format('0,0.00')}`}</span>
              )}
              footer={
                <Field label="今日销售额" value={loading ? '...' : numeral(amounts.today).format('0,0.00')} />
              }
              contentHeight={46}
            >
              <span>
                周同比
                <Trend flag={weeklyRatio >= 0 ? 'up' : 'down'} style={{ marginLeft: 8, color: 'rgba(0,0,0,.85)' }}>
                  {loading ? '-' : `${numeral(weeklyRatio).format('0,0.0')}%` }
                </Trend>
              </span>
              <span style={{ marginLeft: 16 }}>
                日环比
                <Trend
                  flag={previousRatio >= 0 ? 'up' : 'down'}
                  style={{ marginLeft: 8, color: 'rgba(0,0,0,.85)' }}
                >
                  {loading ? '-' : `${numeral(previousRatio).format('0,0.0')}%` }
                </Trend>
              </span>
            </ChartCard>
          </Col>
          <Col span={8}>
            <ChartCard
              title="销售量"
              total={loading ? '...' : numeral(counts.total).format('0,0')}
              action={(
                <Tooltip title="指标说明">
                  <Icon type="info-circle-o" />
                </Tooltip>
              )}
              footer={
                <Field label="今日销售量" value={loading ? '...' : numeral(counts.today).format('0,0')} />
              }
            >
              <MiniArea
                line
                height={46}
                color="#975FE4"
                borderColor="#975FE4"
                // data={loading ? [] : counts.lastDays.map(n => ({
                //   x: moment(n.time).format('YYYY-MM-DD'),
                //   y: n.value,
                // }))}
              />
            </ChartCard>
          </Col>
          <Col span={8}>
            <ChartCard
              title="访问量"
              action={<Tooltip title="指标说明"><Icon type="info-circle-o" /></Tooltip>}
              total={users ? numeral(users.total).format('0,0') : '-'}
              footer={<Field label="日访问量" value={users ? numeral(users.today).format('0,0') : '-'} />}
              contentHeight={46}
            >
              <MiniBar
                height={46}
                data={visits ? visits.data : []}
              />
            </ChartCard>
          </Col>
          <Col span={8} hidden>
            <ChartCard
              title="线上购物转化率"
              action={<Tooltip title="指标说明"><Icon type="info-circle-o" /></Tooltip>}
              total={converts ? `${converts.percent}%` : '-'}
              footer={(
                <div>
                  <span>
                    周同比
                    <Trend flag="up" style={{ marginLeft: 8, color: 'rgba(0,0,0,.85)' }}>{converts ? `${converts.percent}%` : '-'}</Trend>
                  </span>
                  <span style={{ marginLeft: 16 }}>
                    日环比
                    <Trend flag="down" style={{ marginLeft: 8, color: 'rgba(0,0,0,.85)' }}>{converts ? `${converts.percent}%` : '-'}</Trend>
                  </span>
                </div>
              )}
              contentHeight={46}
            >
              <MiniProgress
                percent={converts ? converts.percent : 0}
                strokeWidth={8}
                target={converts ? converts.percent + 2 : 0}
              />
            </ChartCard>
          </Col>
          <Col span={24} className={style.sales}>
            <Card loading={loading} bordered={false} bodyStyle={{ padding: 0 }}>
              <div className={style.salesCard}>
                <Tabs
                  tabBarExtraContent={(
                    <div className={style.salesExtraWrap}>
                      <div className={style.salesExtra}>
                        <span className={this.isActive('today')} onClick={() => this.selectDate('today')}>
                          今日
                        </span>
                        <span className={this.isActive('week')} onClick={() => this.selectDate('week')}>
                          本周
                        </span>
                        <span className={this.isActive('month')} onClick={() => this.selectDate('month')}>
                          本月
                        </span>
                        <span className={this.isActive('year')} onClick={() => this.selectDate('year')}>
                          全年
                        </span>
                      </div>
                      <RangePicker
                        value={rangePickerValue}
                        onChange={this.handleRangePickerChange}
                        style={{ width: 256 }}
                      />
                    </div>
                  )}
                  size="large"
                  tabBarStyle={{ marginBottom: 24 }}
                >
                  <TabPane
                    tab="销售额"
                    key="sales"
                  >
                    <Row>
                      <Col xl={24} lg={12} md={12} sm={24} xs={24}>
                        <div className={style.salesBar}>
                          <Bar
                            height={295}
                            title="销售趋势"
                            // data={loading ? [] : amounts.lastDays.map(n => ({
                            //   x: moment(n.time).format('MM-DD'),
                            //   y: n.value,
                            // }))}
                          />
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    tab="访问量"
                    key="views"
                  >
                    <Row>
                      <Col xl={24} lg={12} md={12} sm={24} xs={24}>
                        <div className={style.salesBar}>
                          <Bar
                            height={292}
                            title="访问量趋势"
                            // data={loading ? [] : counts.lastDays.map(n => ({
                            //   x: moment(n.time).format('MM-DD'),
                            //   y: n.value,
                            // }))}
                          />
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Home;
