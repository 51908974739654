import * as Types from '../action-types';

export default {
  changeMenu(menuKey) {
    return { type: Types.CHANGE_SELECTED_KEYS, menuKey };
  },
  changeOpenKey(openKey) {
    /* const rootSubmenuKeys = ['sub1', 'sub2', 'sub3'];
    return (dispatch, getState) => {
      const state = getState();
      const latestOpenKey = openKey.find(key => state.user.openKey.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        dispatch({ type: Types.CHANGE_OPEN_KEY, openKey });
      } else {
        dispatch({ type: Types.CHANGE_OPEN_KEY, openKey: latestOpenKey ? [latestOpenKey] : [] });
      }
    }; */
    return { type: Types.CHANGE_OPEN_KEY, openKey };
  },
};
