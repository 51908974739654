/**
 *  登录
 */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Input,
  Button,
  Form,
  Icon,
  message,
} from 'antd';
import actions from '../../store/actions/signin';
// eslint-disable-next-line no-unused-vars
import geetest from '../../common/gt'; // 禁用这一行的eslint校验
import { getGeetestApi } from '../../api/common';
import style from '../../common/scss/index.module.scss';

@connect(state => ({
  signin: state.signin,
}), actions)
@withRouter
@Form.create({ name: 'signin' })
class Signin extends React.Component {
  static propTypes = {
    form: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
    signin: PropTypes.any.isRequired,
  };

  constructor() {
    super();
    this.state = {
      loading: false, // 是否显示极验的 正在加载...
      hasGetIdentifyCode: false,
    };
  }

  componentWillMount() {
    this.showGeetest();
  }

  /**
   * 显示极验验证码
   */
  showGeetest=() => {
    const { hasGetIdentifyCode } = this.state;
    if (hasGetIdentifyCode) {
      return;
    }
    this.setState({
      loading: true,
    });
    getGeetestApi().then((res) => {
      console.log(res);
      if (res.code !== 0 || !res.data) {
        message.error(`极验服务异常：${res.code}`);
        return;
      }
      window.initGeetest({
        // 以下配置参数来自服务端 SDK
        gt: res.data.gt,
        challenge: res.data.challenge,
        offline: !res.data.success, // 表示用户后台检测极验服务器是否宕机
        new_captcha: res.data.new_captcha, // 用于宕机时表示是新验证码的宕机
        product: 'popup',
        // lang,
        width: '100%',
      }, (captchaObj) => {
        // 将验证码加到id为captcha的元素里，同时会有三个input的值用于表单提交
        captchaObj.appendTo('#captchaBox');
        captchaObj.onReady(() => {
          this.setState({
            hasGetIdentifyCode: true,
            loading: false,
            captchaObj,
          });
        });
        captchaObj.onSuccess(() => {
          const captchaResult = captchaObj.getValidate();
          this.setState({
            captchaResult,
          });
        });
      });
    });
  }

  submit=() => {
    const { form, history, signin } = this.props;
    const { captchaResult, captchaObj } = this.state;
    if (!captchaResult) {
      message.error('请先进行验证');
      return;
    }
    form.validateFields().then(() => {
      const sendData = form.getFieldsValue();
      signin({ ...sendData, ...captchaResult }, history, captchaObj);
    });
  }

  render() {
    const { form } = this.props;
    const { loading } = this.state;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.handleSubmit} className={style.loginForm}>
        <Form.Item>
          {getFieldDecorator('user_name', {
            rules: [{ required: true, message: '请输入用户名' }],
          })(
            <Input size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '请输入密码' }],
          })(
            <Input size="large" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="密码" />,
          )}
        </Form.Item>
        <Form.Item>
          <div id="captchaBox" className={style.geetest} />
          <div className={loading ? '' : style.hide}>正在加载...</div>
          <Button size="large" block type="primary" onClick={this.submit}>
            立即登录
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
export default Signin;
