import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import {
  // Breadcrumb,
  Card, message, InputNumber, Button, // Select, DatePicker, Option,Table,Input,
} from 'antd';
// import moment from 'moment';
// import { Button } from 'antd/lib/radio';
import actions from '../../store/actions/user';
import style from '../../common/scss/product-list.module.scss';
import {
  profitsharingSetting, profitsharingUpdateSetting,
} from '../../api/index';
import { getApi, postApi } from '../../api/common';

// const { Search } = Input;
@connect(state => ({
  openKey: state.user.openKey,
}), actions)
class SplitSetting extends Component {
  constructor() {
    super();
    this.quering = {};
    this.state = {
      data: [],
    };
  }

  componentWillMount() {
    const { changeMenu, changeOpenKey, openKey } = this.props;
    changeMenu('SplitSetting');
    const index = openKey.findIndex(item => item === 'sub3');
    const newOpenKey = [].concat(openKey);
    newOpenKey.splice(index, 1);
    changeOpenKey(index === -1 ? ['sub3'] : ['sub3', ...newOpenKey]);
  }

  async componentDidMount() {
    this.getProfitsharingSetting();
  }

  async getProfitsharingSetting() {
    try {
      const res = await getApi(profitsharingSetting, this.quering);
      if (res.code === 0) {
        this.quering.outer_ratio_0 = res.data.outer_ratio[0] * 100;
        this.quering.outer_ratio_1 = res.data.outer_ratio[1] * 100;
        this.quering.outer_merchant_ratio = res.data.outer_merchant_ratio * 100;
        this.setState({
          data: res.data,
        });
      } else {
        message.erro(`${res.msg || res.message}`);
      }
    } catch (err) {
      console.log(`${err.message}`);
    }
  }

  async updateSetting() {
    const params = {
      profit_league_upper_level1_ratio: parseFloat(this.quering.outer_ratio_0) / 100,
      profit_league_upper_level2_ratio: parseFloat(this.quering.outer_ratio_1) / 100,
      profit_league_mall_ratio: parseFloat(this.quering.outer_merchant_ratio) / 100,
    };
    const res = await postApi(profitsharingUpdateSetting, params);
    if (res.code === 0) {
      message.success('设置成功');
    } else {
      throw new Error(`${res.code}, ${res.msg || res.message}`);
    }
  }

  outer_ratio_0_change(value) {
    if (value) {
      this.quering.outer_ratio_0 = Number(value);
      this.setState({
        data: {
          outer_ratio: [this.quering.outer_ratio_0, this.quering.outer_ratio_1],
          outer_merchant_ratio: this.quering.outer_merchant_ratio,
        },
      });
    }
  }

  outer_ratio_1_change(value) {
    if (value) {
      this.quering.outer_ratio_1 = Number(value);
      this.setState({
        data: {
          outer_ratio: [this.quering.outer_ratio_0, this.quering.outer_ratio_1],
          outer_merchant_ratio: this.quering.outer_merchant_ratio,
        },
      });
    }
  }

  outer_merchant_ratio_change(value) {
    if (value) {
      this.quering.outer_merchant_ratio = Number(value);
      this.setState({
        data: {
          outer_ratio: [this.quering.outer_ratio_0, this.quering.outer_ratio_1],
          outer_merchant_ratio: this.quering.outer_merchant_ratio,
        },
      });
    }
  }

  render() {
    const {
      data,
    } = this.state;
    const { outer_ratio, outer_merchant_ratio } = data;

    let outer_ratio_0 = 0;
    let outer_ratio_1 = 0;
    let outer_merchant_ratio_str = 0;

    if (outer_ratio) {
      outer_ratio_0 = Number(this.quering.outer_ratio_0);
      outer_ratio_1 = Number(this.quering.outer_ratio_1);
    }
    if (outer_merchant_ratio) {
      outer_merchant_ratio_str = Number(this.quering.outer_merchant_ratio);
    }

    return (
      <div className={style.content}>
        <div className={style.title}>
          <div className={style.title_head}>联盟分润</div>
          <div className={style.title_info}>联盟分润</div>
        </div>

        <Card bordered={false}>
          <div className={style.setting_info}>联盟分润比例设置:</div>
          <div className={style.superior_partner}>
            联盟一级伙伴:
            <InputNumber
              className={style.input_style}
              min={0}
              max={100}
              defaultValue={0}
              value={outer_ratio_0}
              onChange={e => this.outer_ratio_0_change(e)}
            />
            %
          </div>
          <div className={style.superior_partner}>
            联盟二级伙伴:
            <InputNumber
              className={style.input_style}
              min={0}
              max={100}
              defaultValue={0}
              value={outer_ratio_1}
              onChange={e => this.outer_ratio_1_change(e)}
            />
            %
          </div>
          <div className={style.superior_partner}>
            联盟伙伴商家:
            <InputNumber
              className={style.input_style}
              min={0}
              max={100}
              defaultValue={0}
              value={outer_merchant_ratio_str}
              onChange={e => this.outer_merchant_ratio_change(e)}
            />
            %
          </div>
          <div className={style.tishi_info}>
            注：设置数值表示该项分润占商家设定分润金额的百分比。
          </div>
          <Button type="primary" onClick={() => this.updateSetting()}>
            保存
          </Button>
        </Card>
      </div>
    );
  }
}
export default SplitSetting;
