import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Input,
  Button,
  Form,
  Row,
  Col,
  Modal,
  message,
} from 'antd';
// eslint-disable-next-line no-unused-vars
import geetest from '../../common/gt'; // 禁用这一行的eslint校验
import { getGeetestApi } from '../../api/common';
import submitApi from '../../api/signup';
import style from '../../common/scss/index.module.scss';

@withRouter
@Form.create({ name: 'signup' })
class Signup extends React.Component {
  static propTypes = {
    form: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
  };

  constructor() {
    super();
    this.state = {
      visible: false, // 是否弹出极验
      loading: false, // 是否显示极验的 正在加载...
      hasGetIdentifyCode: false,
      count: 0, // 验证码倒计时
    };
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /**
   * 显示极验验证码
   */
  showGeetest = () => {
    const { form } = this.props;
    const { hasGetIdentifyCode } = this.state;
    // 手机号没校验通过
    if (!form.getFieldValue('cellphone')) {
      message.error('请输入手机号');
      return;
    }
    this.setState({
      visible: true,
    });
    if (hasGetIdentifyCode) {
      return;
    }
    this.setState({
      loading: true,
    });
    getGeetestApi().then((res) => {
      window.initGeetest({
        // 以下配置参数来自服务端 SDK
        gt: res.data.gt,
        challenge: res.data.challenge,
        offline: !res.data.success, // 表示用户后台检测极验服务器是否宕机
        new_captcha: res.data.new_captcha, // 用于宕机时表示是新验证码的宕机
        product: 'popup',
        // lang,
        width: '100%',
      }, (captchaObj) => {
        // 将验证码加到id为captcha的元素里，同时会有三个input的值用于表单提交
        captchaObj.appendTo('#captchaBox');
        captchaObj.onReady(() => {
          this.setState({
            hasGetIdentifyCode: true,
            loading: false,
            captchaObj,
          });
        });
        captchaObj.onSuccess(() => {
          const captchaResult = captchaObj.getValidate();
          // that._captchaResult = captchaObj.getValidate();
          // that.afterValidate();
          this.setState({
            visible: false,
            captchaResult,
          });
          this.sendCode(); // 发送验证码
        });
        // that._captchaReset = captchaObj;
      });
    });
  }

  /**
   * 发送验证码
   */
  sendCode = () => {
    // const { form } = this.props;
    // const cellphone = form.getFieldValue('cellphone');
    // sendCodeApi(1, cellphone).then((res) => {
    //   if (res.status === 0) {
    //     this.count();
    //   } else {
    //     const { captchaObj } = this.state;
    //     captchaObj.reset();
    //     message.error(res.message);
    //   }
    // });
  };

  /**
   * 发送验证码倒计时
   */
  count = () => {
    this.setState({
      count: 60,
    }, () => {
      this.timer = setInterval(() => {
        const { count } = this.state;
        if (count <= 0) {
          const { captchaObj } = this.state;
          clearInterval(this.timer);
          captchaObj.reset();
          return;
        }
        this.setState({
          count: count - 1,
        });
      }, 1000);
    });
  }

  /**
   * 隐藏极验
   */
  hideGeetest = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * 提交表单注册
   */
  submit = () => {
    const { form, history } = this.props;
    const { captchaResult } = this.state;
    form.validateFields().then(() => {
      const sendData = form.getFieldsValue();
      submitApi({ ...sendData, ...captchaResult }).then((res) => {
        if (res.code === 0) {
          setTimeout(() => {
            message.success('注册成功，请登录');
            history.push('/profile/signin');
          }, 2000);
        } else {
          const { captchaObj } = this.state;
          if (captchaObj) {
            captchaObj.reset();
          }
          message.error(res.message);
        }
      });
    });
  }

  /**
   * 校验再次输入的跟第一次的密码是不是一致
   */
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('两次密码输入不一致');
    } else {
      callback();
    }
  }

  validateCellphone = (rule, value, callback) => {
    const { form } = this.props;
    if (value && !/^1\d{10}/.test(form.getFieldValue('cellphone'))) {
      callback('手机号格式不正确');
    } else {
      callback();
    }
  }

  render() {
    const { form } = this.props;
    const { visible, loading, count } = this.state;
    const { getFieldDecorator } = form;
    return (
      <Form className={style.loginForm} id="signupForm">
        <Form.Item>
          {getFieldDecorator('country_code', {
            rules: [{ required: true, message: '请选择国家码' }],
            initialValue: 86,
          })(
            <Input size="large" placeholder="国家码" type="hidden" />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('cellphone', {
            rules: [{
              required: true, message: '请输入手机号',
            }, {
              validator: this.validateCellphone,
            }],
          })(
            <Input size="large" placeholder="手机号" />,
          )}
        </Form.Item>
        <Form.Item>
          <Row>
            <Col span={16}>
              {getFieldDecorator('vcode', {
                rules: [{ required: true, message: '请输入短信验证码' }],
              })(
                <Input size="large" />,
              )}
            </Col>
            <Col span={8}>
              {
                count > 0 ? (
                  <Button className={`${style.right} ${style.getCode}`} size="large">
                    {`${count} S`}
                  </Button>
                ) : <Button className={`${style.right} ${style.getCode}`} size="large" onClick={this.showGeetest}>获取验证码</Button>
              }
            </Col>
            <Modal
              visible={visible}
              title="请验证"
              onCancel={this.hideGeetest}
              footer={null}
            >
              <div id="captchaBox" />
              <div className={loading ? '' : style.hide}>正在加载...</div>
            </Modal>
          </Row>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{
              required: true, message: '请输入密码',
            }, {
              message: '请输入6-16位密码', min: 6, max: 16,
            }],
          })(
            <Input size="large" type="password" placeholder="6-16位密码，区分大小写" />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('confirmPassword', {
            rules: [{
              required: true, message: '请再次输入密码',
            }, {
              validator: this.compareToFirstPassword,
            }],
          })(
            <Input size="large" type="password" placeholder="确认密码" />,
          )}
        </Form.Item>
        <Form.Item>
          <Button size="large" type="primary" onClick={this.submit} className={style.signupBtn}>
            注册
          </Button>
          <Link className={style.right} to="/profile/signin">使用已有帐户登录</Link>
        </Form.Item>
      </Form>
    );
  }
}
export default Signup;
