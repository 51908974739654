import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {
  Form, Input, message,
} from 'antd';
import { withTranslation } from 'react-i18next';
import { getGeetestApi } from '../../api/common';
import style from '../../common/scss/index.module.scss';

@Form.create({ name: 'setpassword' })
@withTranslation(['common', 'securitySetting'])
class SetPassWord extends Component {
  constructor() {
    super();
    this.state = {
      loading: false, // 是否显示极验的 正在加载...
      hasGetIdentifyCode: false,
    };
  }

  componentWillMount() {
    this.showGeetest();
  }

  componentDidMount() {
    const ref = this.props;
    ref.onRef(this);
  }

  /**
   * 显示极验验证码
   */
  showGeetest=() => {
    const { hasGetIdentifyCode } = this.state;
    if (hasGetIdentifyCode) {
      return;
    }
    this.setState({
      loading: true,
    });
    getGeetestApi().then((res) => {
      window.initGeetest({
        // 以下配置参数来自服务端 SDK
        gt: res.data.gt,
        challenge: res.data.challenge,
        offline: !res.data.success, // 表示用户后台检测极验服务器是否宕机
        new_captcha: res.data.new_captcha, // 用于宕机时表示是新验证码的宕机
        product: 'popup',
        // lang,
        width: '100%',
      }, (captchaObj) => {
        // 将验证码加到id为captcha的元素里，同时会有三个input的值用于表单提交
        captchaObj.appendTo('#captchaBox');
        captchaObj.onReady(() => {
          this.setState({
            hasGetIdentifyCode: true,
            loading: false,
            captchaObj,
          });
        });
        captchaObj.onSuccess(() => {
          const captchaResult = captchaObj.getValidate();
          this.setState({
            captchaResult,
          });
        });
      });
    });
  };

  /**
   * 校验再次输入的跟第一次的新密码是不是一致
   */
  compareToFirstPassword = (rule, value, callback) => {
    const { t } = this.props;
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(t('两次密码输入不一致'));
    } else {
      callback();
    }
  };

  submitPassWord = async () => {
    const { t } = this.props;
    const { form } = this.props;
    const { captchaResult, captchaObj } = this.state;
    if (!captchaResult) {
      message.error(t('请先进行验证'));
      return captchaResult;
    }
    const va = await form.validateFields();
    if (va.errors) {
      return null;
    }
    const sendData = form.getFieldsValue();
    return { data: { ...sendData, ...captchaResult }, captchaObj };
  };

  render() {
    const { form } = this.props;
    const { t } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const { getFieldDecorator } = form;
    const { loading } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item
          {...formItemLayout}
          label={t('输入旧密码')}
        >
          {getFieldDecorator('password', {
            rules: [{ required: true, message: t('请输入旧密码') }, {
              message: t('请输入密码'), min: 6, max: 16,
            }],
          })(
            <Input type="password" />,
          )}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('输入新密码')}
        >
          {getFieldDecorator('newPassword', {
            rules: [{ required: true, message: t('请输入新密码') }, {
              message: t('请输入密码'), min: 6, max: 16,
            }],
          })(
            <Input type="password" />,
          )}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('再次输入新密码')}
        >
          {getFieldDecorator('confirmPassword', {
            rules: [{
              required: true, message: t('请再次输入新密码'),
            }, {
              validator: this.compareToFirstPassword,
            }],
          })(
            <Input type="password" />,
          )}
        </Form.Item>
        <div>
          <div id="captchaBox" className={style.geetest} />
          <div className={loading ? '' : style.hide}>{t('正在加载')}</div>
        </div>
      </Form>
    );
  }
}

export default SetPassWord;
