import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {// Divider,
  Table, message, Tabs,
} from 'antd';
import numeral from 'numeral';
import Slick from './slick';
import ProductInfo from './ProductInfo';
// import moment from 'moment';
import actions from '../../store/actions/user';
import style from '../../common/scss/order-detail.module.scss';
import {
  assetDetail, profitsharingExecute, profitsharingRefund,
} from '../../api/index';
import { getApi, postApi } from '../../api/common';

const { TabPane } = Tabs;
@connect(state => ({
  openKey: state.user.openKey,
}), actions)
class AssetsTokenDetail extends Component {
  constructor() {
    super();
    this.quering = {};
    this.state = {
      columns: [{
        title: 'Token Origin(sku)',
        dataIndex: 'contract_list[0].origin',
        key: 'contract_list[0].origin',
      },{
        title: '商品图片',
        dataIndex: 'picture',
        key: 'picture',
        render: (text, record) => {
          if ( record.specs.image) {
            return (
              <img
                className={style.productImage}
                src={record.specs.image.res_key}
                alt="商品图片"
              />
            );
          }
          return '暂无';
        },
      }, {
        title: '商品名称',
        dataIndex: 'caption',
        key: 'caption',
      },{
        title: '规格；单位',
        key: 'unit_name',
        render: (text, record) => {
          const attr = [];
          if (record.specs && Object.keys(record.specs)) {
            Object.keys(record.specs).forEach(item => {
              if (item !== 'image') {
                attr.push(record.specs[item]);
              }
            });
            attr.push(record.unit_name);
          }
          return attr.join(';');
        },
      }, {
        title: '未提货Token数量',
        dataIndex: 'sum',
        key: 'sum',
      }, {
        title: '已发货Token数量',
        dataIndex: 'pickup',
        key: 'pickup',
      }, {
        title: '持有用户数',
        dataIndex: 'owers',
        key: 'owers',
      },
      ],
      spu_info: {},
      sku_list: [],
      loading: false,
    };
  }

  static getDerivedStateFromProps(nextProps, preProps) {
    const { match } = nextProps;
    const origin = match.params.id;
    if (origin === preProps.origin) {
      return null;
    }
    this.external_changing = true;
    return {
      origin,
    };
  }

  async componentDidMount() {
    const { origin } = this.state;
    this.getTokenDetail({ origin });
  }

  getTokenDetail= (params) => {
    this.setState({ loading: true });
    getApi(assetDetail, params).then(res => {
      if (res.code === 0) {
        res.data.sku_list.forEach(item => {
          item.caption = res.data.caption;
          item.unit_name = res.data.unit_name;
        });
        this.setState({
          spu_info: res.data,
          sku_list: res.data.sku_list,
          loading: false
        });
      } else {
        this.setState({ loading: false });
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }


  fomatAmount = (amount) => {
    let formated = 0;
    if (amount < 0) {
      formated = `-¥ ${numeral(Math.abs(amount) / 100).format('0,0.00')}`;
    } else {
      formated = `¥ ${numeral(amount / 100).format('0,0.00')}`;
    }
    return formated;
  }

  print = () => {

  }

  refund = () => {
    const { origin } = this.state;
    this.profitsharingRefund({ origin });
  }

  execute = () => {
    const { origin } = this.state;
    this.profitsharingExecute({ origin });
  }

  async profitsharingExecute({ origin }) {
    this.setState({ loading: true });
    await postApi(profitsharingExecute, { origin }).then(res => {
      if (res.code === 0) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }

  async profitsharingRefund({ origin }) {
    this.setState({ loading: true });
    await postApi(profitsharingRefund, { origin }).then(res => {
      if (res.code === 0) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        message.error(`${res.msg || res.message}`);
      }
    }).catch(err => console.log(`${err.message}`));
  }

  render() {
    const {
      columns,
      // divides_data,
      loading,
      spu_info,
      sku_list,
    } = this.state;


    return (
      <div className={style.content}>
        <div className={`${style.orderDetail} ${style.clearfix}`}>
          <div className={style.detailInfo}>
            <div className={style.toOrder}>
              <Link to="/user/assets/tokens" className={style.linkInfo1}>资产管理</Link>
            </div>
            <div className={style.userInfo2}>/</div>
            <div className={style.userInfo2}>资产管理详情</div>
            <div className={`${style.productInfoWrap} ${style.clearfix}`}>
              {spu_info.slideshow ? (
                <div className={style.left}>
                  {spu_info.slideshow.length > 0
                    ? <Slick slideshow={spu_info.slideshow} className={style.left} />
                    : null
                  }
                </div>
              ) : null}
              <div className={style.left}>
                <ProductInfo className={style.left} spu_info={spu_info} />
              </div>
            </div>

          </div>
          {/* <div className={style.rightInfo}>
            <Button key="print" className={style.buttonStyle} onClick={() => this.print()}>
              打印
            </Button>
            <Button key="refund" className={style.buttonStyle} onClick={() => this.refund()}>
              用户退款
            </Button>
            <Button key="execute"
            className={style.buttonStyle} type="primary" onClick={() => this.execute()}>
              立即结算
            </Button>

          </div> */}
        </div>
        <Tabs className={style.tabStyle}>
          <TabPane tab="SKU列表" key="tab1">
            <Table
              className={style.table}
              columns={columns}
              dataSource={sku_list}
              rowKey={record => record.sku_id}
              loading={loading}
            />
          </TabPane>
          {/* <TabPane tab="交易记录" key="tab2">
            <Table
              className={style.table}
              columns={transfercolumns}
              // expandRowByClick
              // expandIconAsCell={false}
              // expandIconColumnIndex={-1}
              // expandedRowRender={this.expandedRowRender}
              // onExpandedRowsChange={this.expandedRowsChange}
              dataSource={historyData}
              rowKey={record => record.txid}
              loading={loading}
              // pagination={{
              //   total,
              //   pageSize,
              //   onChange: this.changePage,
              // }}
            />
          </TabPane>
          <TabPane tab="持有人" key="tab3">
            <Table
              className={style.table}
              columns={profitcolumns}
              dataSource={profitData}
              rowKey={record => record.txid}
              loading={loading}
              // pagination={{
              //   total,
              //   pageSize,
              //   onChange: this.changePage,
              // }}
            />
          </TabPane> */}
        </Tabs>

        {/* <div className={style.table}>
          <div className={style.tableTitle}>
            分润详情
          </div>
          <Divider />
          <Table
            rowClassName={style.row}
            columns={columns}
            dataSource={divides_data}
            rowKey={record => record.profit_type + record.profit_level}
            loading={loading}
            className={style.tableStyle}
          />
        </div> */}
      </div>
    );
  }
}
export default AssetsTokenDetail;
