// 登陆成功后获取保存用户信息
export const SET_USER = 'SET_USER';
// 创建商品页面增加规格项
export const ADD_FORMAT_ITEM = 'ADD_FORMAT_ITEM';
// 创建商品页面减少规格项
export const REMOVE_FORMAT_ITEM = 'REMOVE_FORMAT_ITEM';
// 更改左侧菜单列表的 selectedKeys
export const CHANGE_SELECTED_KEYS = 'CHANGE_SELECTED_KEYS';
// 更改左侧菜单列表的 openKey
export const CHANGE_OPEN_KEY = 'CHANGE_OPEN_KEY';
