import { createStore, applyMiddleware, compose } from 'redux';
// import reduxLogger from 'redux-logger';
import reduxThunk from 'redux-thunk';
import reducer from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(
  applyMiddleware(reduxThunk),
));
// window._store = store;
export default store;
