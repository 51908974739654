import * as Types from '../action-types';

const initState = {
  selectedMenuKey: 'index',
  openKey: [],
};
const user = (state = initState, action) => {
  switch (action.type) {
  case Types.CHANGE_SELECTED_KEYS:
    return {
      ...state,
      selectedMenuKey: action.menuKey,
    };
  case Types.CHANGE_OPEN_KEY:
    return {
      ...state,
      openKey: action.openKey,
    };
  default:
    return state;
  }
};
export default user;
