import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Button } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  // Breadcrumb,
  Table, Card, Input, // DatePicker, Radio,Button
} from 'antd';
// import moment from 'moment';
import actions from '../../store/actions/user';
import style from '../../common/scss/product-list.module.scss';
import {
  assetsList,
} from '../../api/index';
import { getApi } from '../../api/common';

const { Search } = Input;
@connect(state => ({
  openKey: state.user.openKey,
}), actions)
class AssetsTokens extends Component {
  constructor() {
    super();
    this.quering = {};
    this.state = {
      pageSize: 0,
      columns: [{
        title: 'SPU-ID',
        key: 'sku.spu_id',
        // dataIndex: 'sku.spu_id',
        render: (record) => {
          console.log(record);
          if (record.sku) {
            return <span>{record.sku.spu_id}</span>;
          }
          return '暂无';
        },
      }, {
        title: '商品图片',
        key: 'image',
        render: (record) => {
          if (record.sku.specs.image && record.sku) {
            return <img className={style.productImage} src={record.sku.specs.image.res_key} alt="商品图片" />;
          }
          return '暂无';
        },
      }, {
        title: '商品名称',
        key: 'producer',
        render: (record) => record.sku.caption,
      }, {
        title: '数字凭证',
        key: 'token_origin',
        dataIndex: 'token_origin',
      },
      {
        title: '生产商家',
        key: 'specs',
        dataIndex: 'producer',
      }, {
        title: '未提货Token数量',
        key: 'sum',
        dataIndex: 'sum',
        render: Number.parseInt,
      },
      {
        title: '已发货Token数量',
        key: 'deliver',
        dataIndex: 'deliver',
      },
      {
        title: '操作',
        key: 'action',
        render: (record) => (
          <>
            <Link to={`/user/assets/token/${record.token_origin}`}>查看详情</Link>
            {/* <Button key="link" type="link">详情</Button> */}
          </>
        ),
      }],
      data: [],
      loading: false,
      total: 0,
      // page: 1,
    };
  }

  componentWillMount() {
    const { changeMenu, changeOpenKey, openKey } = this.props;
    changeMenu('assetsTokens');
    const index = openKey.findIndex(item => item === 'sub5');
    const newOpenKey = [].concat(openKey);
    newOpenKey.splice(index, 1);
    changeOpenKey(index === -1 ? ['sub5'] : ['sub5', ...newOpenKey]);
  }

  async componentDidMount() {
    this.getAssetsListv();
  }

  async getAssetsListv() {
    try {
      this.setState({
        loading: true,
      });
      const res = await getApi(assetsList, this.quering);
      if (res.code === 0) {
        this.setState({
          data: res.data,
          total: res.pagination.total,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        throw new Error(`${res.msg}`);
      }
    } catch (err) {
      console.log(`${err.message}`);
    }
  }

  // 按 Token Origin 搜索商品
  searchName= (origin) => {
    this.quering.page_no = 1;
    this.quering.page_size = 10;
    this.quering.origin = origin;
    this.getAssetsListv();
  }

  // 按关键字搜索商品
  search= (keywords) => {
    this.quering.page_no = 1;
    this.quering.page_size = 10;
    this.quering.keywords = keywords;
    this.getAssetsListv();
  }

  // 改变页码
  changePage= (current, pageSize) => {
    this.quering.page_no = current;
    this.quering.page_size = pageSize;
    this.getAssetsListv();
  }

  render() {
    const {
      columns,
      data,
      loading,
      total,
    } = this.state;
    const that = this;
    return (
      <div className={style.content}>
        <div className={style.title}>
          <div className={style.title_head}>资产管理</div>
          <div className={style.title_info}>资产管理</div>
        </div>

        <Card bordered={false}>
          <div className={style.searchCondition}>
            <div className={style.name}>关键字:</div>
            <div className={style.search_info}>
              <Search
                className={style.search}
                placeholder="数字凭证"
                // suffix={<span />}
                onSearch={this.search}
              />
            </div>
            {/* <div> */}
            {/* <Button type="primary">查询</Button> */}
            {/* </div> */}
          </div>
          <div className={style.table}>
            <Table
              rowClassName={style.row}
              columns={columns}
              dataSource={data}
              rowKey="token_origin"
              loading={loading}
              pagination={{
                defaultCurrent: 1,
                current: this.quering.page_no,
                total,
                showQuickJumper: true,
                showSizeChanger: true,
                pageSize: this.quering.page_size,
                onChange: this.changePage,
                onShowSizeChange(current, pageSize) {
                  that.quering.page_no = 1;
                  that.quering.page_size = pageSize;
                  that.getAssetsListv();
                },
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: () => `共 ${total} 条`,
                // current: this.quering.page_no,
                // total,
                // pageSize,
                // onChange: this.changePage,
              }}
            />
          </div>

        </Card>
      </div>
    );
  }
}
export default AssetsTokens;
