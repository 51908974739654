import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Button } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  // Breadcrumb,Button
  Table, Card, message, DatePicker, Radio, Input,
} from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import actions from '../../store/actions/user';
import style from '../../common/scss/product-list.module.scss';
import {
  profitsharingList,
} from '../../api/index';
import { getApi } from '../../api/common';

const { Search } = Input;
@connect(state => ({
  openKey: state.user.openKey,
}), actions)
class LiquidaTion extends Component {
  constructor() {
    super();
    this.quering = {};
    this.state = {
      columns: [{
        title: '订单时间',
        key: 'created_at',
        dataIndex: 'created_at',
        render: (text) => (
          moment(text).format('YYYY-MM-DD HH:mm:ss')
        ),
      }, {
        title: '清算订单ID',
        key: 'order_id',
        dataIndex: 'order_id',
      }, {
        title: '来源店铺',
        key: 'merchant',
        dataIndex: 'merchant',
      }, {
        title: '订单总额',
        key: 'amount',
        dataIndex: 'amount',
        render: (text) => (
          `¥ ${numeral(text / 100).format('0,0.00')}`
        ),
      }, {
        title: '分润总额',
        key: 'profit_amount',
        dataIndex: 'profit_amount',
        render: (text) => (
          `¥ ${numeral(text / 100).format('0,0.00')}`
        ),
      }, {
        title: '结算状态',
        key: 'status',
        dataIndex: 'status',
        render: (text, record) => {
          // 分润状态, 0: 未处理(刚从链上同步到), 1: 已计划 2: 已分润 3: 已退回, 8: 找不到商家 9: 错误
          if(record.status === 0 || record.status === 1){
            return '待结算';
          }
          if(record.status === 2){
           return '已到账';
         }
         if(record.status === 3){
           return '用户退款';
         }
         if(record.status === 6){
           return '分润为0';
         }
         if(record.status === 7){
           return '找不到联盟用户微信号及实名或无效';
         }
         if(record.status === 8){
           return '分润已错过';
         }
         if(record.status === 9){
           return '错误';
         }
          return '-';
        },
      },
      {
        title: '操作',
        key: 'action',
        render: (record) => (
          <div>
            <Link id="detail" to={`/user/order/detail/${record.order_id}`}>查看详情</Link>
          </div>
        ),
      }],
      data: [],
      loading: false,
      total: 0,
      // page: 1,
    };
  }

  componentWillMount() {
    const { changeMenu, changeOpenKey, openKey } = this.props;
    changeMenu('LiquidaTion');
    const index = openKey.findIndex(item => item === 'sub4');
    const newOpenKey = [].concat(openKey);
    newOpenKey.splice(index, 1);
    changeOpenKey(index === -1 ? ['sub4'] : ['sub4', ...newOpenKey]);
  }

  async componentDidMount() {
    this.getProfitsharingList();
  }

  async getProfitsharingList() {
    try {
      this.setState({
        loading: true,
      });
      const res = await getApi(profitsharingList, this.quering);
      if (res.code === 0) {
        this.setState({
          data: res.data,
          total: res.pagination.total,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        message.erro(`${res.msg}`);
      }
    } catch (err) {
      console.log(`${err.message}`);
    }
  }

  // 按 Token Origin 搜索商品
  searchName= (origin) => {
    this.quering.page_no = 1;
    this.quering.page_size = 10;
    this.quering.origin = origin;
    this.getProfitsharingList();
  }

  // 按关键字搜索商品
  search= (keywords) => {
    this.quering.page_no = 1;
    this.quering.page_size = 10;
    this.quering.keywords = keywords;
    this.getProfitsharingList();
  }

  // 改变页码
  changePage= (current, pageSize) => {
    this.quering.page_no = current;
    this.quering.page_size = pageSize;
    this.getProfitsharingList();
  }

  getstartTime = (data, dateString) => {
    const startTime = moment(dateString).format('YYYY-MM-DD').valueOf();
    this.setState({
      startTime,
      timeType: 0,
    }, () => this.searchByDate(startTime));
  }

  getendTime = (data, dateString) => {
    const endTime = moment(dateString).format('YYYY-MM-DD').valueOf();
    this.setState({
      endTime,
      timeType: 1,
    }, () => this.searchByDate(endTime));
  }

  searchByDate = () => {
    const {
      timeType, startTime, endTime,
    } = this.state;
    this.quering.page_no = 1;
    this.quering.page_size = 10;
    if (timeType === 0) {
      this.quering.start_time = startTime;
    }
    if (timeType === 1) {
      this.quering.end_time = endTime;
    }
    this.getProfitsharingList();
  }

  onStatusChange = (e) => {
    if (e && e.target && e.target.value) {
      if (e.target.value === '100') {
        this.quering.status = null;
      } else {
        this.quering.status = e.target.value;
      }
      this.quering.page_no = 1;
      this.quering.page_size = 10;
      this.getProfitsharingList();
    }
  }

  render() {
    const {
      columns,
      data,
      loading,
      total,
    } = this.state;
    const that = this;
    return (
      <div className={style.content}>
        <div className={style.title}>
          <div className={style.title_head}>清算订单</div>
          <div className={style.title_info}>清算订单</div>
        </div>

        <Card bordered={false}>
          <div className={style.searchCondition}>
            <div className={style.flex1}>
              <DatePicker
                className={style.rangePicker1}
                onChange={this.getstartTime}
                allowClear
                disabledDate={this.disabledStartDate}
              />
              <span className={style.time_info}>—</span>
              <DatePicker
                className={style.rangePicker2}
                onChange={this.getendTime}
                allowClear
                disabledDate={this.disabledEndDate}
              />
              {/* <Button type="primary" className={style.button_style}>
                查询
              </Button> */}

            </div>
            <div className={style.flex1}>
              <Radio.Group onChange={e => this.onStatusChange(e)} defaultValue="100">
                <Radio.Button value="100">全部</Radio.Button>
                <Radio.Button value="0">待结算</Radio.Button>
                <Radio.Button value="2">已到账</Radio.Button>
                <Radio.Button value="3">用户退款</Radio.Button>
              </Radio.Group>
            </div>
            <div className={style.flex1}>
              <Search
                className={style.search}
                placeholder="请输入订单号或店铺名称"
                onSearch={this.search}
              />
            </div>
          </div>
          <div className={style.table}>
            <Table
              rowClassName={style.row}
              columns={columns}
              dataSource={data}
              rowKey={record => record.order_id}
              loading={loading}
              pagination={{
                defaultCurrent: 1,
                current: this.quering.page_no,
                total,
                showQuickJumper: true,
                showSizeChanger: true,
                pageSize: this.quering.page_size,
                onChange: this.changePage,
                onShowSizeChange(current, pageSize) {
                  that.quering.page_no = 1;
                  that.quering.page_size = pageSize;
                  that.getProfitsharingList();
                },
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: () => `共 ${total} 条`,
              }}
            />
          </div>

        </Card>
      </div>
    );
  }
}
export default LiquidaTion;
