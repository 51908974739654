import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';

import {
    Row, Col, Card, DatePicker, message, Radio, Table, Icon, Divider
} from 'antd';
import {
    Chart,
    Legend,
    Geom,
    Axis,
    Tooltip,
		View,
} from "bizcharts";
import DataSet from '@antv/data-set';
import actions from '../../store/actions/user';
import style from '../../common/scss/product-list.module.scss';
// import { homeStats } from '../../api/index';
import { getApi } from '../../api/common';

import { countTradeDistributionListUrl } from '../../api/index';
const ds = new DataSet();
@connect(state => ({
    openKey: state.user.openKey,
}), actions)

class tradeDSC extends Component {
    constructor() {
        super();
        const { quering, state } = this._init();

        this.quering = quering
        this.state = state

    };

    componentWillMount() {
        const { changeMenu, changeOpenKey, openKey } = this.props;
        changeMenu('tradeDSC');
        const index = openKey.findIndex(item => item === 'sub7');
        const newOpenKey = [].concat(openKey);
        newOpenKey.splice(index, 1);
        changeOpenKey(index === -1 ? ['sub7'] : ['sub7', ...newOpenKey]);
        this.initData();
    }

    componentDidMount() {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        window.dispatchEvent(event);
    }


    _init() {
        const timeTormat = 'YYYY-MM-DD'
        const start_time = moment().subtract(30, 'days')
        const end_time = moment()

        const quering = {
            start_time: start_time.format(timeTormat), end_time: end_time.format(timeTormat), type: 1, page_no: 1, page_size: 10, // 1. 按日查询 2. 按天查询page_no: 1,
        }



        const X = 'X'
        const lineY = 'count' // 折线
				const intervalY = 'sum' // 柱状  sum
				const allCount = 'allCount';  // 折线
        const h5Count = 'h5Count';  // 折线
        const wechatCount = 'wechatCount';  // 折线
        const allSum = 'allSum'; // 柱状
        const h5Sum = 'h5Sum'; // 柱状
        const wechatSum = 'wechatSum'; // 柱状
        const allAvg = 'allAvg'; // 柱状
        const h5Avg = 'h5Avg'; // 柱状
        const wechatAvg = 'wechatAvg'; // 柱状
        const title = {
            // lineY: '订单量',
						// intervalY: '累计交易额',
						allCount: '总下单量',  // 折线
						h5Count: 'H5下单量',  // 折线
						wechatCount: '小程序下单量',  // 折线
						// allSum: '总交易额', // 柱状
						h5Sum: 'H5交易额', // 柱状
						wechatSum: '小程序交易额', // 柱状
						allAvg: '所有交易额平均值', // 柱状
						h5Avg: 'h5交易额平均值', // 柱状
						wechatAvg: '小程序交易额平均值', // 柱状
        }

        const chart = {
            chartIns: null,
            X,
            lineY, // 折线
            intervalY, // 柱状

            title,

            position: {
                line: `${X}*${lineY}`, // 折线
                interval: `${X}*${intervalY}`,
            },

            legendItem: [
							/* {
								value: allSum,
								marker: {
										symbol: "circle",
										fill: "#3182bd",
										radius: 5
								}
							}, */
							{
									value: allCount,
									marker: {
											symbol: "circle",
											fill: "#ffae6b",
											radius: 5,
									}
							},
							{
									value: h5Count,
									marker: {
											symbol: "circle",
											fill: "#41a2fc",
											radius: 5,
									}
							},
							{
									value: h5Sum,
									marker: {
											symbol: "circle",
											fill: "#fad248",
											radius: 5,
									}
							},
							{
									value: wechatCount,
									marker: {
											symbol: "circle",
											fill: "#54ca76",
											radius: 5,
									}
							},
							{
									value: wechatSum,
									marker: {
											symbol: "circle",
											fill: "#db4c3c",
											radius: 5,
									}
							},
							{
								value: allAvg,
								marker: {
										symbol: "circle",
										fill: "#3182bd",
										radius: 5
								}
							},
							{
									value: wechatAvg,
									marker: {
											symbol: "circle",
											fill: "#ffae6b",
											radius: 5,
									}
							},
							{
									value: h5Avg,
									marker: {
											symbol: "circle",
											fill: "#41a2fc",
											radius: 5,
									}
							},
            ],
            mixScale: {
                intervalY: {
                    min: 0
                },
                lineY: {
                    min: 0
                },
            },

        }


        const state = {
            ...chart,

            columns: [
                // "appid": "wx44bfdc612bb8dacc",
                //  "count": 120,
                //  "sum": 880,
                //  "avg": 7.3333,
                //  "name": "湃小摊"
                {
                    title: '店铺',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '累计交易额（H5/小程序）',
                    dataIndex: 'all_sum',
                    render: (text, record) => (
                        `¥ ${numeral(text / 100).format('0,0.00')}（${numeral(record.h5_sum / 100).format('0,0.00')}/${numeral(record.applet_sum / 100).format('0,0.00')}）`
                    ),
                },
                {
                    title: '平均订单价（H5/小程序）',
                    dataIndex: 'all_avg',
                    render: (text, record) => (
                        `¥ ${numeral(text / 100).format('0,0.00')}（${numeral(record.h5_avg_sum / 100).format('0,0.00')}/${numeral(record.applet_avg_sum / 100).format('0,0.00')}）`
                    ),
                },
                {
                    title: '订单量（H5/小程序）',
                    dataIndex: 'all_count',
										render: (text, record) => (
											`${text}（${record.h5_count}/${record.applet_count}）`
										)
                },
            ],
            tableList: [],

            // ------------- 复合图 ----------------

            mixChartList: []
        }

        return { quering, state, chart }
    }

    async initData() {
        await this.getTradeList();
    }


    onStatusChange = (e) => {
			console.log(e);
			this.quering.type = e.target.value;
			this.getTradeList();
        /* if (e && e.target && e.target.value) {
            const { mixChartList } = this.state
            const val = e.target.value
            // 2. 平均订单价 1. 累计交易额
            mixChartList.map(item => {
                item.intervalY = val === 1 ? item.intervalYAll : item.intervalYAvg
            })
            const title = {
                lineY: '订单量',
                intervalY: val === 1 ? '累计交易额' : '平均订单价',
            }
            this.setState({ mixChartList, title })
        } */
    }

    // 改变页码
    changePage = (current, pageSize) => {
        this.quering.page_no = current;
        this.quering.page_size = pageSize;
        this.getTradeList();
    }

    async getTradeList() {
        try {
            this.setState({ loading: true });
            const res = await getApi(countTradeDistributionListUrl, this.quering);
            if (res.code === 0) {
                const { mall_trade = [], mall_trade_list = [], all_sum, all_avg, applet_all_sum, h5_all_sum } = res.data
                const { total } = res.pagination

                const allSum = `¥ ${numeral(all_sum / 100).format('0,0.00')}`
                const allAvg = `¥ ${numeral(all_avg / 100).format('0,0.00')}`
                const wechatSum = `¥ ${numeral(applet_all_sum / 100).format('0,0.00')}`
                const h5Sum = `¥ ${numeral(h5_all_sum / 100).format('0,0.00')}`

                const mixChartList = []
                for (const item of mall_trade) {
                    // "name": "其它店铺",  //x 轴 店铺名称
                    // "count": 0,  //y 轴 下单量
                    // "sum": 0,  //y 轴 交易金额(单位为分)
                    // "avg": 0   //y 轴 平均交易额
                    const { name, /* all_sum, */ applet_sum, h5_sum, all_count, applet_count, h5_count, all_avg, h5_avg_sum, applet_avg_sum } = item

                    mixChartList.push({
											X: name,
											// allSum: all_sum,
											wechatSum: applet_sum,
											h5Sum: h5_sum,
											allCount: all_count,
											wechatCount: applet_count,
											h5Count: h5_count,
											allAvg: +all_avg,
											h5Avg: h5_avg_sum,
											wechatAvg: applet_avg_sum,
										})

                }

								const dv = ds.createView().source(mixChartList);
								const dv1 = ds.createView().source(mixChartList);
								if (this.quering.type === 1) {
									dv.transform({
										type: 'fold',
										fields: ['allCount', 'wechatCount', 'h5Count'], // 展开字段集
										key: 'lineY', // key字段
										value: 'count', // value字段
									})
									dv1.transform({
										type: 'fold',
										fields: [/* 'allSum', */ 'wechatSum', 'h5Sum'], // 展开字段集
										key: 'intervalY', // key字段
										value: 'sum', // value字段
									})
								} else if (this.quering.type === 2) {
									dv1.transform({
										type: 'fold',
										fields: ['allAvg', 'wechatAvg', 'h5Avg'], // 展开字段集
										key: 'intervalY', // key字段
										value: 'sum', // value字段
									})
								}
								
                this.setState({
                    tableList: mall_trade_list,
                    total: mall_trade_list.length,
                    mixChartList,
                    allSum,
                    wechatSum,
										h5Sum,
										allAvg,
										dv,
										dv1,
                    loading: false,
                });
            } else {
                this.setState({ loading: false });
                message.error(`${res.msg}`);
            }
        } catch (error) {
            message.error(`${error.message}`);
            console.log('get countTradeDistributionListUrl error', error);
            this.setState({ loading: false });
        }
    }


    render() {
        const {
            columns, tableList, loading, total = 0, dv, dv1,
            currentTime = moment().format('YYYY-MM-DD HH:mm:ss'),
            title, position, lineY, intervalY, mixScale, allAvg = 0, allSum = 0, h5Sum = 0, wechatSum = 0,
        } = this.state;

        const that = this;

        const legendItemConvert = val => title[val]

        const onGetG2Instance = chart => {
            this.state.chartIns = chart;
        }
        return (
            <div className={style.content}>

                <div className={style.title}>
                    <div className={style.title_head}>数据统计</div>
                    <div className={style.title_info}>数据统计</div>
                </div>
                <Card bordered={false}>
                    <div style={{ marginTop: '20px' }}>
                        <Row>
                            <p>
                                <Icon type="line" rotate='90' style={{ fontSize: '30px', color: '#08c' }} />
                                累计交易额
                            </p>
                        </Row>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <Row gutter={24} >
                            <Col span={8} >
                                <div height={500} style={{ border: '1px solid', borderRadius: '10px', borderStyle: 'dashed', marginBottom: '20px' }}>
                                    {/* <img id="u30_img" class="img " src="https://axure-file.lanhuapp.com/63185181-4aab-4009-bc64-e43d52081a72__e2d98e92a1082aff10207c55547fabf5" /> */}
                                    <div style={{ 'margin': '20px' }}>
                                        <div className={style.flex1}>全平台交易额（单位：元） </div>
                                        <p>
                                            <span style={{ color: '#CCCCCC', fontSize: '10px' }}>统计截止时间：{currentTime}</span>
                                        </p>
                                        <span style={{ color: '#333333', fontSize: '32px', fontFamily: 'Arial Negreta' }}>{allSum}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div style={{ border: '1px solid', borderRadius: '10px', borderStyle: 'dashed', marginBottom: '20px' }}>
                                    <div style={{ 'margin': '20px' }}>
                                        <div className={style.flex1}>平均店铺交易额（单位：元） </div>
                                        <p><span style={{ color: '#CCCCCC', fontSize: '10px' }}>统计截止时间：{currentTime}</span></p>
                                        <span style={{ color: '#333333', fontSize: '32px', fontFamily: 'Arial Negreta' }}>{allAvg}</span>
                                    </div>
                                </div>
                            </Col>
														<Col span={8}>
                                <div style={{ border: '1px solid', borderRadius: '10px', borderStyle: 'dashed', marginBottom: '20px' }}>
                                    <div style={{ 'margin': '20px' }}>
                                        <div className={style.flex1}>小程序交易额（单位：元） </div>
                                        <p><span style={{ color: '#CCCCCC', fontSize: '10px' }}>统计截止时间：{currentTime}</span></p>
                                        <span style={{ color: '#333333', fontSize: '32px', fontFamily: 'Arial Negreta' }}>{wechatSum}</span>
                                    </div>
                                </div>
                            </Col>
														<Col span={8}>
                                <div style={{ border: '1px solid', borderRadius: '10px', borderStyle: 'dashed', marginBottom: '20px' }}>
                                    <div style={{ 'margin': '20px' }}>
                                        <div className={style.flex1}>H5交易额（单位：元） </div>
                                        <p><span style={{ color: '#CCCCCC', fontSize: '10px' }}>统计截止时间：{currentTime}</span></p>
                                        <span style={{ color: '#333333', fontSize: '32px', fontFamily: 'Arial Negreta' }}>{h5Sum}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <Row gutter={24} type="flex" justify="space-between">
                            <Col span={16}>
                                <div >
                                    <Icon type="line" rotate='90' style={{ fontSize: '30px', color: '#08c' }} />
                                    单店铺交易额：
                                    <span style={{ color: '#CCCCCC', fontSize: '10px' }}>统计截止时间：{currentTime}</span>
                                </div>
                            </Col>

                            <Col span={8} >
                                <Radio.Group onChange={e => this.onStatusChange(e)} defaultValue={1}>
                                    <Radio.Button value={1}>累计交易额</Radio.Button>
                                    <Radio.Button value={2}>平均订单价</Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <Row gutter={24}>
                            <Col >
                                <Chart height={400} scale={mixScale} forceFit padding='auto' data={dv1} onGetG2Instance={onGetG2Instance} >
                                    <Legend
																		 	allowAllCanceled={true}
                                      itemFormatter={legendItemConvert}
                                    />
                                    <Axis name={intervalY}
                                        label={{
                                            offset: 25,
                                            textStyle: {
                                                fill: "#3182bd",
                                            },
                                            formatter(text, item, index) {
                                                return `${numeral(text / 100).format('0,0.00')}`
                                            },
                                        }}
                                    />
                                    <Tooltip />
                                    <Geom type="interval" position={position.interval} color="#3182bd"
                                        tooltip={['intervalY*sum', (intervalY, sum) => {
																					if (intervalY.toLowerCase().includes('sum') || intervalY.toLowerCase().includes('avg')) {
																						return {
																							name: title[intervalY],
																							value: `¥ ${numeral(sum / 100).format('0,0.00')}`
																						}
																					}
																					return {
																						name: title[intervalY],
																						value: sum
																					}
																				}]}
																				adjust= {this.quering.type === 1 ? 'stack' : [{
																					type: 'dodge',
																					marginRatio: 0, 
																				}]}
																				color={['intervalY', (value) => {
																					if (value === 'allSum' || value === 'allAvg') {
																						return '#2b6cbb';
																					}
																					if (value === 'wechatSum' || value === 'wechatAvg') {
																						return '#41a2fc';
																					}
																					if (value === 'h5Sum' || value === 'h5Avg') {
																						return '#54ca76';
																					}
																				}]}
																		/>
																		{
																			this.quering.type === 1 ? (
																				<View data={dv} >
																					<Axis name={lineY}
																						label={{
																								offset: 25,
																								textStyle: {
																										fill: "#fdae6b",
																								}
																						}}
																						position="right"
																						visible={this.quering.type === 1}
																					/>
																					<Geom type="line" position={position.line} color="#fdae6b" size={3} shape="smooth"
																							tooltip={['lineY*count', (lineY, count) => {
																								return {
																									name: title[lineY],
																									value: count
																								}
																							}]}
																						// adjust= {'stack'}
																						color={['lineY', (value) => {
																							if (value === 'allCount') {
																								return '#2b6cbb';
																							}
																							if (value === 'wechatCount') {
																								return '#41a2fc';
																							}
																							if (value === 'h5Count') {
																								return '#54ca76';
																							}
																						}]}
																					/>
																				</View>
																			) : null
																		}
                                </Chart>
                            </Col>
                        </Row>

                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <Row>
                            <div className={style.table}>
                                <Table
                                    rowClassName={style.row}
                                    columns={columns}
                                    dataSource={tableList}
                                    rowKey={record => record.appid}
                                    loading={loading}
                                    pagination={{
                                        defaultCurrent: 1,
                                        current: this.quering.page_no,
                                        total,
                                        showQuickJumper: true,
                                        showSizeChanger: true,
                                        pageSize: this.quering.page_size,
                                        onChange: this.changePage,
                                        onShowSizeChange(current, pageSize) {
                                            that.quering.page_no = 1;
                                            that.quering.page_size = pageSize;
                                            that.getTradeList();
                                        },
                                        pageSizeOptions: ['10', '20', '50', '100'],
                                        showTotal: () => `共 ${total} 条`,
                                    }}
                                ></Table>
                            </div>
                        </Row>
                    </div>

                </Card>
            </div >
        );
    }
}
export default tradeDSC;
