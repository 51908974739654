import { message } from 'antd';
import * as Types from '../action-types';
import submitApi from '../../api/signin';
// import setAuthToken from '../../api/authtoken';

export default {
  signin(data, history, captchaObj) {
    return dispatch => {
      submitApi(data).then((res) => {
        if (res.code === 0 && res.data) {
          dispatch({
            type: Types.SET_USER,
            ...res.data,
          });
          // setAuthToken(res.data.token);
          localStorage.setItem('oper_id', res.data.oper_id);
          localStorage.setItem('token', res.data.token);
          setTimeout(() => {
            message.success('登录成功');
            history.push('/');
          }, 500);
        } else {
          // const { captchaObj } = this.state;
          if (captchaObj) {
            captchaObj.reset();
          }
          message.error(res.msg || 'Unknown login error');
        }
      });
    };
  },
};
