import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import { Button } from 'antd';
import { Pie, } from 'ant-design-pro/lib/Charts';
import {
    Row, Col, Card, DatePicker, message, Radio, Table, Icon
} from 'antd';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend,

    Coord,
    Label,
    Guide,

} from "bizcharts";
import DataSet from "@antv/data-set";

import actions from '../../store/actions/user';
import style from '../../common/scss/product-list.module.scss';
// import { homeStats } from '../../api/index';
import { getApi } from '../../api/common';

import { countNewUserListUrl } from '../../api/index';

const { RangePicker, MonthPicker } = DatePicker;
// import 'moment/locale/zh-cn';
// moment.locale('zh-cn');
// import locale from 'antd/es/date-picker/locale/zh_CN';

@connect(state => ({
    openKey: state.user.openKey,
}), actions)

class newUser extends Component {
    constructor() {
        super();
        const { quering, state, global } = this._init();

        this.quering = quering
        this.state = state
        this.global = global
    };

    componentWillMount() {
        const { changeMenu, changeOpenKey, openKey } = this.props;
        changeMenu('newUser');
        const index = openKey.findIndex(item => item === 'sub7');
        const newOpenKey = [].concat(openKey);
        newOpenKey.splice(index, 1);
        changeOpenKey(index === -1 ? ['sub7'] : ['sub7', ...newOpenKey]);

        this.initData();
    }

    componentDidMount() {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        window.dispatchEvent(event);
    }


    _init() {
        const timeTormat = 'YYYY-MM-DD'
        const start_time = moment().subtract(30, 'days')
        const end_time = moment()

        const quering = {
            start_time: start_time.format(timeTormat), end_time: end_time.format(timeTormat), type: 1, page_no: 1, page_size: 31, // 1. 按日查询 2. 按天查询page_no: 1,
        }

        const { ticks, lineRawList } = this.creatLineData(start_time, end_time, timeTormat)

        const state = {
            columns: [
                // "created_at": "2019-09-28",
                // "count": 2,
                // "today_all": 19,
                // "all": 21
                {
                    title: '日期',
                    dataIndex: 'created_at',
                    key: 'created_at',
                },
                {
                    title: '今日新增',
                    dataIndex: 'count',
                    key: 'count',
                },
                {
                    title: '之前之和',
                    dataIndex: 'today_all',
                    key: 'today_all',
                },
                {
                    title: '累计',
                    dataIndex: 'all',
                    key: 'all',
                },
                {
                    title: '操作',
                    dataIndex: 'op',
                    key: 'op',
                },
            ],

            tableList: [],

            salesPieData: [
                // "count": 18,
                // "appid": "wx44bfdc612bb8dacc",
                // "name": "湃小摊"
            ],

            timeTormat,

            timeSelectValue: `${quering.start_time} ~ ${quering.end_time}`,

            defaultTime: [start_time, end_time],

            disabledEndMonthPicker: true,

            // ------------- 折线图 ----------------
            lineList: [],

            timeVisiable: {},
            monthVisiable: { display: 'none' },

        }

        const global = {
            lineRawList,
            lineCols: {
                time: {
                    // tickInterval: 100
                    //'identity' | 'linear' | 'cat' | 'time' | 'timeCat' | 'log' | 'pow'
                    // type: 'identity', // 指定数据类型
                    // alias: string, // 数据字段的别名，会影响到轴的标题内容
                    // formatter: () => { }, // 格式化文本内容，会影响到轴的label格式
                    // range: array, // 输出数据的范围，默认[0, 1]，格式为 [min, max]，min 和 max 均为 0 至 1 范围的数据。
                    // tickCount: number, // 设置坐标轴上刻度点的个数
                    // ticks: array, // 用于指定坐标轴上刻度点的文本信息，当用户设置了 ticks 就会按照 ticks 的个数和文本来显示
                    // sync: boolean // 当 chart 存在不同数据源的 view 时，用于统一相同数据属性的值域范围
                    ticks,
                }
            }
        }

        return { quering, state, global }
    }

    async initData() {
        await this.getNewUserList();
    }


    onStatusChange = async (e) => {
        if (e && e.target && e.target.value) {

            let start_time, end_time = moment(), timeTormat = 'YYYY-MM-DD'

            const type = e.target.value

            // 1. 按日查 2. 按月查 
            if (type === 1) {

                start_time = moment().subtract(30, 'days')

                const start_format = `${start_time.format(timeTormat)}`
                const end_format = `${end_time.format(timeTormat)}`


                const { lineCols, lineRawList } = this.creatLineData(start_time, end_time, timeTormat)

                this.setState({
                    timeSelectValue: `${start_format}  ~ ${end_format}`,
                    timeVisiable: {},
                    monthVisiable: { display: 'none' },

                    lineCols,
                    disabledEndMonthPicker: true,
                })

                this.global.lineRawList = lineRawList

                start_time = start_format
                end_time = end_format

            } else {

                start_time = moment().subtract(12, "months")
                end_time = moment()
                timeTormat = 'YYYY-MM'
                const start_format = `${start_time.format(timeTormat)}`
                const end_format = `${end_time.format(timeTormat)}`


                const { lineCols, lineRawList } = this.creatLineData(start_time, end_time, timeTormat, 2, 2)


                this.global.lineRawList = lineRawList
                this.setState({
                    timeSelectValue: `${start_format}  ~ ${end_format}`,
                    timeVisiable: { display: 'none' },
                    monthVisiable: { display: 'flex' },

                    lineCols,
                    disabledEndMonthPicker: true,
                })

                start_time = start_format
                end_time = end_format
            }
            this.quering.start_time = start_time
            this.quering.end_time = end_time
            this.quering.type = type
            this.quering.page_no = 1;
            this.quering.page_size = 30;

            await this.getNewUserList();
        }
    }


    creatLineData = (start, end, format = 'YYYY-MM-DD', interval = 2, type = 1) => {
        let xTime = moment(start)
        let eTime = moment(end)
        const ticks = []
        const lineRawList = []
        let count = 0

        while (xTime.isSameOrBefore(eTime)) {
            const time = xTime.format(format)
            count++
            if (count === interval) {
                ticks.push(time)
                count = 0
            }
            lineRawList.push({ time, raw: xTime, format })
            xTime.add(1, `${type === 1 ? 'days' : 'months'}`)
        }

        const lineCols = {
            time: {
                ticks,
            }
        }
        return { ticks, lineCols, lineRawList }
    }


    disabledDate = (current) => {
        const start = moment().subtract(30, 'days')
        const end = moment()
        return current && (current > end || current < start); // 30 天以前
    }

    disabledStartMonth = (current) => {
        const start_month = moment().subtract(12, "months")
        const end = moment()

        return current && (current > end || current < start_month);
    }

    disabledEndMonth = (current) => {

        const { startMonthRaw = moment().subtract(12, "months") } = this.global
        // const start_month = moment().subtract(12, "months")
        const end = moment()

        return current && (current > end || current < startMonthRaw);
    }

    // 日期选择变化

    timeSelected = async (data, dateString) => {

        const start = dateString[0];
        const end = dateString[1];

        if (moment(start).isBefore(moment(end).subtract(31, 'days'))) {
            message.error(`按日查询，时间跨度不能超过31天`);
            return
        }

        this.quering.start_time = start
        this.quering.end_time = end

        // 直接data[0] 会改变选择框的值 指针传递???
        const startRaw = Object.assign({}, data[0])

        const { lineCols, lineRawList } = this.creatLineData(startRaw, data[1])

        this.global.lineRawList = lineRawList

        this.setState({ timeSelectValue: `${start}  ~ ${end}`, lineCols })

        await this.getNewUserList()
    }


    monthStartSelect = (data, dateString) => {

        this.quering.start_time = dateString
        this.global.startMonthRaw = Object.assign({}, data)

        this.setState({ disabledEndMonthPicker: false })

    }

    monthEndSelect = async (data, dateString) => {

        this.quering.end_time = dateString

        const { start_time, end_time } = this.quering

        const { startMonthRaw } = this.global

        if (moment(startMonthRaw).isBefore(moment(data).subtract(12, 'months'))) {
            message.error(`按月查询，时间跨度不能超过12月`);
            return
        }

        const { lineCols, lineRawList } = this.creatLineData(startMonthRaw, data, 'YYYY-MM', 2, 2)

        this.global.lineRawList = lineRawList

        this.setState({ timeSelectValue: `${start_time}  ~ ${end_time}`, lineCols })

        await this.getNewUserList()
    }

    // 改变页码
    changePage = (current, pageSize) => {
        this.quering.page_no = current;
        this.quering.page_size = pageSize;
        this.getNewUserList();
    }

    enabled(start_date, time, format) {
        if (!start_date) {
            return true
        }
        return moment(start_date, format).isBefore(moment(time, format))
    }

    async getNewUserList() {
        try {

            const { lineRawList } = this.global

            this.setState({
                tableList: [],
                lineList: [],
                total: 0,
                salesPieData: [],
                salesPieDataTotal: [],
                loading: true,
            });

            // this.setState({ loading: true });

            const res = await getApi(countNewUserListUrl, this.quering);


            // {
            //     "code": 0,
            //     "msg": "成功",
            //     "data": {
            //         "user": [
            //             {
            //                 "created_at": "2019-09-27",
            //                 "count": 19
            //             },
            //         ],
            //         "mall": [
            //             {
            //                 "count": 18,
            //                 "appid": "wx44bfdc612bb8dacc",
            //                 "name": "湃小摊"
            //             },
            //         ],
            //         "user_list": [
            //             {
            //                 "created_at": "2019-09-30",
            //                 "count": 1,
            //                 "today_all": 21,
            //                 "all": 22
            //             },
            //         ]
            //     },
            //     "pagination": {
            //         "total": 7,
            //         "mall_total": 36
            //     }
            // }
            if (res.code === 0) {
                const { user_list = [], mall = [], user, start_date } = res.data
                const { total, mall_total } = res.pagination
                const malls = []
                const lineList = []
                for (const item of mall) {
                    const { name, count } = item
                    malls.push({ x: name, y: count })
                }

                const shops = []

                for (const u of user) {

                    const { name } = u

                    // lineList.push({ name, count, time: created_at })

                    // 没有的显示0 ---------- 控件有没有默认0 的选项？？？
                    if (!shops.includes(name)) {
                        shops.push(name)

                        lineRawList.map(p => {
                            const u = user.find(u => u.created_at === p.time && u.name === name)
                            if (u) {
                                const { name, count, created_at } = u
                                const n = name === '弦子平台' ? '物影钱包' : name;
                                lineList.push({ name: n, count, time: created_at })
                            } else {
                                // 如果日期 小于 start_date 页面不显示 yyyy-mm-dd /yyyy-mm 格式
                                if (this.enabled(start_date, p.time, p.format)) {
                                    const n = name === '弦子平台' ? '物影钱包' : name;
                                    lineList.push({ name: n, count: 0, time: p.time })
                                }
                            }
                        })
                    }
                }
                const tableList = []

                lineRawList.map(p => {
                    const u = user_list.find(u => u.created_at === p.time)
                    if (u) {
                        //  "created_at": "2019-09-30",
                        //  "count": 1,
                        //  "today_all": 21,
                        //  "all": 22F
                        const { created_at, count, today_all, all } = u
                        tableList.push({ created_at, count, today_all, all })
                    } else {

                        // 如果日期 小于 start_date 页面不显示 yyyy-mm-dd /yyyy-mm 格式
                        if (this.enabled(start_date, p.time, p.format)) {
                            tableList.push({ count: 0, today_all: 0, all: 0, created_at: p.time });
                        }
                    }
                })

                tableList.forEach((val, index) => {
                    if (index > 0 && val.count === 0 && val.all === 0) { // 今日新增为0
                        val.all = tableList[index - 1].all
                        val.today_all = val.all
                    }
                })

                this.setState({
                    tableList: tableList.reverse(),
                    lineList,
                    total: tableList.length,
                    salesPieData: malls,
                    salesPieDataTotal: mall_total,
                    loading: false,
                });
            } else {
                this.setState({ loading: false });
                message.error(`${res.msg}`);
            }
        } catch (error) {
            message.error(`${error.message}`);
            console.log('get new user list error', error);
            this.setState({ loading: false });
        }
    }


    render() {
        const {
            columns, tableList, loading, total = 0,
            salesPieData, timeTormat = 'YYYY-MM-DD', salesPieDataTotal,
            timeSelectValue = '~', defaultTime,
            lineList, lineCols,
            timeVisiable, monthVisiable,
            disabledEndMonthPicker
        } = this.state;

        const that = this;
        // const { DataView } = DataSet;
        // const { Html } = Guide;
        // const data = [
        //     {
        //         item: "事例一",
        //         count: 40
        //     },
        //     {
        //         item: "事例二",
        //         count: 21
        //     },
        //     {
        //         item: "事例三",
        //         count: 17
        //     },
        //     {
        //         item: "事例四",
        //         count: 13
        //     },
        //     {
        //         item: "事例五",
        //         count: 9
        //     }
        // ];
        // const dv = new DataView();
        // dv.source(data).transform({
        //     type: "percent",
        //     field: "count",
        //     dimension: "item",
        //     as: "percent"
        // });
        // const cols = {
        //     percent: {
        //         formatter: val => {
        //             val = val * 100 + "%";
        //             return val;
        //         }
        //     }
        // };

        return (
            <div className={style.content}>

                <div className={style.title}>
                    <div className={style.title_head}>数据统计</div>
                    <div className={style.title_info}>数据统计</div>
                </div>

                <Card bordered={false}>
                    <Row gutter={24} type="flex" justify="space-around" >
                        <Col span={8}>
                            <div style={{ display: 'flex', }}>
                                <Icon type="line" rotate='90' style={{ fontSize: '30px', color: '#08c' }} />
                                新增用户分析：
                                <Radio.Group onChange={e => this.onStatusChange(e)} defaultValue={1}>
                                    <Radio.Button value={1} >日</Radio.Button>
                                    <Radio.Button value={2}>月</Radio.Button>
                                </Radio.Group>
                            </div>
                        </Col>
                        <Col span={8}>
                            <RangePicker showTime={false} format={timeTormat}
                                // defaultValue={defaultTime}
                                // value={defaultTime}
                                // disabledDate={this.disabledDate}
                                onChange={this.timeSelected}
                                style={timeVisiable}
                            />

                            <div style={monthVisiable} >
                                <MonthPicker
                                    onChange={this.monthStartSelect} onOpenChange={this.monthStartPanlShow}
                                // disabledDate={this.disabledStartMonth} 
                                />
                                <span style={{ color: '#CCCCCC', fontSize: '10px', marginRight: '5px', marginLeft: '5px', marginTop: '2px' }}>——</span>
                                <MonthPicker onChange={this.monthEndSelect} disabled={disabledEndMonthPicker}
                                    disabledDate={this.disabledEndMonth}
                                />
                            </div>

                        </Col>
                        <Col span={8}>
                            <div style={{ display: 'flex', }}>
                                <Icon type="line" rotate='90' style={{ fontSize: '30px', color: '#08c' }} />
                                新增用户来源店铺：
                                <p style={{ display: 'flex', marginLeft: '5px', marginTop: '5px' }}>
                                    <span style={{ color: '#CCCCCC', fontSize: '10px' }}>{timeSelectValue}</span>
                                </p>
                            </div>
                        </Col>

                    </Row>

                    <div style={{ marginTop: '20px' }}>
                        <Row gutter={24} type="flex" justify="space-around">
                            <Col span={15}>
                                <Chart height={window.innerHeight * 1 / 3} data={lineList} scale={lineCols} forceFit padding='auto' animate={true}>
                                    <Axis name="time" />
                                    <Axis name="count" />
                                    <Legend />
                                    <Tooltip
                                        crosshairs={{
                                            type: "line"
                                        }}
                                    />
                                    {/* {['name', ['#ff0000', '#00ff00']]} */}
                                    <Geom type="areaStack" position="time*count" color="name" />
                                    <Geom type="lineStack" position="time*count" size={2} color="name" />
                                    {/* <Geom type="point" position="time*count" size={2} color="name" shape="circle" /> */}

                                </Chart>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={7}>
                                <Pie hasLegend title="新增用户" subTitle="新增用户" height={window.innerHeight * 1 / 3} data={salesPieData}
                                    total={() => (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: loading ? '加载中 ...' : salesPieDataTotal || salesPieData.reduce((pre, now) => now.y + pre, 0),
                                            }}
                                        />
                                    )}
                                    valueFormat={val => <span dangerouslySetInnerHTML={{ __html: loading ? '加载中 ...' : val }} />}
                                />

                                {/* <Chart
                                    height={window.innerHeight * 1 / 3}
                                    data={dv}
                                    scale={cols}
                                    padding='auto'
                                    forceFit
                                >
                                    <Coord type={"theta"} radius={0.75} innerRadius={0.6} />
                                    <Axis name="percent" />
                                    <Legend
                                    // position="right"
                                    />
                                    <Tooltip
                                        showTitle={false}
                                    />
                                    <Guide>
                                        <Html
                                            position={["50%", "50%"]}
                                            html="<div style=&quot;color:#8c8c8c;font-size:1.16em;text-align: center;width: 10em;&quot;>主机<br><span style=&quot;color:#262626;font-size:2.5em&quot;>200</span>台</div>"
                                            alignX="middle"
                                            alignY="middle"
                                        />
                                    </Guide>
                                    <Geom
                                        type="intervalStack"
                                        position="percent"
                                        color="item"
                                        tooltip={[
                                            "item*percent",
                                            (item, percent) => {
                                                percent = percent * 100 + "%";
                                                return {
                                                    name: item,
                                                    value: percent
                                                };
                                            }
                                        ]}
                                        style={{
                                            lineWidth: 1,
                                            stroke: "#fff"
                                        }}
                                    >
                                        <Label
                                            content="percent"
                                            formatter={(val, item) => {
                                                return item.point.item + ": " + val;
                                            }}
                                        />
                                    </Geom>
                                </Chart> */}

                            </Col>

                        </Row>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Row>
                            <div className={style.table}>
                                <Table
                                    rowClassName={style.row}
                                    columns={columns}
                                    dataSource={tableList}
                                    rowKey={record => record.created_at}
                                    loading={loading}
                                    // pagination={{
                                    // defaultCurrent: 1,
                                    // current: this.quering.page_no,
                                    // total,
                                    // showQuickJumper: true,
                                    // showSizeChanger: true,
                                    // pageSize: this.quering.page_size,
                                    // onChange: this.changePage,
                                    // onShowSizeChange(current, pageSize) {
                                    //     that.quering.page_no = 1;
                                    //     that.quering.page_size = pageSize;
                                    //     that.getNewUserList();
                                    // },
                                    //     pageSizeOptions: ['10', '20', '50', '100'],
                                    //     showTotal: () => `共 ${total} 条`,
                                    // }}
                                    pagination={false}
                                ></Table>
                            </div>
                        </Row>
                    </div>
                </Card>
            </div >
        );
    }
}
export default newUser;
