import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import style from '../../common/scss/exception.module.scss';

export default class ServerError extends Component {
  render() {
    return (
      <div className={style.content}>
        <div className={style.bgWrap}>
          <div className={`${style.bg} ${style.bg500}`} />
        </div>
        <div>
          <div className={style.title}>500</div>
          <div className={style.text}>抱歉，服务器出错了</div>
          <Link to="/">
            <Button type="primary">返回首页</Button>
          </Link>
        </div>
      </div>
    );
  }
}
