import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import {
  Table, Card, Button, Modal, message, Input, Upload, Icon, Form,
} from 'antd';
import actions from '../../store/actions/user';
// eslint-disable-next-line import/named
import api from '../../api/manager';
import style from '../../common/scss/themeManage.module.scss';

const {
  greetingList, getUploadToken, greetingCreate, greetingDetail,
  greetingTop, greetingUpdate,
} = api;
@connect(state => ({
  openKey: state.user.openKey,
}), actions)
@Form.create({ name: 'themeManage' })
class themeManage extends Component {
  constructor() {
    super();
    this.token = {
      token: '',
    };
    this.type = 'create';
    this.style_id = '';
    this.columns = [{
      title: '主题顺序',
      render: (a, b, index) => index + 1,
    }, {
      title: '主题图片',
      dataIndex: 'cover_img',
      render: (url) => (<img src={url} alt="" width="56" />),
    }, {
      title: '主题名称',
      dataIndex: 'title',
    }, {
      title: '主题编号',
      dataIndex: 'style_id',
    }, {
      title: '累计使用数量',
      dataIndex: 'use_amount',
    }, {
      title: '操作',
      render: (text, record, index) => {
        if (index === 0) {
          return (
            <span className={style.update} onClick={() => this.update(record.style_id)}>更新</span>
          );
        }
        return (
          <>
            <span className={style.update} onClick={() => this.update(record.style_id)}>更新</span>
            <span className={style.sticky} onClick={() => this.sticky(record.style_id)}>置顶</span>
          </>
        );
      },
    }];
    this.state = {
      data: [],
      loading: false,
      visible: false,
      imageUrl: new Array(8).fill({}),
      /* title: '',
      bgcolor_mine: '',
      bgcolor_other: '',
      product_bgcolor: '',
      bless_bgcolor: '',
      product_name_color: '',
      bless_word_color: '',
      status_color: '',
      id_color: '', */
    };
  }

  componentWillMount() {
    const {
      changeMenu, changeOpenKey, openKey,
    } = this.props;
    changeMenu('themeManage');
    const index = openKey.findIndex(item => item === 'sub8');
    const newOpenKey = [].concat(openKey);
    newOpenKey.splice(index, 1);
    changeOpenKey(index === -1 ? ['sub8'] : ['sub8', ...newOpenKey]);

    this.getData();
  }

  getData = () => {
    this.setState({
      loading: true,
    });
    greetingList().then(res => {
      if (res.code === 0) {
        this.setState({
          data: res.data,
        });
      } else {
        message.error(res.msg);
      }
      this.setState({
        loading: false,
      });
    }, () => {
      message.error('服务器连接异常');
    });
  }

  create = () => {
    this.type = 'create';
    this.showModal();
  }

  update = (style_id) => {
    this.style_id = style_id;
    this.type = 'update';
    greetingDetail({ style_id }).then(res => {
      if (res.code === 0) {
        const { form } = this.props;
        this.showModal();
        form.setFieldsValue({
          title: res.data.title,
          bgcolor_mine: res.data.fonts.bgcolor_mine,
          bgcolor_other: res.data.fonts.bgcolor_other,
          product_bgcolor: res.data.fonts.product_bgcolor,
          bless_bgcolor: res.data.fonts.bless_bgcolor,
          product_name_color: res.data.fonts.product_name_color,
          bless_word_color: res.data.fonts.bless_word_color,
          status_color: res.data.fonts.status_color,
          id_color: res.data.fonts.id_color,
          card1: res.data.imgs.card1,
          card2: res.data.imgs.card2,
          card3: res.data.imgs.card3,
          card4: res.data.imgs.card4,
          card5: res.data.imgs.card5,
          card6: res.data.imgs.card6,
          card7: res.data.imgs.card7,
          card8: res.data.imgs.card8,
        });
        this.setState({
          imageUrl: [{
            url: res.data.imgs.card1,
            realUrl: res.data.imgs.card1,
          }, {
            url: res.data.imgs.card2,
            realUrl: res.data.imgs.card2,
          }, {
            url: res.data.imgs.card3,
            realUrl: res.data.imgs.card3,
          }, {
            url: res.data.imgs.card4,
            realUrl: res.data.imgs.card4,
          }, {
            url: res.data.imgs.card5,
            realUrl: res.data.imgs.card5,
          }, {
            url: res.data.imgs.card6,
            realUrl: res.data.imgs.card6,
          }, {
            url: res.data.imgs.card7,
            realUrl: res.data.imgs.card7,
          }, {
            url: res.data.imgs.card8,
            realUrl: res.data.imgs.card8,
          }],
        });
      } else {
        message.error(res.msg);
      }
    }, () => message.error('服务器连接异常'));
  }

  sticky = (style_id) => {
    greetingTop({ style_id }).then(res => {
      if (res.code === 0) {
        message.success('已置顶');
        this.getData();
      } else {
        message.error(res.msg);
      }
    }, () => message.error('服务器连接异常'));
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  hideModal = () => {
    this.style_id = '';
    const { form } = this.props;
    this.setState({
      visible: false,
      imageUrl: new Array(8).fill({}),
    });
    form.resetFields();
  }

  handleOk = () => {
    const {
      imageUrl,
    } = this.state;
    const { form: { validateFields } } = this.props;
    validateFields((errors, values) => {
      if (!errors) {
        const params = {
          title: values.title,
          bgcolor_mine: values.bgcolor_mine,
          bgcolor_other: values.bgcolor_other,
          product_bgcolor: values.product_bgcolor,
          bless_bgcolor: values.bless_bgcolor,
          product_name_color: values.product_name_color,
          bless_word_color: values.bless_word_color,
          status_color: values.status_color,
          id_color: values.id_color,
          card1: imageUrl[0].url,
          card2: imageUrl[1].url,
          card3: imageUrl[2].url,
          card4: imageUrl[3].url,
          card5: imageUrl[4].url,
          card6: imageUrl[5].url,
          card7: imageUrl[6].url,
          card8: imageUrl[7].url,
        };
        if (this.type === 'create') {
          greetingCreate(params).then(res => {
            if (res.code === 0) {
              this.getData();
              this.hideModal();
            } else {
              message.error(res.msg);
            }
          }, () => message.error('服务器连接异常'));
        } else if (this.type === 'update') {
          params.style_id = this.style_id;
          greetingUpdate(params).then(res => {
            if (res.code === 0) {
              this.getData();
              this.hideModal();
            } else {
              message.error(res.msg);
            }
          }, () => message.error('服务器连接异常'));
        }
      }
    });
  }

  beforeUpload = async (file, alias, index) => {
    const isPng = file.type === 'image/png';
    if (!isPng) {
      message.error('图片格式不正确!');
    }
    const limitSize = file.size / 1024 / 1024 < 10;
    if (!limitSize) {
      message.error('图片不能大于10M!');
    }
    const res = await getUploadToken({ alias });
    if (res && res.code === 0) {
      const { imageUrl } = this.state;
      imageUrl.splice(index, 1, {
        url: res.data.url,
        token: res.data.uploadToken,
      });
      this.token = {
        token: res.data.uploadToken,
      };
      this.setState({
        imageUrl,
      });
    }
  }

  handleChange = (info, index) => {
    if (info.file.status === 'uploading') {
      this.setState({ [`loading${index}`]: true });
      return;
    }
    if (info.file.status === 'done') {
      const { imageUrl } = this.state;
      const item = imageUrl[index];
      imageUrl.splice(index, 1, {
        url: item.url,
        realUrl: item.url,
        token: item.token,
      });
      this.setState({
        imageUrl,
        [`loading${index}`]: false,
      });
    }
  };

  changeInput = (e, alias) => {
    this.setState({
      [`${alias}`]: e.target.value,
    });
  }

  render() {
    const {
      data, loading, visible, imageUrl,
    } = this.state;
    const { form: { getFieldDecorator } } = this.props;
    const uploadButton = (index) => {
      const { [`loading${index}`]: uploadLoading } = this.state;
      return (
        <div>
          <Icon type={uploadLoading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">上传照片</div>
        </div>
      );
    };
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };
    // 校验颜色
    const validateColor = (rule, value, callback) => {
      if (!value) {
        callback('');
        return;
      }
      callback();
    };
    return (
      <div className={style.content}>
        <div className={style.title}>
          <div className={style.title_head}>贺卡模板管理</div>
          <div className={style.title_info}>贺卡主题管理</div>
        </div>

        <Card bordered={false}>
          <div className={style.tableTitle}>主题模板列表<span>当前线上展示前 3 项主题</span></div>
          <Button type="primary" onClick={this.create}>新建主题</Button>
          <Table
            className={style.table}
            // rowClassName={style.row}
            columns={this.columns}
            dataSource={data}
            rowKey="style_id"
            loading={loading}
            pagination={false}
          />
        </Card>
        <Modal
          title="上传主题文件"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.hideModal}
          rowKey="style_id"
          className={style.modal}
        >
          <Form {...formItemLayout}>
            <Form.Item label="主题文件名称（10字以内可重复）">
              {
                getFieldDecorator('title', {
                  rules: [{ required: true, message: '请输入主题文件名称' }, {
                    max: 10, message: '最多10个字符',
                  }],
                })(
                  <Input placeholder="请您在此输入主题名称" className={style.themeName} onChange={(e) => this.changeInput(e, 'title')} />,
                )
              }
            </Form.Item>
            <div>
              {
                imageUrl.map((item, index) => (
                  <div className={style.picItem} key={index.toString()}>
                    <Form.Item label={`卡片0${index + 1}`} help="支持.png格式">
                      {
                        getFieldDecorator(`card${index + 1}`, {
                          initialValue: '',
                          rules: [{ required: true, message: '请上传图片' }],
                        })(
                          <Upload
                            action="//up-z2.qiniup.com/"
                            data={this.token}
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={(file) => this.beforeUpload(file, `card0${index + 1}`, index)}
                            onChange={(info) => this.handleChange(info, index)}
                            accept=".png"
                          >
                            {item.realUrl ? <img src={item.url} alt="avatar" style={{ width: '100%' }} /> : uploadButton(index)}
                          </Upload>,
                        )
                      }
                    </Form.Item>
                    {/* <div className={style.name}>卡片0{index + 1}</div>
                    <Upload
                      action="//up-z2.qiniup.com/"
                      data={this.token}
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={(file) => this.beforeUpload(file, `card0${index + 1}`, index)}
                      onChange={(info) => this.handleChange(info, index)}
                    >
                      {item.realUrl ? <img src={item.url} alt="avatar"
                       style={{ width: '100%' }} /> : uploadButton(index)}
                    </Upload>
                    <div className={style.accept}>支持.png格式</div> */}
                  </div>
                ))
              }
            </div>
            <div className={style.optionsWrap}>
              <div className={style.options}>
                <Form.Item label="创建贺卡背景色">
                  {
                    getFieldDecorator('bgcolor_mine', {
                      initialValue: '',
                      rules: [{ validator: validateColor }],
                    })(
                      <Input placeholder="请输入对应色值" className={style.color} onChange={(e) => this.changeInput(e, 'bgcolor_mine')} />,
                    )
                  }
                </Form.Item>
                {/* <div>创建贺卡背景色</div> */}
              </div>
              <div className={style.options}>
                <Form.Item label="领取人背景色">
                  {
                    getFieldDecorator('bgcolor_other', {
                      initialValue: '',
                      rules: [{ validator: validateColor }],
                    })(
                      <Input placeholder="请输入对应色值" className={style.color} onChange={(e) => this.changeInput(e, 'bgcolor_other')} />,
                    )
                  }
                </Form.Item>
                {/* <div>领取人背景色</div> */}
              </div>
              <div className={style.options}>
                <Form.Item label="商品背景色">
                  {
                    getFieldDecorator('product_bgcolor', {
                      initialValue: '',
                      rules: [{ validator: validateColor }],
                    })(
                      <Input placeholder="请输入对应色值" className={style.color} onChange={(e) => this.changeInput(e, 'product_bgcolor')} />,
                    )
                  }
                </Form.Item>
                {/* <div>商品背景色</div> */}
              </div>
              <div className={style.options}>
                <Form.Item label="祝福语背景色">
                  {
                    getFieldDecorator('bless_bgcolor', {
                      initialValue: '',
                      rules: [{ validator: validateColor }],
                    })(
                      <Input placeholder="请输入对应色值" className={style.color} onChange={(e) => this.changeInput(e, 'bless_bgcolor')} />,
                    )
                  }
                </Form.Item>
                {/* <div>祝福语背景色</div> */}
              </div>
              <div className={style.options}>
                <Form.Item label="商品文字颜色">
                  {
                    getFieldDecorator('product_name_color', {
                      initialValue: '',
                      rules: [{ validator: validateColor }],
                    })(
                      <Input placeholder="请输入对应色值" className={style.color} onChange={(e) => this.changeInput(e, 'product_name_color')} />,
                    )
                  }
                </Form.Item>
                {/* <div>商品文字颜色</div> */}
              </div>
              <div className={style.options}>
                <Form.Item label="祝福语字体颜色">
                  {
                    getFieldDecorator('bless_word_color', {
                      initialValue: '',
                      rules: [{ validator: validateColor }],
                    })(
                      <Input placeholder="请输入对应色值" className={style.color} onChange={(e) => this.changeInput(e, 'bless_word_color')} />,
                    )
                  }
                </Form.Item>
                {/* <div>祝福语字体颜色</div> */}
              </div>
              <div className={style.options}>
                <Form.Item label="状态颜色">
                  {
                    getFieldDecorator('status_color', {
                      initialValue: '',
                      rules: [{ validator: validateColor }],
                    })(
                      <Input placeholder="请输入对应色值" className={style.color} onChange={(e) => this.changeInput(e, 'status_color')} />,
                    )
                  }
                </Form.Item>
                {/* <div>状态颜色</div> */}
              </div>
              <div className={style.options}>
                <Form.Item label="贺卡ID">
                  {
                    getFieldDecorator('id_color', {
                      initialValue: '',
                      rules: [{ validator: validateColor }],
                    })(
                      <Input placeholder="请输入对应色值" className={style.color} onChange={(e) => this.changeInput(e, 'id_color')} />,
                    )
                  }
                </Form.Item>
                {/* <div>贺卡ID</div> */}
              </div>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default themeManage;
